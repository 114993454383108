import { postApi } from '$lib/model/request';

import {
	ICreditConfig,
	IFetchRechargeStatusData,
	IRechargeCreditsData,
	IRechargeCreditsParams,
	IWxAuthorization,
	IWxAuthorizationParams
} from './types';

export default class CreditsService {
	static fetchCreditConfig(data: { key: string }) {
		return postApi<ICreditConfig>('/trade/v1/activity/detail', {
			data
		});
	}

	static rechargeCredits(data: IRechargeCreditsParams) {
		return postApi<IRechargeCreditsData>('/trade/v1/activity/recharge', {
			data
		});
	}

	static fetchRechargeStatus(data: { tradeKey: string }) {
		return postApi<IFetchRechargeStatusData>('/trade/v1/activity/queryTradeStatus', {
			data
		});
	}

	static fetchVerifyCode(data: { phoneNumber: string }) {
		return postApi<{ outTime: string; userId: string; code: string; extInfo: { openId: string } }>(
			'/trade/v1/activity/smsSend',
			{
				data
			}
		);
	}
	static fetchWechatInfo(data: IWxAuthorizationParams) {
		return postApi<IWxAuthorization>('/base/v1/oauth/wechat/sns/info', {
			data
		});
	}
}
