import { useRef } from 'react';

import CreateCache from './store';

function useKeepAlive() {
	const cacheRef = useRef<CreateCache>();
	if (!cacheRef.current) {
		cacheRef.current = new CreateCache();
	}
	return [cacheRef.current];
}

export default useKeepAlive;
