import classNames from 'classnames';
import { Children, cloneElement, memo, useCallback, useMemo, useState } from 'react';

import type { EventType } from './data';

export interface ITouchFeedBack {
	/** 是否禁用 */
	disabled?: boolean;
	/** 激活时的类名 */
	activeClassName?: string;
	/** 激活时的内联样式 */
	activeStyle?: React.CSSProperties;
	/** 子集元素 */
	children?: any;
}

/** 触摸回馈组件 */
const TouchFeedBack = memo((props: ITouchFeedBack) => {
	const {
		children: _pChildren,
		disabled: _pDisabled,
		activeClassName: _pActiveClassName,
		activeStyle: _pActiveStyle,
	} = props;

	/** 是否激活 */
	const [_active, setActive] = useState(false);

	/** touchfeedback组件下的子集元素 */
	const tChild = Children.only(_pChildren);

	/** 绑定事件 */
	const triggerEvent = useCallback(
		(type: EventType, isActive: boolean, ev: Event) => {
			const eventType = `on${type}`;
			if (_pChildren.props[eventType]) {
				_pChildren.props[eventType](ev);
			}
			if (isActive !== _active) {
				setActive(isActive);
			}
		},
		[_pChildren, _active]
	);

	const onTouchStart = useCallback(
		(e: Event) => {
			triggerEvent('TouchStart', true, e);
		},
		[triggerEvent]
	);

	const onTouchMove = useCallback(
		(e: Event) => {
			triggerEvent('TouchMove', false, e);
		},
		[triggerEvent]
	);

	const onTouchEnd = useCallback(
		(e: Event) => {
			triggerEvent('TouchEnd', false, e);
		},
		[triggerEvent]
	);

	const onTouchCancel = useCallback(
		(e: Event) => {
			triggerEvent('TouchCancel', false, e);
		},
		[triggerEvent]
	);

	const onMouseDown = useCallback(
		(e: Event) => {
			triggerEvent('MouseDown', true, e);
		},
		[triggerEvent]
	);

	const onMouseUp = useCallback(
		(e: Event) => {
			triggerEvent('MouseUp', false, e);
		},
		[triggerEvent]
	);

	const onMouseLeave = useCallback(
		(e: Event) => {
			triggerEvent('MouseLeave', false, e);
		},
		[triggerEvent]
	);

	const onKeyDown = useCallback(
		(e: KeyboardEvent) => {
			if (e.key === 'Enter' || e.key === ' ') {
				triggerEvent("KeyDown", true, e);
			}
		},
		[triggerEvent]
	)

	const onKeyUp = useCallback((e: KeyboardEvent) => {
		if (e.key === 'Enter' || e.key === ' ') {
			triggerEvent("KeyUp", false, e);
		}
	}, [triggerEvent])

	/** 事件集合 */
	const events = useMemo(() => {
		if (_pDisabled) return undefined;
		return {
			onTouchStart,
			onTouchMove,
			onTouchEnd,
			onTouchCancel,
			onMouseDown,
			onMouseUp,
			onMouseLeave,
			onKeyDown,
			onKeyUp,
		};
	}, [
		_pDisabled,
		onTouchStart,
		onTouchMove,
		onTouchEnd,
		onTouchCancel,
		onMouseDown,
		onMouseUp,
		onMouseLeave,
		onKeyDown,
		onKeyUp,
	]);

	/** 组件样式 */
	const styles = useMemo(() => {
		if (!_pDisabled && _active) {
			const { style } = tChild.props;
			if (_pActiveStyle) {
				return { ...style, ..._pActiveStyle };
			}
		}
	}, [_pDisabled, _active, tChild.props, _pActiveStyle]);

	/** 组件类名 */
	const classes = useMemo(() => {
		if (!_pDisabled && _active) {
			const { className } = tChild.props;
			return classNames(className, _pActiveClassName);
		}
	}, [_pDisabled, _active, tChild.props, _pActiveClassName]);

	return !_pDisabled && _active
		? cloneElement(tChild, { className: classes, style: styles, ...events })
		: cloneElement(tChild, events);
});

export default TouchFeedBack;
