/** 直辖市数据 */
export const DIRECTLY_CITY = [
	{ id: '2', pid: '1', name: '北京', ad_name: '北京市' },
	{ id: '25', pid: '1', name: '上海', ad_name: '上海市' },
	{ id: '27', pid: '1', name: '天津', ad_name: '天津市' },
	{ id: '32', pid: '1', name: '重庆', ad_name: '重庆市' }
];

/** 港澳台 */
export const GAT_ID = [
	{ id: '33', name: '香港' },
	{ id: '34', name: '澳门' },
	{ id: '35', name: '台湾' }
];

export const OS_MAP_REGEXP: Record<string, RegExp> = {
	['MACOS']: /(mac os x)\s+([\w_]+)/,
	['WINDOWS']: /(windows nt)\s+([\w.]+)/,
	['IOS']: /(i(?:pad|phone|pod))(?:.*)cpu(?: i(?:pad|phone|pod))? os (\d+(?:[\.|_]\d+){1,})/,
	['ANDROID']: /(android)\s+([\d.]+)/
};

export const AMAP_KEY = 'dcbeeb0a12d815abc4033791b776eaec';
