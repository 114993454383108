import { postApi } from '$lib/model/request';

import {
	FetchComplainDetailRes,
	IAddComplainParams,
	IAddComplainRes,
	IComplainConfig,
	IComplainHistoryRes,
	IFetchComplainConfigParams,
	IFetchComplainDetailReq,
	IFetchComplainHistoryReq,
	IFetchUserGender,
	IFetchUserGenderReq
} from './types';

export default class ComplainService {
	/** 获取投诉应用配置 */
	static fetchComplainConfig(params: IFetchComplainConfigParams) {
		return postApi<IComplainConfig>('/crm/v1/appealProject/template', { data: params });
	}
	/** 添加投诉 */
	static addComplain(params: IAddComplainParams) {
		return postApi<IAddComplainRes>('/crm/v1/appealRecord/add', { data: params });
	}
	/** 获取历史记录 */
	static fetchComplainHistory(params: IFetchComplainHistoryReq) {
		return postApi<IComplainHistoryRes>('/crm/v1/appealRecord/pageQuery', {
			data: params
		});
	}
	/** 获取投诉详情 */
	static fetchComplainDetails(params: IFetchComplainDetailReq) {
		return postApi<FetchComplainDetailRes>('/crm/v1/appealRecord/info', { data: params });
	}
	/** 获取用户性别 */
	static fetchUserGender(params: IFetchUserGenderReq) {
		return postApi<Record<string, IFetchUserGender>>('/user/member/getMembersGender', {
			data: params,
			php: true
		});
	}
}
