import { postApi } from '$lib/model/request';

export default class NewYearActivityService {
	/** 页面数据列表 */
	static recruitmentDataCenterList(data: any) {
		return postApi('/rightGateway/v1/rightStat/visualQuery', { data });
	}
	/** 资源位数据 */
	static ObtainResourceBitData(data: any, header?: any) {
		return postApi('/cms/v1/resource/page', { data, headers: header });
	}
	// /** 福气列表 */
	// static fetchNewYearActivityMyPrizeList() {
	// 	return postApi('/marketing/v1/activityService/myPrize/list');
	// }
	// /** 查看中奖名单列表 */
	// static fetchNewYearActivityWinPrizeList() {
	// 	return postApi('/marketing/v1/activityService/activityPrizeResult/list');
	// }
}
