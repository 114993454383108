// import { postApi } from '$lib/model/request';

import { postApi } from '$lib/model/request';

import { IGetUrlSchemeRes, IPaymentData, IPaymentStatusData, queryTradeResultRes } from './types.d';

export default class CommonService {
	// static fetchVerifyCode(data: { mobile: string }) {
	// 	return postApi<{ number: number; time: number }>('/fleamarket/v1/message/send', { data });
	// }

	/**
	 * 获取支付状态
	 * @param {{tradeNo: string}} data
	 * @returns
	 */
	static fetchPaymentStatus(data: { tradeNo: string }) {
		return postApi<IPaymentStatusData>('/trade/v1/pay/queryTradeStatus', { data });
	}

	/**
	 * 获取支付信息
	 * @param {{tradeNo: string}} data
	 * @returns
	 */
	static fetchPaymentInfo(data: { tradeNo: string }) {
		return postApi<IPaymentData>('/trade/v1/pay/queryTradeInfo', { data });
	}

	static queryTradeResult(data: { tradeNo: string }) {
		return postApi<queryTradeResultRes>('/trade/v1/pay/queryTradeResult', { data });
	}

	/** H5 跳转到小程序（urlscheme） */
	static getUrlScheme(data: FormData) {
		return postApi<IGetUrlSchemeRes>('/job/home/getUrlScheme', { data, php: true });
	}

}
