import { inBrowser } from "./in-browser"

// eslint-disable-next-line import/no-mutable-exports
export let supportsPassive = false

if (inBrowser) {
  try {
    const opts = {}
    Object.defineProperty(opts, "passive", {
      get() {
        supportsPassive = true
      },
    })
    window.addEventListener("test-passive", null as any, opts)
  } catch (e) {}
}
