import { postApi } from '$lib/model/request';

// 大转盘相关接口
export class TurntableServices {
	static instance: TurntableServices | null = null;

	static getInstance() {
		if (!this.instance) this.instance = new TurntableServices();
		return this.instance;
	}

	startDraw(data?: SStartDrawPayload) {
		return postApi<STurntableRes['data']>(`/marketingcenter/v1/turnTable/draw`, { data });
	}

	getTimes(data?: BasePayload) {
		return postApi<SQueryTimeRes['data']>('/marketingcenter/v1/turnTable/getTimes', { data });
	}

	query(data?: Record<string, any>) {
		return postApi<SQueryTurntableContentRes['data']>('/marketingcenter/v1/turnTable/index', {
			data
		});
	}

	isEnd(data?: SQueryIsEndPayload) {
		return postApi<SQueryIsEndRes['data']>('/marketingcenter/v1/turnTable/isTurnTable', { data });
	}

	shared(data: SQuerySharedPayload) {
		return postApi<SQuerySharedRes['data']>('/marketingcenter/v1/turnTable/shareEnd', { data });
	}

	queryVideo(data: SQueryVideoPayload) {
		return postApi<SQueryVideoRes['data']>('/marketingcenter/v1/turnTable/showVideo', { data });
	}

	viewVideoEnd(data: SQueryIsEndPayload) {
		return postApi<SQueryIsEndRes['data']>('/marketingcenter/v1/turnTable/videoEnd', { data });
	}

	queryAdvDialogTimes(data: SQueryAdvDialogTimes) {
		return postApi<SQueryAdvDialogTimesRes['data']>('/marketingcenter/v1/turnTable/adTimes', {
			data
		});
	}

	/**
	 * @desc 大转盘是否已经结束
	 * @returns
	 */
	queryIsEnd() {
		return postApi<SQueryIsEndRes['data']>('/marketingcenter/v1/turnTable/isTurnTable');
	}

	/**
	 * @deprecated 分享回调
	 * @param data
	 * @returns
	 */
	shareEnd(data: any) {
		return postApi('/marketingcenter/v1/turnTable/shareEnd', { data });
	}

	/**
	 * @desc 去下载
	 * @param data
	 * @returns
	 * @deprecated 小程序
	 */
	toDownload(data: any) {
		return postApi('/marketingcenter/v1/turnTable/toDownloadApp', { data });
	}
}

const turntableServices = TurntableServices.getInstance();

export default turntableServices;
