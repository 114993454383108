import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { IMemberVipPurchaseData, IMemberVipPurchasePreData } from '$lib/service/member-vip';

/**
 * 计算优惠券倒计时
 * @param currTime 当前时间
 * @param endTime 会员卡券结束时间
 */
export const countdown = (currTime: string, endTime: string) => {
	dayjs.extend(duration);
	const targetDate = dayjs(endTime);
	const currentDate = dayjs();
	const diffDuration = dayjs.duration(targetDate.diff(currentDate));

	const days = diffDuration.days();
	const hours = diffDuration.hours();
	const minutes = diffDuration.minutes();
	const seconds = diffDuration.seconds();
	return {
		days: days.toString(),
		hours: hours.toString().padStart(2, '0'),
		minutes: minutes.toString().padStart(2, '0'),
		seconds: seconds.toString().padStart(2, '0')
	};
};
/**
 * 格式化会员购买接口数据，因接口替换将数据统一处理成页面所需格式
 * @param preData 接口原数据
 * @param goodsGroupCode 商品组编码：会员卡-GENERAL_VIP，次卡-TIMES_VIP
 * @returns {IMemberVipPurchaseData} 返回数据
 */
export const formatVipData = (
	preData: IMemberVipPurchasePreData,
	goodsGroupCode: string
): IMemberVipPurchaseData => {
	const { showOriginPrice, introduce, description, picture, goodsList = [] } = preData || {};
	// 这个数据页面会用到，固定次卡和会员的编码不变, 自定义生成一个
	const vipType = goodsGroupCode === 'JOB_GENERAL_VIP' ? 1006 : 1007;
	const vipSkuList = goodsList.map((item) => {
		const {
			delayWarn,
			defaultShow,
			label,
			goodsName,
			goodsCode,
			skuAttrConfig,
			scopeDescription,
			priceConfig,
			description,
			privilegePicture,
			privilegeVideo,
			picture: pictureUrl,
			businessType,
			subSkuAttrConfigList
		} = item || {};
		const { occupationList, dailyViews, views, rightsDays, numberOfTimes, dailyLimit } =
			skuAttrConfig || {};
		const { integralPrice, originIntegralPrice, originPrice, price } = priceConfig || {};
		return {
			vipSku: goodsCode,
			delayWarn,
			introduce,
			label,
			price: originPrice, // 划线价，单位分
			discountPrice: price, // 实售价，单位分
			integralPrice: originIntegralPrice, // 划线积分
			integralDiscountPrice: integralPrice, // 实售积分
			name: goodsName,
			views,
			businessType,
			picture: pictureUrl,
			occupationConfig: {
				occupationList,
				all: occupationList?.length <= 0
			},
			scopeDescription,
			privilegePicture,
			privilegeVideo,
			extInfo: {
				defaultShow,
				exclusivePrivilege: description
			},
			numberOfTimes,
			dailyLimit,
			dailyFreeTimes: dailyViews,
			totalFreeTimes: views,
			days: rightsDays, // 权益天数
			reducePrice: 0,
			subSkuAttrConfigList
		};
	});
	const vipSpuList = [
		{
			introduce,
			guidePicture: picture,
			description,
			showOriginPrice,
			vipSpu: vipType,
			vipType,
			// 按照产品需求，这个字段不会在新版本页面展示了，给一个默认值占位
			name: vipType === 1006 ? '招聘会员' : '次卡套餐',
			vipSkuList
		}
	];
	return { vipSpuList, delayWarn: false };
};
