import { postApi } from '$lib/model/request';

import {
    IPurchaseDetailParamsReq,
    IPurchaseDetailRes,
} from './types';

export default class CampaignRecruitmentService {
    /** 商品详情 */
    static fetchTMarketGoodsGroupPurchaseDetail(params: IPurchaseDetailParamsReq) {
        return postApi<IPurchaseDetailRes>('/marketGateway/v1/marketGoodsGroup/purchaseDetail', { data: params });
    }
}
