import { useRef } from 'react';

import CreateScroll from './CreateScroll';

export default function useScroll() {
	const ref = useRef<CreateScroll>();
	if (!ref.current) {
		ref.current = new CreateScroll();
	}

	return [ref.current];
}
