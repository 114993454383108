// 环境变量
export const YP_CDN = process.env.YP_CDN;
export const H5_CDN = process.env.H5_CDN;
export const YP_ENV = process.env.YP_ENV;
export const YP_API_SERVER = process.env.YP_API_SERVER;
export const YP_PHP_SERVER = process.env.YP_PHP_SERVER;
export const VOLC_ENGINE_APP_ID = process.env.VOLC_ENGINE_APP_ID;
export const VOLC_ENGINE_APP_ID_ZP = process.env.VOLC_ENGINE_APP_ID_ZP;
export const YP_M_CLIENT = process.env.YP_M_CLIENT;
export const WX_APP_ID = process.env.WX_APP_ID;
export const INSURANCE_PATH = process.env.INSURANCE_PATH;
export const H5HYBRID = process.env.H5HYBRID;
export const STATIC = process.env.STATIC;
/** app支付的appId */
export const APP_PAYMENT_APPID = 'wx617cfe7ae2c932f6';
/** 记工记账appID */
export const APP_PAYMENT_APPID_JGJZ = 'wxf5529085b9a995bb';
