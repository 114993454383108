export type ICacheEntity = {
	Component: any;
	props: any;
	scrollY: number;
	key: string;
};
export default class CreateCache {
	private cacheKey: string = '';
	private cacheMap: Map<string, ICacheEntity> = new Map();
	private cacheListener: Function | null = null;
	setCacheKey = (name: string) => {
		this.cacheKey = name;
		this.dispatchCache(() => {
			return { current: this.cacheMap.get(name)! };
		});
	};
	getCacheKey = () => {
		return this.cacheKey;
	};
	addCache = (name: string, cache: ICacheEntity) => {
		this.cacheMap.set(name, cache);
		this.dispatchCache(() => {
			return { cacheMap: this.cacheMap, current: cache };
		});
	};
	getCache = (name: string) => {
		return this.cacheMap.get(name);
	};
	getCaches = () => {
		return this.cacheMap;
	};
	removeCache = (name?: string) => {
		if (name) {
			this.cacheMap.delete(name);
		} else {
			this.cacheMap.clear();
		}
		this.dispatchCache(() => {
			return { cacheMap: new Map(this.cacheMap) };
		});
	};
	getCacheListener() {
		return this.cacheListener;
	}
	removeCacheListener() {
		this.cacheListener = null;
	}
	subscribeCache(
		listener: (arg: { cacheMap: Map<string, ICacheEntity>; current: ICacheEntity }) => void
	) {
		this.cacheListener = listener;
	}
	dispatchCache(cb: () => { cacheMap?: Map<string, ICacheEntity>; current?: ICacheEntity }) {
		if (this.cacheListener) {
			this.cacheListener(cb());
		}
	}
}
