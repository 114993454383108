import { postApi } from "$lib/model/request";

import {
    BatchQryConf,
    OtherRecruitListData,
    OtherRecruitListParams,
    OtherResumeStatusData,
    OtherResumeStatusParams,
    PointQryConf,
    PointServiceConfig,
    PropMallServiceConfig,
    ResumeQryConf,
    ResumeServiceConfig,
    TabBtnQryConf,
    TabBtnServiceConfig,
    TabDataQryConf,
    TabDataServiceConfig
} from "./types";

class PropMallService {
    // 获取商品列表
    static getList(data: BatchQryConf) {
        return postApi<PropMallServiceConfig>("/marketGateway/v1/marketGoodsGroup/batchDetail", { data });
    }
    // 获取用户积分
    static getPoint(data: PointQryConf) {
        return postApi<PointServiceConfig>("/integral/v1/memberIntegral/getMemberIntegral", { data });
    }
    // 获取我的道具类型list
    static getTabData(data: TabDataQryConf) {
        return postApi<TabDataServiceConfig>("/marketGateway/v2/marketGoodsRight/pageQuery", { data });
    }
    // 获取我的道具类型tab
    static getTabBtn(data: TabBtnQryConf) {
        return postApi<TabBtnServiceConfig>("/marketGateway/v1/marketGoodsRight/stat", { data });
    }
    // 简历是否存在
    static getExistResume(data: ResumeQryConf) {
        return postApi<ResumeServiceConfig>("/resume/v2/my/exist", { data });
    }
    /**  检查他人简历状态 */
    static checkOtherResumeStatus(data: OtherResumeStatusParams) {
        return postApi<OtherResumeStatusData>("/resume/v3/base/checkOtherResumeStatus", { data });
    }
    /** 查看老板的招工信息列表 */
    static fetchOtherRecruitList(data: OtherRecruitListParams) {
        return postApi<OtherRecruitListData>("/job/v2/list/browse/boss/jobList", { data });
    }
}

export default PropMallService;