import { postApi } from '$lib/model/request';

import {
	FetchCodeLogin,
	FetchCodeLoginData,
	FetchInitData,
	FetchLgnoreData,
	FetchListParams,
	FetchLoginLgnore,
	IListPackage,
	ISecondHandListData
} from './types';

export default class BnSureService {
	/* 保险列表 */
	static getListPackage() {
		return postApi<IListPackage>('/insurance/v1/init/listPackage');
	}
	static fetchList(data: FetchListParams) {
		return postApi<ISecondHandListData>('/insurance/v1/agreement/userPolicies', { data });
	}
	static fetchInit() {
		return postApi<FetchInitData>('/account/v1/userBase/getLoginUserInfo', { defaultLogin: false });
	}
	static getOtherInsurance(data: any) {
		return postApi<any>('/insurance/v1/tppDocking/insurePage', { data });
	}
	static fetchLoginLgnore(data: FetchLgnoreData, headers: Record<string, any>) {
		return postApi<FetchLoginLgnore>('/reach/v1/verifyCode/loginIgnore/send', {
			data,
			headers
		});
	}
	static fetchCodeLogin(data: FetchCodeLoginData, headers: Record<string, any>) {
		return postApi<FetchCodeLogin>('/account/v1/login/codeLogin', { data, headers });
	}
}
