import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { IMemberVipPurchaseData, IMemberVipPurchasePreData } from '$lib/service/member-vip';

/**
 * 计算优惠券倒计时
 * @param currTime 当前时间
 * @param endTime 会员卡券结束时间
 */
export const countdown = (currTime: string, endTime: string) => {
	dayjs.extend(duration);
	const targetDate = dayjs(endTime);
	const currentDate = dayjs();
	const diffDuration = dayjs.duration(targetDate.diff(currentDate));

	const days = diffDuration.days();
	const hours = diffDuration.hours();
	const minutes = diffDuration.minutes();
	const seconds = diffDuration.seconds();
	return {
		days: days.toString(),
		hours: hours.toString().padStart(2, '0'),
		minutes: minutes.toString().padStart(2, '0'),
		seconds: seconds.toString().padStart(2, '0')
	};
};
/**
 * 格式化会员购买接口数据，因接口替换将数据统一处理成页面所需格式
 * @param preData 接口原数据
 * @param goodsGroupCode 商品组编码：会员卡-GENERAL_VIP，次卡-TIMES_VIP
 * @returns {IMemberVipPurchaseData} 返回数据
 */
export const formatVipData = (
	preData: IMemberVipPurchasePreData,
	goodsGroupCode: string
): IMemberVipPurchaseData => {
	const { showOriginPrice, introduce, description, picture, goodsList = [] } = preData || {};
	// 这个数据页面会用到，固定次卡和会员的编码不变, 自定义生成一个
	const vipType = goodsGroupCode === 'GENERAL_VIP' ? 1002 : 1003;
	const vipSkuList = goodsList.map((item) => {
		const {
			delayWarn,
			defaultShow,
			label,
			goodsName,
			goodsCode,
			skuAttrConfig,
			scopeDescription,
			priceConfig,
			description,
			privilegePicture,
			privilegeVideo
		} = item || {};
		const { occupationList, dailyViews, views, rightsDays } = skuAttrConfig || {};
		const { integralPrice, originIntegralPrice, originPrice, price } = priceConfig || {};
		return {
			vipSku: goodsCode,
			delayWarn,
			introduce,
			label,
			price: originPrice,
			discountPrice: price,
			integralPrice: originIntegralPrice,
			integralDiscountPrice: integralPrice,
			name: goodsName,
			occupationConfig: {
				occupationList,
				all: occupationList?.length <= 0
			},
			scopeDescription,
			privilegePicture,
			privilegeVideo,
			extInfo: {
				defaultShow,
				exclusivePrivilege: description
			},
			dailyFreeTimes: dailyViews,
			totalFreeTimes: views,
			days: rightsDays,
			reducePrice: 0
		};
	});
	const vipSpuList = [
		{
			introduce,
			guidePicture: picture,
			description,
			showOriginPrice,
			vipSpu: vipType,
			vipType,
			// 按照产品需求，这个字段不会在新版本页面展示了，给一个默认值占位
			name: vipType === 1002 ? '求职会员' : '求职次卡',
			vipSkuList
		}
	];
	return { vipSpuList, delayWarn: false };
};
