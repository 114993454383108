import { postApi } from '$lib/model/request';

import {
    IComplaintCategoryConfig,
    reportCenterParams,
} from './types';

export default class SelectClassificationNewService {

    /**
     * 获取投诉分类
     * projectId:项目id 招工-1100, 找活1101, im-1102
     */
    static fetchComplaintCategoryConfig(params: { projectId: number }) {
        return postApi<IComplaintCategoryConfig>('/audit/v1/reportCenter/category', { data: params });
    }

    static reportCenter(params: reportCenterParams) {
        return postApi<IComplaintCategoryConfig>('/audit/v1/reportCenter/save', { data: params });
    }

}
