import { postApi } from '$lib/model/request';

import {
	IFetchPromotedInvitationsParams,
	IFetchReceivedInvitationsParams,
	IFetchWithdrawDetailParams,
	IPromotedInvitationsRes,
	IReceivedInvitationsRes,
	ISubmitClockParams,
	ISubmitClockRes,
	ISubmitVerifyCodeParams,
	ISubmitVerifyCodeRes,
	ISubmitWithdrawParams,
	IWithdrawDetailRes,
	IWithdrawListParams,
	IWithdrawListRes
} from './types';

class DepositService {
	/** 获取我发起邀约的列表 */
	static fetchPromotedInvitations(data: IFetchPromotedInvitationsParams) {
		return postApi<IPromotedInvitationsRes>('/member/v1/guaranteeContract/promoteList', { data });
	}

	/** 获取我收到邀约的列表 */
	static fetchReceivedInvitations(data: IFetchReceivedInvitationsParams) {
		return postApi<IReceivedInvitationsRes>('/member/v1/guaranteeContract/receiveList', { data });
	}

	/** 提交到场验证码 */
	static submitVerifyCode(data: ISubmitVerifyCodeParams) {
		return postApi<ISubmitVerifyCodeRes>('/member/v1/guaranteeContract/submitValidateCode', {
			data
		});
	}

	/** 提交打卡签到 */
	static submitClock(data: ISubmitClockParams) {
		return postApi<ISubmitClockRes>('/member/v1/guaranteeContract/clockIn', { data });
	}

	/** 获取可提现金额 */
	static fetchWithdrawAmount() {
		return postApi<{ amount: string; cashouted: number }>('/active/bookkeeping/amount', {
			php: true
		});
	}

	/** 提现 */
	static submitWithdraw(data: ISubmitWithdrawParams) {
		return postApi('/active/cashout/save', { data, php: true });
	}

	/** 提现记录列表 */
	static fetchWithdrawList(data: IWithdrawListParams) {
		return postApi<IWithdrawListRes>('/active/cashout/statement', { data, php: true });
	}

	/** 提现详情 */
	static fetchWithdrawDetail(data: IFetchWithdrawDetailParams) {
		return postApi<IWithdrawDetailRes>('/active/cashout/detail', { data, php: true });
	}
}

export default DepositService;
