import { postApi } from '$lib/model/request';

import {
	IFetchAppShareHeaders,
	IFetchAppShareReq,
	IFetchCashOutReq,
	IFetchNewYearActivityPrizeResultReq,
	INewYearActivityInvitationReportReq
} from './types';

export default class NewYearActivityService {
	/** 获取活动时间等基础信息配置 */
	static fetchNewYearActivityConfig() {
		return postApi('/marketing/v1/activityService/activityInfo/query');
	}
	/** 福气列表 */
	static fetchNewYearActivityMyPrizeList() {
		return postApi('/marketing/v1/activityService/myPrize/list');
	}
	/** 查看中奖名单列表 */
	static fetchNewYearActivityWinPrizeList() {
		return postApi('/marketing/v1/activityService/activityPrizeResult/list');
	}
	/** 获取 奖励轮播滚动 */
	static fetchNewYearActivityCarousel() {
		return postApi('/marketing/v1/activityService/lotteryRecord/query');
	}
	/** 获取 我的福卡 */
	static fetchNewYearActivityMyFuka() {
		return postApi('/marketing/v1/activityService/myFuka/list');
	}
	/** 获取 抽奖次数 */
	static fetchNewYearActivityDrawPrizeTimes() {
		return postApi('/marketing/v1/activityService/drawPrizeChance/count');
	}
	/**  抽奖 */
	static newYearActivityDrawPrize() {
		return postApi('/marketing/v1/activityService/prize/draw');
	}
	/**  查询任务完成状态 */
	static fetchNewYearActivityTasks() {
		return postApi('/marketing/v1/activityService/taskFinishStatus/query');
	}
	/**  查看中奖结果 */
	static fetchNewYearActivityPrizeResult() {
		return postApi('/marketing/v1/activityService/activityPrizeResult/query');
	}
	/**  完成任务 */
	static newYearActivityTaskFinish(data: IFetchNewYearActivityPrizeResultReq) {
		return postApi('/marketing/v1/activityService/task/finish', { data });
	}
	/**  福卡合成 */
	static newYearActivityFukaMerge() {
		return postApi('/marketing/v1/activityService/myFuka/merge');
	}
	/** 邀请上报 */
	static newYearActivityInvitationReport(data: INewYearActivityInvitationReportReq) {
		return postApi('/share/v1/report/assistByUser', { data });
	}
	/** 查询现金奖励金额 */
	static fetchNewYearActivityCashOut(data: IFetchCashOutReq) {
		return postApi('/marketing/v1/activityService/myPrize/cashOut', { data });
	}
	/** 获取app分享配置 */
	static fetchAppShare({
		headers,
		data
	}: {
		headers: IFetchAppShareHeaders;
		data: IFetchAppShareReq;
	}) {
		return postApi('/job/home/share', { php: true, headers, data });
	}
}
