import { postApi } from '$lib/model/request';

import {
	IFetchWechatGetShareTeamInfoParams,
	IFetchWechatGetShareTeamInfoResponse,
	IGetAlbumMemberResourceParams,
	IGetAlbumMemberResourceResponse
} from './types';

export default class WatermarkCameraService {
	/** h5获取团队邀请分享信息 */
	static fetchWechatGetShareTeamInfo(params: IFetchWechatGetShareTeamInfoParams) {
		return postApi<IFetchWechatGetShareTeamInfoResponse>('/wmc/v1/share/wechatGetShareTeamInfo', {
			data: params
		});
	}
	/** 获取分享相册成员资源 */
	static getAlbumMemberResource(params: IGetAlbumMemberResourceParams) {
		return postApi<IGetAlbumMemberResourceResponse>('/wmc/v1/share/getAlbumMemberResource', {
			data: params
		});
	}
}
