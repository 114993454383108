import { useRouter } from 'next/router';
import { useEffect } from 'react';

import CreateScroll from './CreateScroll';

function useCacheScroll(scroll: CreateScroll) {
	const router = useRouter();
	useEffect(() => {
		scroll.updateCacheKey(router.asPath.split('?')[0]);
	}, [router.asPath, scroll]);
	const updateScrollKey = (e: any) => {
		if (e?.state?.as) {
			scroll.updateScrollKey(e.state.as);
		}
	};
	useEffect(() => {
		window.history.scrollRestoration = 'manual';
		const updateCacheScroll = () => {
			scroll.updateCacheScroll();
		};
		const handleScroll = (path: string) => {
			scroll.scroll(path.split('?')[0]);
		};
		router.events.on('beforeHistoryChange', updateCacheScroll);
		router.events.on('routeChangeComplete', handleScroll);
		window.addEventListener('popstate', updateScrollKey);

		return () => {
			scroll.destroy();
			router.events.off('beforeHistoryChange', updateCacheScroll);
			router.events.off('routeChangeComplete', handleScroll);
			window.removeEventListener('popstate', updateScrollKey);
		};
	}, []);
}

export default useCacheScroll;
