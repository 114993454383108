import isEqual from 'lodash/isEqual';
import { NextPageContext } from 'next';

// import { TOKEN } from './storageKeys';
import { hHeaders } from '$lib/http';

import { actions, AppState } from '../model/wrapper/common';
import { getOs, getOsVersion, isMobile, isWechat } from './tools';

export const isNullable = function (express: unknown) {
	return express === 'undefined' || express === 'null';
};

/**
 * @param arr 要转换的数组
 * @returns {}
 */
export const getArrToObj = (arr: any[]) => {
	return arr.reduce((acc, [key, value]) => {
		acc[key] = value;
		return acc;
	}, {});
}

export const getLoginParams = (query: any[], ua?: string) => {
	const packages: any = {
		android: 'io.dcloud.H576E6CC7',
		ANDROID: 'io.dcloud.H576E6CC7',
		ios: 'com.menggua.yupaowang',
		IOS: 'com.menggua.yupaowang'
	}
	const params: any = getArrToObj(query)
	hHeaders.packagename = packages[params?.runtime] || hHeaders?.packagename
	hHeaders.runtime = params?.runtime || hHeaders?.runtime
	hHeaders.os = getOs(ua)
	return hHeaders
}

/**
 * @param store
 * @param ctx
 */
export const sliceUrlParams = (store: any, ctx: NextPageContext<any>) => {
	const url = `https://h5hybridprod.com${ctx.asPath!}`;
	const query = Array.from(new URL(url).searchParams.entries());
	let cache: Record<string, string> = {};
	const {
		query: oldQuery,
		platform: oldPlatform,
		headers: oldHeaders
	} = store.getState().configModel as AppState['configModel'];
	const { token: oldToken } = store.getState().userModel as AppState['userModel'];
	query.forEach(([key, val]) => {
		if (isNullable(val) || isNullable(key)) return;
		if (key === 'session') {
			return (() => {
				if (val != oldToken) {
					store.dispatch(actions.userModel.setToken(val));
				}
			})();
		}
		if (key === 'enterType') {
			try {
				const ua = ctx.req?.headers['user-agent'] ?? '';
				store.dispatch(actions.configModel.setState({ headers: { ...getLoginParams(query, ua) } }));
			} catch { }
		}
		if (key === 'params') {
			return (() => {
				try {
					const params = JSON.parse(val);
					store.dispatch(actions.configModel.setState({ params }));
				} catch { }
			})();
		}
		if (key === 'headers') {
			return (() => {
				try {
					const ua = ctx.req?.headers['user-agent'] ?? '';
					const headers = JSON.parse(val);
					if (!headers?.os) {
						Object.assign(headers, { os: getOs(ua), osversion: getOsVersion(ua) });
					}
					if (!headers?.runtime) {
						headers.runtime = isMobile(ua) ? (isWechat(ua) ? 'WX_H5' : 'H5') : 'PC';
					}
					if (!isEqual(headers, oldHeaders)) {
						store.dispatch(actions.configModel.setState({ headers }));
					}
					let platform = 'h5';

					if (headers?.runtime) {
						if (headers?.runtime === 'computer') {
							headers.runtime = 'pc'
						}
						const runtime = headers?.runtime?.toLowerCase();
						platform = runtime.indexOf('_mini') > -1 ? 'mini' : runtime;
					}
					if (oldPlatform != platform) {
						store.dispatch(actions.configModel.setState({ platform }));
					}
				} catch { }
			})();
		}
		try {
			cache[key] = JSON.parse(val)
			// if (typeof cache[key] === "number" && key !== "top" && key !== "bottom") {
			// 	cache[key] = String(val)
			// }
		} catch {
			cache[key] = val
		}
	});
	// if (!cache.top) {
	// 	cache.top = '0';
	// }

	if (!isEqual(cache, oldQuery)) {
		store.dispatch(
			actions.configModel.setState({ query: { ...(oldQuery ?? {}), ...(cache ?? {}) } })
		);
	}
};
