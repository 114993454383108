import type { ReactElement } from 'react';
import { createRoot } from 'react-dom/client';

import { inBrowser } from './in-browser';

export function renderToBody(element: ReactElement) {
	if (!inBrowser) return;
	const container = document.createElement('div');
	document.body.appendChild(container);

	const r = createRoot(container);
	r.render(element);
	function unmount() {
		r.unmount();
		document.body.removeChild(container);
	}
	return unmount;
}
