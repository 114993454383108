import { postApi } from '$lib/model/request';

import type {
	fetchFeedbackDetailsRes,
	IAddFeedbackParams,
	IAddFeedbackRes,
	IFeedbackConfig,
	IFeedbackHistoryRes,
	IFetchFeedbackConfigParams,
	IfetchFeedbackDetailsReq,
	IFetchFeedbackHistoryReq,
	IFetchUnreadReq
} from './types';

export default class FeedbackService {
	/** 获取反馈应用配置 */
	static fetchFeedbackConfig(params: IFetchFeedbackConfigParams) {
		return postApi<IFeedbackConfig>('/crm/v1/feedbackConfig/info', { data: params });
	}
	/** 添加意见反馈 */
	static addFeedback(params: IAddFeedbackParams) {
		return postApi<IAddFeedbackRes>('/crm/v1/feedbackRecord/add', { data: params });
	}
	/** 获取历史记录 */
	static fetchFeedbackHistory(params: IFetchFeedbackHistoryReq) {
		return postApi<IFeedbackHistoryRes>('/crm/v1/feedbackRecord/redList', {
			data: params
		});
	}
	/** 获取反馈详情 */
	static fetchFeedbackDetails(params: IfetchFeedbackDetailsReq) {
		return postApi<fetchFeedbackDetailsRes>('/crm/v1/feedbackRecord/redInfo', { data: params });
	}
	/** 获取未读消息数量 */
	static fetchUnread(params: IFetchUnreadReq) {
		return postApi<{ count: number }>('/crm/v1/feedbackInteraction/unreadCount', { data: params });
	}
}
