import { get } from '$lib/http';
import { getApi, postApi } from '$lib/model/request';

import type {
	IAreaData,
	IFetchWxConfigRes,
	IGetServerTimeResponse,
	ISecondHandCategory
} from './types';

export default class ConfigService {
	static getPHPServerTime() {
		return postApi<{ serverTime: number }>('/eventreport/v1/base/serverTime', { php: false });
	}
	static getServerTime() {
		return postApi<IGetServerTimeResponse>('/base/v1/common/getCurrentTime', { data: {} });
	}

	static fetchWxConfig(data: { url: string; wechat_token?: string }) {
		return getApi<IFetchWxConfigRes>('/login/wechatSdk/getConfig', { params: data, php: true });
	}

	static fetchAreaData() {
		return postApi<IAreaData>('/lbs/v1/area/all');
	}

	static fetchSecondHandCategory() {
		return postApi<ISecondHandCategory[]>('/fleamarket/v1/goodsCategory/query');
	}
}
