import { postApi } from '$lib/model/request';

import { ICorpTransferAccountData, ICustomerConfig, IPrePayData, IPrePayParams } from './type';

export default class CustomerService {
  /** 套餐详情 */
  static getOrderInfo(data: { orderNo: string }) {
    return postApi<ICustomerConfig>('/trade/v1/order/getMyOrder', {
      defaultLogin: false,
      data
    });
  }
  /** 订单支付接口（已有订单） */
  static submit(data: IPrePayParams) {
    return postApi<IPrePayData>('/trade/v2/order/prePay', {
      defaultLogin: false,
      data
    });
  }
  /** 对公转账账户信息  */
  static getCorpTransferAccount() {
    return postApi<ICorpTransferAccountData>('/trade/v2/order/getCorpTransferAccount', {
      defaultLogin: false,
    });
  }
}