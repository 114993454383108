import { postApi } from '$lib/model/request';

import {
	IActivityAppointResult,
	IActivityResult,
	ICreateOrderParams,
	ICreateOrderRes,
	ICreateReturnOrderReq,
	ICreateReturnOrderRes,
	IFetchCouponsRes,
	IFetchGroupBookingListParams,
	IFetchOrderListReq,
	IFetchProductDetailParams,
	IFetchReturnOrderListReq,
	IFetchReturnOrderListRes,
	IGroupBookingListRes,
	IGroupBookingOrderDetail,
	INewYearActivityRes,
	IOrderList,
	IPostShippingAddressParams,
	IProductDetailRes,
	IProductListRes,
	IPurchaseResultRes,
	IReturnOrderListReq,
	IReturnOrderListRes,
	ISelfGroupBookingListRes,
	IShippingAddressInfo,
	IUserIntegralInfo
} from './types';

export default class YpStoreService {
	/** 获取商品详情 */
	static fetchProductDetail(data: IFetchProductDetailParams) {
		return postApi<IProductDetailRes>('/trade/v1/mallGoods/detail', { data });
	}
	/** 获取商品列表 */
	static fetchProductList() {
		return postApi<IProductListRes>('/trade/v1/goodsAreas/all');
	}

	/** 新增收货地址 */
	static postShippingAddress(data: IPostShippingAddressParams) {
		return postApi('/trade/v1/receiveAddr/add', { data });
	}
	/** 修改收货地址 */
	static updateShippingAddress(data: IShippingAddressInfo) {
		return postApi('/trade/v1/receiveAddr/modify', { data });
	}
	/** 查询收货地址 */
	static fetchShippingAddress() {
		return postApi<IShippingAddressInfo>('/trade/v1/receiveAddr/queryOne');
	}
	/** 获取用户积分信息 */
	static fetchUserIntegral() {
		return postApi<IUserIntegralInfo>('/trade/v1/integral/userUsable');
	}

	/**
	 * 下单
	 * @param {ICreateOrderParams} data
	 * @returns
	 */
	static createOrder(data: ICreateOrderParams) {
		return postApi<ICreateOrderRes>('/trade/v1/goods/createOrder', { data });
	}

	/**
	 * 获取购买结果
	 */
	static fetchPurchaseResult(data: { tradeNo: string }) {
		return postApi<IPurchaseResultRes>('/trade/v1/pay/queryTradeStatus', { data });
	}

	/**
	 * 通过订单号获取当前用户中奖结果
	 * @param {{ orderNo: string }} data
	 */
	static fetchActivityResult(data: { orderNo: string }) {
		return postApi<IActivityResult>('/trade/v1/mall/activityAfterPay', { data });
	}

	/** 获取订单列表 */
	static fetchOrderList(data: IFetchOrderListReq) {
		return postApi<IOrderList>('/trade/v1/order/getUserMallOrderList', { data });
	}

	/** 活动预约 */
	static activityAppoint() {
		return postApi('/trade/v1/mall/preBook');
	}

	/** 获取预约结果 */
	static fetchActivityAppointResult() {
		return postApi<IActivityAppointResult>('/trade/v1/mall/preBookResp');
	}

	/** 获取新年活动 */
	static fetchNewYearActivity() {
		return postApi<INewYearActivityRes>('/trade/v1/mall/activities');
	}

	/** 获取拼团商品列表 */
	static fetchGroupBookingList(data: IFetchGroupBookingListParams) {
		return postApi<IGroupBookingListRes>('/marketingcenter/v1/groupPurchase/goods/pageList', {
			data
		});
	}

	/** 获取我的拼团列表 */
	static fetchSelfGroupBookingList(data: IFetchGroupBookingListParams) {
		return postApi<ISelfGroupBookingListRes>('/marketingcenter/v1/groupPurchase/user/pageList', {
			data
		});
	}

	/** 获取拼团详情 */
	static fetchGroupBookingOrderDetail(data: { purchaseOrderNo: string }) {
		return postApi<IGroupBookingOrderDetail>('/marketingcenter/v1/groupPurchase/order/detail', {
			data
		});
	}

	/**
	 * 获取优惠券列表
	 * @param {{queryType:'TEMPLATE' | 'INSTANCE' | 'COMPOSE'}} data
	 * TEMPLATE-查券，商城首页查询券 INSTANCE-查可用实例，支付时查询可用券 COMPOSE-查询券和实例，商品详情页，查询券信息
	 * @returns
	 */
	static fetchCoupons(data: {
		queryType: 'TEMPLATE' | 'INSTANCE' | 'COMPOSE';
		goodsPrice?: number;
		goodsCount?: number;
		goodsCode?: string;
	}) {
		return postApi<IFetchCouponsRes>('/trade/v1/mall/vouchers', { data });
	}

	/** 领取优惠券  */
	static receiveCoupon(data: { taskId: string; templateId: string }) {
		return postApi('/trade/v1/mall/drawVoucher', { data });
	}

	/** 退货申请 列表 */
	static returnOrderList(data: IReturnOrderListReq) {
		return postApi<IReturnOrderListRes>('/trade/v1/order/returnOrderList', {
			data
		});
	}

	/** 选择订单 列表 */
	static fetchReturnOrderList(data: IFetchReturnOrderListReq) {
		return postApi<IFetchReturnOrderListRes>('/trade/v1/order/pageQuery', {
			data
		});
	}

	/** 创建退货单 */
	static createReturnOrder(data: ICreateReturnOrderReq) {
		return postApi<ICreateReturnOrderRes>('/trade/v1/order/createReturnOrder', {
			data
		});
	}
}
