import { useEffect, useRef } from 'react';

import CreateCache, { ICacheEntity } from './store';

const useCacheListener = (
	keepAlive: CreateCache,
	cb: (args: { cacheMap?: Map<string, ICacheEntity>; current?: ICacheEntity }) => void
) => {
	const ref = useRef<boolean>();
	if (!ref.current) {
		ref.current = true;
		keepAlive.subscribeCache(cb);
	}
	useEffect(() => {
		return () => {
			ref.current = false;
			keepAlive.removeCacheListener();
		};
	}, []);
};

export default useCacheListener;
