import { postApi } from '$lib/model/request';

import {
	ICreateMembersOrderParams,
	ICreateMembersOrderRes,
	IFetchMembersConfigParams,
	IFetchMembersGoodInfo,
	IFetchMembersStatusParams,
	IFetchPurchaseRecordParams,
	IMembersGoodInfoRes,
	IMembersStatusRes,
	IPurchaseRecordRes,
	IPurchaseResultRes,
	ITeamMembersConfigRes
} from './types';

export default class TeamMembersService {
	/**
	 * 获取会员配置
	 * @param {IFetchMembersConfigParams} data
	 * @returns
	 */
	static fetchMembersConfig(data: IFetchMembersConfigParams) {
		return postApi<ITeamMembersConfigRes>('/member/v1/vipType/info', { data });
	}

	/**
	 * 获取班组会员商品信息
	 * @param {IFetchMembersGoodInfo} data
	 * @returns
	 */
	static fetchMemberGoodInfo(data: IFetchMembersGoodInfo) {
		return postApi<IMembersGoodInfoRes>('/trade/v1/goods/queryOne', { data });
	}

	/**
	 * 查询会员状态
	 * @param {IFetchMembersStatusParams} data
	 * @returns
	 */
	static fetchMembersStatus(data: IFetchMembersStatusParams) {
		return postApi<IMembersStatusRes>('/member/v1/vipMembership/status', { data });
	}

	/**
	 * 班组会员下单
	 * @param {ICreateMembersOrderParams} data
	 * @returns
	 */
	static createOrder(data: ICreateMembersOrderParams) {
		return postApi<ICreateMembersOrderRes>('/trade/v1/goods/createOrder', { data });
	}

	/**
	 * 获取购买结果
	 */
	static fetchPurchaseResult(data: { tradeNo: string }) {
		return postApi<IPurchaseResultRes>('/trade/v1/pay/queryTradeStatus', { data });
	}

	/**
	 * 获取班组会员购买记录
	 * @param {IFetchPurchaseRecordParams} data
	 * @returns
	 */
	static fetchPurchaseRecord(data: IFetchPurchaseRecordParams) {
		return postApi<IPurchaseRecordRes>('/trade/v1/order/baseInfo', { data });
	}
}
