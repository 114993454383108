import { IHttpHeaders } from '$lib/http/types';
import { postApi } from '$lib/model/request';
import { getOs, getOsVersion } from '$lib/utils/tools';

import {
    BaseParams,
    ShortLinkCheckReq,
    UserPaymentProofReq,
    UserRealNameConfirmReq
} from './types';

export const headers: Partial<IHttpHeaders> = {
    hybird: 'H5',
    platform: 'h5',
    runtime: 'h5',
    system: 'h5',
    packagename: 'yp_js_mobile',
    os: getOs(),
    osversion: getOsVersion() || 6.0,
    appversion: '6.2.0',
    version: '6.2.0',
    hybridversion: '6.2.0',
    runtimeversion: '10',
    systemVersion: '10',
    packageversion: '6.2.0'
};

export default class PayRefundService {
    /** 实名验证 */
    static IUserRealNameConfirm(data: UserRealNameConfirmReq) {
        return postApi<void>('/crm-css/v1/user/realNameConfirm', { data });
    }

    /** 短链过期检测 */
    static IShortLinkCheck(data: ShortLinkCheckReq) {
        return postApi<void>('/crm-css/v1/shortLink/check', { data });
    }

    /** 用户支付凭证 */
    static IApplyOrderUserPaymentProof(data: UserPaymentProofReq) {
        return postApi<void>('/crm-css/v1/applyOrder/userPaymentProof', { data });
    }

    /** 用户商户凭证是否提交 */
    static IApplyOrderCheckPaymentProof(data: BaseParams) {
        return postApi<Boolean>('/crm-css/v1/applyOrder/checkPaymentProof', { data });
    }

    /** 实名信息是否提交 */
    static IApplyOrderCheckPaymentInfo(data: BaseParams) {
        return postApi<Boolean>('/crm-css/v1/applyOrder/checkPaymentInfo', { data });
    }

    /** 申请工单证据资源地址 */
    static applyUpload(data: UserPaymentProofReq) {
        return postApi<void>('/crm-css/v1/multimediaResources/applyUpload', { data });
    }

}
