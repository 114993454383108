import router from 'next/router';
import type { FC, RefObject } from 'react';
import { createRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { renderToBody } from '../utils/render-to-body';
import type { DialogProps } from './Dialog';
import { Dialog } from './Dialog';

type Ref = {
	close: () => void;
	ref: RefObject<HTMLDivElement>;
};

export default function withWrapper<P = undefined>(Component: FC<Partial<DialogProps> & P>,t?: Partial<DialogProps>) {
	return (props: Omit<DialogProps, 'visible'> & P) => {
		const Wrapper = forwardRef<Ref, { children?: any } | undefined>((p, ref) => {
			const [visible, setVisible] = useState(false);
			const refEl = useRef<HTMLDivElement>(null);
			const closeDialogOnPop = (_: any, { shallow }: { shallow: boolean }) => {
				if (!shallow) unmount?.();
			};
			useEffect(() => {
				setVisible(true);
				router.events.on('routeChangeStart', closeDialogOnPop);
				return () => {
					router.events.off('routeChangeStart', closeDialogOnPop);
				};
			}, []);

			useImperativeHandle(ref, () => ({
				close: () => {
					setVisible(false);
				},
				ref: refEl
			}));

			return (
				<Dialog
					ref={refEl}
					{...t}
					{...props}
					visible={visible}
					onClose={() => {
						props?.onClose?.();
						setVisible(false);
					}}
					unmount={() => unmount?.()}
					afterClose={() => {
						props?.afterClose?.();
						unmount?.();
					}}
				>
					{p?.children}
				</Dialog>
			);
		});
		const ref = createRef<Ref>();
		const unmount = renderToBody(
			<Wrapper ref={ref}>
				<Component {...props} />
			</Wrapper>
		);
		return {
			close: () => {
				ref.current?.close();
			},
			ref: ref.current?.ref
		};
	};
}
