import { getApi, postApi } from '$lib/model/request';

import { IContactRecordParams, IContactRecordRes, IReportedRes } from './types';

/** 举报中心-service */
export default class ReportPlatformService {
	/**
	 * 获取举报中心默认展示tab
	 * @returns {{show_tab:number}} 默认展示的tab：1-我举报的信息，2-我举报的名片
	 */
	static fetchDefaultTab() {
		return getApi<{ show_tab: number }>('/job/complaint/defaultShowTab', { php: true });
	}

	/**
	 * 获取联系记录列表
	 */
	static fetchContactRecord(data: IContactRecordParams) {
		return postApi<IContactRecordRes>('/jlist/focusMe/getMeContactList', { php: true, data });
	}

	/**
	 * 获取我投诉的招工信息
	 */
	static fetchReportedRecruit(params: { page: number; page_size: number }) {
		return getApi<IReportedRes>('/jlist/complaint/jobComplaintList', { php: true, params });
	}

	/**
	 * 获取我投诉的找活信息
	 */
	static fetchReportedResume(params: { page: number; page_size: number }) {
		return getApi<IReportedRes>('/jlist/complaint/resumeComplaintList', { php: true, params });
	}
}
