import './index.scss';

import classNames from 'classnames';
import React, {
	cloneElement,
	EffectCallback,
	memo,
	ReactElement,
	useEffect,
	useState
} from 'react';

import { inBrowser } from '../utils/in-browser';
import KeepAliveContext from './context';
import type { ICacheEntity } from './store';
import useCacheListener from './useCacheListener';
import useKeepAlive from './useKeepAlive';
import { ICacheComponentOptions } from './withKeepAlive';

export type IComponent = (string | React.JSXElementConstructor<any>) & {
	cacheInfo?: ICacheComponentOptions;
};

const KeepAliveProvider = (props: { children: ReactElement }) => {
	const { children } = props;
	const tChild = cloneElement(children);
	const componentType = tChild?.type as IComponent;
	const { key: keepAliveKey = '', scrollContainer } = componentType?.cacheInfo || {};
	const isKeepAlive = !!componentType?.cacheInfo;
	const [keepAlive] = useKeepAlive();
	const [cacheMap, setCacheMap] = useState<Map<string, ICacheEntity>>();
	const isEnabled = !!cacheMap?.get(keepAliveKey);
	useCacheListener(keepAlive, ({ cacheMap: newCacheMap }) => {
		setCacheMap(newCacheMap);
	});
	if (!!keepAliveKey && !cacheMap?.get(keepAliveKey)) {
		keepAlive.addCache(keepAliveKey, {
			Component: memo(componentType as any),
			props,
			scrollY: 0,
			key: keepAliveKey
		});
	}

	const keepAliveEffect = (cacheKey: string) => {
		const useKeepAliveEffect = (effect: EffectCallback, dependencies?: any[]) =>
			useEffect(() => {
				if (cacheKey === keepAliveKey) {
					return effect();
				}
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, dependencies);

		return useKeepAliveEffect;
	};
	const handleRemoveCache = (event: any) => {
		const { key: removeCacheKey } = event?.detail || {};
		keepAlive.removeCache(removeCacheKey ?? undefined);
		if (inBrowser) {
			scrollContainer?.scrollTo(0, 0);
		}
	};

	useEffect(() => {
		window.addEventListener('removeKeepAlive', handleRemoveCache);
		return () => {
			window.removeEventListener('removeKeepAlive', handleRemoveCache);
		};
	}, []);

	return (
		<KeepAliveContext.Provider value={keepAlive}>
			{(!isKeepAlive || !isEnabled) && children}
			<div className={classNames('kp-container', { hide: !isKeepAlive || !isEnabled })}>
				{Array.from(cacheMap || []).map(([cacheName, { Component, props: cachedProps }]: any) => (
					<div
						key={cacheName}
						className={classNames('kp-item-container', { hide: keepAliveKey !== cacheName })}
					>
						<Component
							useKeepAliveEffect={keepAliveEffect(cacheName)}
							keepAliveKey={keepAliveKey}
							{...cachedProps}
						/>
					</div>
				))}
			</div>
		</KeepAliveContext.Provider>
	);
};

export default KeepAliveProvider;
