/*
 * @Author: sankki 1257477428@qq.com
 * @Date: 2023-12-25 15:41:45
 * @Description: 
 */
import { postApi } from '$lib/model/request';

import { IUserInfo, IUserRealNameStatus } from './types';

export default class UserService {
	static fetchUserInfo() {
		return postApi<IUserInfo>('/account/v1/user/simple/info');
	}
	static fetchRealNameStatus(data?: { wechat_token: string }) {
		return postApi<IUserRealNameStatus>('/account/v1/realname/info', { data });
	}
	static fetchUserCenter() {
		return postApi('/account/v1/userBase/userCenter')
	}
}
