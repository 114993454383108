import { getApi, postApi } from '$lib/model/request';

import { IDiamondListData, JobCategory } from './types';

export default class DiamondService {
  /* 快速区域列表 */
  static fetchTabs(data: any, headers: Record<string, any>) {
    return postApi<IDiamondListData>('/job/v3/list/job/quickAreaListTab', {
      data,
      // headers
    })
  }
  /* 快速区域列表导航栏 */
  static fetchList(data: any, headers: Record<string, any>) {
    return postApi<IDiamondListData>('/job/v3/list/job/quickAreaList', {
      data,
      // headers
    });
  }
  /* 埋点参数 */
  static fetchBury(data: any) {
    return postApi<IDiamondListData>('/jobc/home/config', {
      data,
      php: true,
    });
  }
  /* 获取职位标签 */
  static fetchJobTag(data: any) {
    return postApi<JobCategory>('/occupation/v1/partTime/treeQuery', data)
  }
  /* IP解析城市信息 */
  static fetchIpResolve(data: any) {
    return postApi<any>('/lbs/v1/location/getAreaByIp', data)
  }
}
