import { getApi, postApi } from '$lib/model/request';

import {
	AccountMonetRes,
	ApplyReq,
	ApplyRes,
	DetailsReq,
	DetailsRes,
	IGetCashoutRulesReq,
	IGetCashoutRulesRes,
	RecordListReq,
	RecordListRes
} from './types';

export default class WithdrawalService {
	/** 获取余额 */
	static getAccountMoney(params: { active_id: string }) {
		return getApi<AccountMonetRes>('/active/cashout/account', {
			params,
			php: true
		});
	}
	/** 获取提现记录列表 */
	static getRecordList(params: RecordListReq) {
		return getApi<RecordListRes>('/active/cashout/statement', {
			params,
			php: true
		});
	}
	/** 获取提现记录详情 */
	static getDetails(params: DetailsReq) {
		return getApi<DetailsRes>('/active/cashout/detail', {
			params,
			php: true
		});
	}
	/** 申请提现，更新提现信息 */
	static applyWithdrawal(params: ApplyReq) {
		return postApi<ApplyRes>('/active/cashout/save', {
			data: params,
			php: true
		});
	}
	/** 获取提现规则 */
	static getCashoutRules(params: IGetCashoutRulesReq) {
		return getApi<IGetCashoutRulesRes>('/active/cashout/rules', {
			params,
			php: true
		});
	}
}
