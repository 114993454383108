import { useRef } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '$lib/model/wrapper/memberVip';

/** 判断当前版本号 */
export const useAppVersion = (version = 600) => {
	const { headers } = useSelector((state: AppState) => state.configModel);
	const isNewPurchase = useRef<boolean>(true);
	/** 端上的版本号 */
	const appVersion = headers?.appversion || headers?.appVersion || '';
	isNewPurchase.current = Number(appVersion?.split('.').join('')) >= version;
	return isNewPurchase.current;
};
