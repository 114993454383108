import qs from 'qs';

import { toast } from '$components/shared/toast';

import { YP_CDN } from './env';

function loadScriptSync(src: string) {
	return new Promise<void>((resolve, reject) => {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = src;
		document.body.appendChild(script);
		script.onload = function () {
			resolve();
		};
		script.onerror = function () {
			reject();
		};
	});
}

/** mini相关api */
export class MiniApi {
	static baiduSdk: string = YP_CDN + '/js/baidu_sdk.js';

	static getPlatform() {
		const isBaidu = /swan\//.test(window.navigator.userAgent) || /^webswan-/.test(window.name);
		if (isBaidu) return 'baidu';
		return 'wx';
	}

	static async loadApi() {
		try {
			if (this.platform === 'baidu') {
				if (!window.swan) await loadScriptSync(this.baiduSdk);
			} else {
				!window.wx && (window.wx = await import('weixin-js-sdk'));
			}
		} catch { }
	}

	/** SDK初始化*/
	static async init() {
		try {
			await MiniApi.loadApi();
		} catch {
			console.error('初始化jssdk失败');
		}
	}

	/** 微信鉴权 */
	static config(args: any) {
		MiniApi.init().then(() => {
			wx.config({
				debug: false,
				jsApiList: ['getLocation', 'chooseImage'],
				...args
			});
		});
	}

	static navigateTo(...args: any[]) {
		MiniApi.init().then(() => {
			this.miniPrefix?.navigateTo(...args);
		});
	}

	static reLaunch(...args: any[]) {
		MiniApi.init().then(() => {
			this.miniPrefix?.reLaunch(...args);
		});
	}

	static switchTab(...args: any[]) {
		MiniApi.init().then(() => {
			this.miniPrefix?.switchTab(...args);
		});
	}
	static postMessage(args: any) {
		MiniApi.init().then(() => {
			this.miniPrefix?.postMessage({ data: args });
		});
	}
	static navigateBack(...args: any[]) {
		MiniApi.init().then(() => {
			this.miniPrefix?.navigateBack(...args);
		});
	}

	static redirectTo(...args: any[]) {
		MiniApi.init().then(() => {
			this.miniPrefix?.redirectTo(...args);
		});
	}

	static preview(args: any) {
		console.error('navigatePreview', args);
		MiniApi.redirectTo({
			url:
				`/subpackage/used/lists/index` +
				'?' +
				qs.stringify({ path: location.pathname, isSelfDetail: false, type: 'preview', ...args }),
			fail: function () {
				// 给用户提示，需要重新操作！
				toast.show('操作失败，请重试');
			}
		});
	}

	static navigatePreview(args: any) {
		console.error('navigatePreview', args);
		MiniApi.navigateTo({
			url: '/subpackage/used/lists/preview/index' + '?' + qs.stringify(args)
		});
	}

	private static get platform(): 'wx' | 'baidu' {
		return MiniApi.getPlatform();
	}

	private static get prefix(): typeof wx | typeof swan | undefined {
		if (this.platform === 'baidu') {
			if (!swan) {
				console.error('请先初始化百度SDK');
				return undefined;
			}
			return swan;
		} else {
			if (!wx) {
				console.error('请先初始化微信SDK');
				return undefined;
			}
			return wx;
		}
	}

	private static get miniPrefix(): typeof wx['miniProgram'] | typeof swan['webView'] | undefined {
		if (this.platform === 'baidu') {
			if (!swan) {
				console.error('请先初始化百度SDK');
				return undefined;
			}
			return swan.webView;
		} else {
			if (!wx) {
				console.error('请先初始化微信SDK');
				return undefined;
			}
			return wx.miniProgram;
		}
	}

	static async getLocation(args: { type: string }): Promise<{
		latitude: string | number;
		longitude: string | number;
	}> {
		return new Promise(async (resolve, reject) => {
			await MiniApi.init();
			if (this.platform === 'wx') {
				wx.ready(async function () {
					MiniApi.prefix?.getLocation({
						...args,
						success: function (res: any) {
							return resolve(res);
						}
					});
				});
				wx.error(function () {
					return reject();
				});
			} else {
				MiniApi.prefix?.getLocation({
					...args,
					success: function (res: any) {
						return resolve(res);
					}
				});
			}
		});
	}
}
