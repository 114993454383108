/**
 * @description 本地存储的KEY
 */
/** TOKEN */
export const TOKEN = 'userModel.token';
export const DIFF_TIME = 'DIFF_TIME';
export const SHARE_EXTRA_PARAMS = 'SHARE_EXTRA_PARAMS';
/** 资讯详情点赞缓存 */
export const ARTICLE_PRAISE = 'ARTICLE_PRAISE';

/** 大转盘，广告教育弹窗展示次数 */
export const VIDEO_DIALOG_REST = 'VIDEO_DIALOG_REST';

export const VIDEO_DIALOG_LIMIT = 'VIDEO_DIALOG_LIMIT';
/** 大转盘，教育广告弹出次数 */
export const VIDEO_DIALOG_TIMES = 'VIDEO_DIALOG_TIMES';

/** 教育弹窗，每日弹出次数 */
export const VIDEO_DIALOG_TIMES_DAILY = 'VIDEO_DIALOG_TIMES_DAILY';
/** 上次教育弹窗弹出时间 */
export const LAST_VIDEO_DIALOG_SHOWED_TIME = 'LAST_VIDEO_DIALOG_SHOWED_TIME';

export const UID = 'UID';

/** m-支付页订单号与支付类型信息 */
export const M_PAY_INFO = 'M_PAY_INFO';

/** 元宵节活动页面 非首次进入/点击过分享 */
export const LANTERN_SHARED = 'LANTERN_SHARED';
/** 群发炸弹 历史支付方式 */
export const LAST_BOMB_PAY_TYPE = 'LAST_BOMB_PAY_TYPE';
/** 群发炸弹挽留弹窗弹出时间 */
export const LATEST_POPUP_RETAIN_BOMB = 'LATEST_POPUP_RETAIN_BOMB';

// 是否首次进入商品促销活动页面（送短袖活动）-首次
export const YP_ACTIVITY_SEND_T_SHIRT_FIRST_IN_TAG = 'YP_ACTIVITY_SEND_T_SHIRT_FIRST_IN_TAG';
// 点击返回挽留一下-当天
export const YP_ACTIVITY_CLOSE_KEEP_LIVE = 'YP_ACTIVITY_CLOSE_KEEP_LIVE';
// 是否点击了我的奖品-当天
export const YP_ACTIVITY_HAC_CLICK_MY_PRIZE_BTN = 'YP_ACTIVITY_HAC_CLICK_MY_PRIZE_BTN';


/** 竞招 历史支付方式 */
export const CAMPAIGN_RECRUITMENT_PAY_TYPE = 'CAMPAIGN_RECRUITMENT_PAY_TYPE';


