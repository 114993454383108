import 'intersection-observer';

import { canUseDom } from '$components/shared/utils/can-use-dom';
import { IReduxContext, wrapper } from '$lib/model/wrapper/common';
import { getReduxCtxSync } from '$lib/utils/getReduxContext';

import { VOLC_ENGINE_APP_ID } from '../env';

// tag str
const TAG = 'DATA-ID';
const TAG_SET_NAME = 'DATA-SET';

// observerSn
let overObservered: Map<string, SN_Object> = new Map();
export default class BuryPoint {
	// preset profile
	static profile: Profile | null;
	// observer instance
	static observer: IntersectionObserver | null;
	// target instances
	static observerTargets: React.RefObject<any>[] = [];
	static root: string | undefined = undefined;
	static timeout: number = 1000;
	static threshold: number = 0.8;
	static offsetTop: number = 0;
	static evtName: string = '';
	static async preset() {
		if (!canUseDom) return;

		const {
			configModel: { query }
		} = getReduxCtxSync<IReduxContext>(wrapper).getState();
		let user_unique_id = String(query?.uid ?? '');

		console.error(user_unique_id);
		this.profile = { user_unique_id };
		if (user_unique_id) {
			this.collectEvent('config', { user_unique_id });
		}
	}

	static async clearpreset() {
		this.profile = null;
	}

	static async observerReset() {
		// 注销 Observer
		if (this.observer) this.observer.disconnect();
		// 重置map
		overObservered = new Map();
		// 重新创建 Observer
		this.createIntersection(
			{
				root: this.root!,
				timeout: this.timeout,
				threshold: this.threshold,
				eventName: this.evtName
			},
			this.callback
		);
	}

	static callback(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
		const timestamp = Date.now();
		entries.map((entry) => {
			const {
				top: offsetTop,
				bottom: offsetBottom,
				height: offsetHeight
			} = entry.boundingClientRect;
			const { top: viewportTop = 0, bottom: viewportBottom = 0 } = entry.rootBounds ?? {};

			// console.log(viewportTop, viewportBottom, offsetTop, offsetBottom, this.threshold);

			// 百分之八十进入视区时，开始计时
			if (
				Math.floor(offsetBottom - viewportTop) > Math.floor(offsetHeight * this.threshold) &&
				viewportBottom - offsetTop > Math.floor(offsetHeight * this.threshold)
			) {
				const target = entry.target;
				const sn = target.getAttribute(TAG) as string;

				// 首次进入视区记录时间， 重新进入视区更新时间
				if (!overObservered.has(sn) || overObservered.get(sn)?.hasCollected === false) {
					overObservered.set(sn, {
						timestamp,
						hasCollected: false
					});
				}
			}
			// 百分之百离开视区时，开始上报
			if (offsetBottom < viewportTop || offsetTop > viewportBottom) {
				const target = entry.target;
				const sn = target.getAttribute(TAG) as string;
				// 已经记录了时间
				if (overObservered.has(sn)) {
					const collectEvt = target.getAttribute(TAG_SET_NAME)!;
					const sn_obj = overObservered.get(sn)!;
					const timestamp = Date.now();
					// 曝光时间大于一秒 且没有上报过此条数据
					const collectable =
						timestamp - sn_obj.timestamp >= this.timeout && sn_obj.hasCollected === false;
					if (collectable) {
						overObservered.set(sn, { timestamp, hasCollected: true });
						this.collectEvent?.(this.evtName, JSON.parse(collectEvt));
					}
					// 更新包裹时间
					// else {
					// 	overObservered.set(sn, {
					// 		hasCollected: sn_obj.hasCollected ?? false,
					// 		timestamp
					// 	});
					// }
				}
			}
		});
	}

	static async initEngine() {
		if (!window.collectEvent) {
			try {
				await import('./burypoint');
				(window['collectEvent'] as any)('init', {
					// init evt dispatch
					app_id: VOLC_ENGINE_APP_ID as unknown as number, // expected a number
					channel_domain: `https://snssdk.yupaowang.com`, // default channel
					log: true, // debug
					autotrack: false, // 全埋点开关
					spa: true
				});
				(window['collectEvent'] as any)('start');
			} catch (error) {
				console.error(error);
			}
		}
	}

	static async collectEvent(evtName: string, options?: Record<string, SimpleType>) {
		if (!canUseDom) return;
		// this.initEngine();
		setTimeout(() => {
			window?.collectEvent?.(evtName, options);
		}, 100);
	}

	/** 去掉延迟 */
	static async syncCollectEvent(evtName: string, options?: Record<string, SimpleType>) {
		if (!canUseDom) return;
		window?.collectEvent?.(evtName, options);
	}

	// 创建Observer
	static async createIntersection(cfg: ObserveConfig, callback?: IntersectionObserverCallback) {
		// this.initEngine();
		const { root, threshold, timeout, offsetTop, eventName = '' } = cfg;
		this.root = root;
		if (threshold) {
			this.threshold = threshold ?? this.threshold;
		}
		this.timeout = timeout ?? this.timeout;
		const rootEl = root ? document.getElementById(root) : document;
		this.callback = callback ?? this.callback.bind(this);
		this.offsetTop = offsetTop ?? this.offsetTop;
		this.evtName = eventName;

		if (!rootEl) return;

		// 没有观察者时导入polyfill 并上传
		if (!this.observer) {
			this.observer = new IntersectionObserver(this.callback, {
				root: rootEl,
				threshold:
					this.threshold > 0 && this.threshold < 1 ? [0, this.threshold, 1] : this.threshold,
				rootMargin: `${-this.offsetTop}px 0px 0px 0px`
			});
		}

		while (this.observerTargets.length > 0) {
			let el = this.observerTargets.shift()?.current;
			if (el) this.observer.observe(el);
		}
	}

	// 设置观察对象
	static async observe<T extends HTMLElement = HTMLElement>(
		target: React.RefObject<T>,
		dataset: InlineDataSet
	) {
		// 设置元数据
		if (target.current) {
			target.current.setAttribute(TAG, String(dataset.unique_id));
			target.current.setAttribute(TAG_SET_NAME, JSON.stringify(dataset));
		}
		if (!this.observer) return this.observerTargets.push(target);
		else target.current && this.observer.observe(target.current!);
		// 如果容器里有数据则提交数据给观察者
		while (this.observerTargets.length > 0 && this.observer) {
			let el = this.observerTargets.shift()?.current;
			if (el) requestAnimationFrame(() => this.observer?.observe(el));
		}
		this.observer?.observe(target.current as T);
	}
}

interface Profile extends Record<string, string> {
	user_unique_id: string;
}

interface InlineDataSet extends Record<string, any> {
	unique_id: string | number;
}

interface SN_Object {
	timestamp: number;
	hasCollected: boolean;
}

interface ObserveConfig {
	eventName: string;
	threshold?: number;
	// id
	root?: string;
	timeout?: number;
	offsetTop?: number;
}
