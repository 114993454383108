import { HttpOptions } from '$lib/http/types';
import { postApi } from '$lib/model/request';

import {
	IFetchSecondHandContactRecordParams,
	IFetchSecondHandListParams,
	IFetchSecondHandManageParams,
	ISecondHandContactRecordData,
	ISecondHandContactRecordDetail,
	ISecondHandDetail,
	ISecondHandForm,
	ISecondHandListData,
	ISecondHandManageData,
	ISecondHandPhoneData,
	IUpdateSecondHandStatusParams
} from './types';

export default class SecondHandService {
	static addSecondHand(data: ISecondHandForm) {
		return postApi('/fleamarket/v1/goods/add', { data });
	}
	static editSecondHand(data: ISecondHandForm) {
		return postApi('/fleamarket/v1/goods/update', { data });
	}
	static fetchDetail(data: { id: string | number; userId?: string | number }) {
		return postApi<ISecondHandDetail>('/fleamarket/v1/goods/query', { data });
	}

	static fetchList(data: IFetchSecondHandListParams) {
		return postApi<ISecondHandListData>('/fleamarket/v1/goods/pageQueryEs', { data });
	}

	static fetchContactRecord(data: IFetchSecondHandContactRecordParams) {
		return postApi<ISecondHandContactRecordData>(
			'/fleamarket/v1/goodsUserContactRecord/pageQueryMy',
			{ data }
		);
	}

	static fetchManageList(data: IFetchSecondHandManageParams) {
		return postApi<ISecondHandManageData>('/fleamarket/v1/goods/queryPageMy', { data });
	}

	static updateStatus(data: IUpdateSecondHandStatusParams) {
		return postApi('/fleamarket/v1/goods/updateStatus', { data });
	}

	static fetchPhoneNumber(data: { id: number | string }, options?: Partial<HttpOptions>) {
		return postApi<ISecondHandPhoneData>('/fleamarket/v1/goods/queryTel', {
			data,
			...(options ?? {})
		});
	}
	static fetchContactPhoneNumber(data: { id: number | string }) {
		return postApi<ISecondHandContactRecordDetail>(
			'/fleamarket/v1/goodsUserContactRecord/queryTelById',
			{
				data
			}
		);
	}

	static fetchVerifyCode(data: { mobile: string }) {
		return postApi<{ number: number; time: number }>('/fleamarket/v1/message/send', { data });
	}
}
