import get from 'lodash/get';
import has from 'lodash/has';
import set from 'lodash/set';

import { browser } from '$lib/utils/tools';
import { getSessionStore, setSessionStore } from '$lib/utils/tools/storage';

const KEY = '__YP_SCROLL';
class CreateScroll {
	private cacheScroll: Record<string, number> = {};
	private scrollKey: string = '';
	private cacheKey: string = '';
	constructor() {
		if (browser) {
			const storage = getSessionStore(KEY);
			if (storage) {
				if (Object.keys(storage).length < 1) {
					this.cacheScroll = {};
				} else {
					this.cacheScroll = getSessionStore(KEY);
				}
			}
		}
	}
	updateScrollKey(path: string) {
		this.scrollKey = path.replace(/\?.*/, '');
	}
	updateCacheKey(path: string) {
		this.cacheKey = path;
	}
	updateCacheScroll(info?: { [key: string]: number }) {
		if (browser) {
			if (info) {
				Object.assign(this.cacheScroll, info);
			} else {
				const scrollValue = window?.document?.scrollingElement?.scrollTop || 0;
				if (has(this.cacheScroll, this.cacheKey)) {
					this.cacheScroll[this.cacheKey] = scrollValue;
				} else {
					set(this.cacheScroll, this.cacheKey, window?.document?.scrollingElement?.scrollTop || 0);
				}
			}

			setSessionStore(KEY, this.cacheScroll);
		}
	}
	getCacheScroll() {
		return this.cacheScroll;
	}
	scroll(path: string) {
		const tPath = path.replace(/\?.*/, '');
		if (tPath && tPath === this.scrollKey) {
			browser && window.scrollTo(0, get(this.cacheScroll, this.scrollKey) || 0);
		}
	}
	destroy() {
		this.scrollKey = '';
		this.cacheKey = '';
	}
}

export default CreateScroll;
