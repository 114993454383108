import { postApi } from '$lib/model/request';

import { ApplyOrderAddr, UploadParams } from './types';

export default class ordinaryService {
	// static uploadBeforeQuery(data: any) {
	// 	return postApi<any>('/audit/v1/workOrder/proofResourceApply', { data });
	// }
	static uploadBeforeQuery(data: ApplyOrderAddr) {
		return postApi<any>('/audit/v1/workOrder/applyUpload', { data });
	}
	//上传
	static verifyUpLoad(data: UploadParams) {
		return postApi<any>('/audit/v1/workOrder/addUserProof', { data });
	}
	//验证
	static verifyValidity(data: any) {
		return postApi<any>('/audit/v1/workOrder/link/enable', { data });
	}
}
