import { H5_CDN, YP_CDN } from '$lib/utils/env';
//! 禁止上传webp
const prefix = `${YP_CDN}/images/`;
// 新的cdn前缀
const newPrefix = `${H5_CDN}/images/`;
// 未带images的前缀
const newPrefixNew = `${H5_CDN}/`;
// 新的cdn前缀
const newPath = `${H5_CDN}/`;

// const webpSuffix = '?x-oss-process=image/format';
//! 后期再支持webp
const fullCDN = (path: string) => prefix + path;
export const ARTICLE_LIKE_HIGHLIGHT = `${prefix}icon_great_hig.png`;
export const ARTICLE_LIKE_NORMAL = `${YP_CDN}/images/icon_great_nor@2x.png`;
export const CUSTOM_SERVICE_ICON = `${prefix}lqx/custom_service_icon.png`;
export const UPLOAD_ERROR_ICON = `${prefix}upload-error-image-icon.png`;
export const RE_UPLOAD_ICON = `${prefix}re-upload-icon.png`;
export const ICON_CAPTURE_CAMERA = `${prefix}lqx/icon_capture_camera.webp`;
export const ICON_CAPTURE_LIBRARY = `${prefix}lqx/icon_capture_library.webp`;
export const ICON_CAPTURE_VIDEO = `${prefix}lqx/icon_capture_video.webp`;
export const FEEDBACK_SERVICE_ICON = `${prefix}feedback-service-icon.png`;
export const FEEDBACK_ARROW = `${prefix}feedback-arrow.png`;
export const FEEDBACK_ARROW_NORMAL = `${prefix}feedback-arrow-normal.png`;
export const ICON_BACK_CIRCLE = `${prefix}lqx/icon_back_circle.png`;
export const ICON_POPUP_CLOSE = `${prefix}lqx/icon_popup_close.png`;
export const ICON_MBX = `${prefix}lqx/icon_mbx.png`;
export const ICON_LOCATION = `${prefix}lqx/icon_location.png`;
/** 地图label上面的icon */
export const MAP_LABEL_CHECK = `${prefix}lqx/icon_map_check.png`;
/** 地图定位图标 */
export const MAP_POSITION = `${prefix}lqx/icon_map_position.png`;

/** 意见反馈 二维码 */
export const FEEDBACK_QRCODE_ICON = `${YP_CDN}/images/feedback-qrcode-icon.png`;

/** 用户头像 */
export const USERIMG = `${prefix}user_img.png`;

/** 选择器选中icon图 */
export const SELCET_ICON = `${prefix}icon_pay_y.png`;

/** 默认logo */
export const HEAD_LOGO = `${prefix}lqx/default_logo_header.png`;

/** 二手交易封面展位图 */
export const SECOND_HAND_PLACEHOLDER = `${newPrefix}lqx/yp_second_hand_placeholder.png`;

/** 二手交易列表底部button */
export const SECOND_HAND_BOTTOM = `${prefix}lqx/second_hand_home_button.png`;

export const EMPTY_CARD = `${prefix}lqx/icon_empty.png`;

export const SEARCH_EMPTY_CARD = `${prefix}lqx/icon_search_empty.png`;

export const DEFAULT_LOGO_CLEARLY = `${prefix}lqx/default_logo_clearly.png`;

/** pc端积分充值页相关图片  */
export const PC_HOME_LOGO = `${newPrefix}lt/pc_home_logo1.png`;

export const PC_EMPTY = fullCDN('lqx/pc_empty.png?x-oss-process=image/format,webp');

export const PC_LAYOUT_HEADER_BG = `${newPrefix}lqx/pc_layout_header_bg5.png`;

export const PC_RECHARGE_SUCCESS = fullCDN('lqx/recharge_success.png');

export const PC_RECHARGE_FAILED = fullCDN('lqx/recharge_failed.png');

export const PC_RECHARGE_SUCCESS_MINI = fullCDN('lqx/recharge_success_mini.png');

export const PC_RECHARGE_WECHAT = fullCDN('lqx/recharge_wechat_icon.png');

export const PC_RECHARGE_ALIPAY = fullCDN('lqx/recharge_alipay_icon.png');

export const PC_RECHARGE_QRCODE_ICON = fullCDN('lqx/qrcode_icon.png');

export const ICON_RIGHT_ARROW = fullCDN('lqx/icon-right-arrow.png');
/** end */

/** 工厂保专区新功能介绍 */
export const PROTECT_INTRODUCE1 = `${prefix}lt/introduce1.png`;
export const PROTECT_INTRODUCE2 = `${prefix}lt/introduce2.png`;
export const PROTECT_INTRODUCE3 = `${prefix}lt/introduce3.png`;

/** 未选中 */
export const NOT_SELECT = fullCDN(`why/not-select.png`);
/** 积分优惠 */
export const RECHARGE = fullCDN(`why/recharge_m_banner.png`);
/** 积分背景 */
export const RECHARGE_BG = `${newPrefix}lt/recharge_m_bg5.png`;

/** 选中 */
export const SELECT = fullCDN(`why/select.png`);
/** 微信图标 */
export const WX = fullCDN(`why/wx.png`);
/** 支付宝图标 */
export const ZFB = fullCDN(`why/zfb.png`);
/** 到期结束 */
export const PAST = fullCDN(`why/past.png`);
/** 失败 */
export const FAIL = fullCDN(`why/pay_fail.png`);

/** 专属客服 */
export const CUSTOMER_IMG1 = `${prefix}lt/customer_img1.png`;
export const CUSTOMER_IMG2 = `${prefix}lt/customer_img2.png`;
export const CUSTOMER_IMG3 = `${prefix}lt/customer_img3.png`;
export const CUSTOMER_IMG4 = `${prefix}lt/customer_img4.png`;
export const CUSTOMER_IMG5 = `${prefix}lt/customer_img5.png`;
export const CUSTOMER_TITLE = `${prefix}lt/customer_title.png`;
export const CUSTOMER_CODE = `https://cdn.yupaowang.com/yupao/images/lt/customer_code.png`;

/** 鱼泡安全中心 banner */
export const SAVE_CENTER = `${prefix}lt/save_banner.png`;

/** 标题 */
export const SAVE_TITLE1 = `${prefix}lt/title_bzzx_1.png`;
export const SAVE_TITLE2 = `${prefix}lt/title_bzzx_2.png`;
export const SAVE_TITLE3 = `${prefix}lt/title_bzzx_3.png`;
export const SAVE_TITLE4 = `${prefix}lt/title_bzzx_4.png`;
export const SAVE_TITLE5 = `${prefix}lt/title_bzzx_5.png`;
export const SAVE_TITLE6 = `${prefix}lt/bzzx_title_6.png`;
export const SAVE_TITLE7 = `${prefix}lt/bzzx_title_7.png`;
export const SAVE_TITLE8 = `${prefix}lt/bzzx_title_8.png`;
export const SAFE_TITLE9 = `${prefix}lt/title_bzzx_9.png`;
export const SAFE_TITLE10 = `${prefix}lt/title_bzzx_10.png`;

/** 鱼泡安全中心 图片 */

/** 安全号  */
export const SAFE_img1 = `${prefix}lt/safe_aqhy_img.png`;
/** 隐私保障 */
export const SAFE_img2 = `${prefix}lt/safe_ysbh_img.png`;
/** 七项保障服务 */
export const SAVE_img1 = `${prefix}lt/safe_qxbzfw_img2.png`;
/** 实名认证 */
export const SAVE_img2 = `${prefix}lt/safe_smrz_img1.png`;
/** 企业认证 */
export const SAVE_img3 = `${prefix}lt/safe_qyrz_img.png`;
/** 安全号保障 */
export const SAVE_img4 = `${prefix}lt/safe_aqhbz_img.png`;
/** 隐私保障 */
export const SAVE_img5 = `${prefix}lt/safe_ysbz_img.png`;
/** 务工意外险 */
export const SAVE_img6 = `${newPrefix}lt/safe_wgywx_img1.png`;
/** 到场保障 */
export const SAVE_img7 = `${prefix}lt/safe_dgbz_img.png`;
/** 视频面试 */
export const SAVE_img8 = `${prefix}lt/save_spms_img.png`;
/** 客服 */
export const SAVE_img9 = `${prefix}lt/save_call.png`;

/** 到场服务 */
/** 鱼泡安全中心banner图 */
export const DEPOSIT_SAFETY_BANNER = `${prefix}lqx/deposit_agreement.png`;

/** 安全保证 */
export const SAVE_GUARANTEE = `${prefix}lt/safe_guarantee3.png`;

/** 班组vip */
export const TEAM_MEMBERS_BG = `${prefix}lqx/team_members_bg.png`;
export const TEAM_MEMBERS_PURCHASE_ICON1 = `${prefix}lqx/team_members_purchase_icon1.png`;
export const TEAM_MEMBERS_PURCHASE_ICON2 = `${prefix}lqx/team_members_purchase_icon2.png`;
export const TEAM_MEMBERS_PURCHASE_ICON3 = `${prefix}lqx/team_members_purchase_icon3.png`;
export const TEAM_MEMBERS_PURCHASE_ICON4 = `${prefix}lqx/team_members_purchase_icon4.png`;
export const TEAM_MEMBERS_PURCHASE_ICON5 = `${prefix}lqx/team_members_purchase_icon5.png`;
export const TEAM_MEMBERS_PURCHASE_ICON6 = `${prefix}lqx/team_members_purchase_icon6.png`;
export const TEAM_MEMBERS_DIALOG_BG1 = `${prefix}lqx/team_members_dialog_bg1.png`;
export const TEAM_MEMBERS_DIALOG_BG2 = `${prefix}lqx/team_members_dialog_bg2.png`;
export const TEAM_MEMBERS_PURCHASE_RECORD_ICON = `${prefix}lqx/team_members_purchase_record_icon.png`;
export const TEAM_MEMBERS_BUOY = `${prefix}lqx/team_members_buoy.png`;
/** end */
/** 加群引导 */
export const GROUP_WX = `${prefix}lt/group_wx.png`;
/** 首充 */
export const RECHARGE_BG1 = `${newPrefix}lt/recharge-bg1.png`;
export const RECHARGE_BG2 = `${newPrefix}lt/recharge-bg3.png`;

export const RECHARGE_BG3 = `${newPrefix}lt/recharge-bg333.png`;
export const RECHARGE_BG4 = `${newPrefix}lt/recharge-bg444.png`;
export const RECHARGE_BG5 = `${newPrefix}lt/recharge-bg555.png`;
export const RECHARGE_BG6 = `${newPrefix}lt/recharge-bg666.png`;
export const RECHARGE_BG8 = `${newPrefix}lt/recharge-bg888.png`;
export const RECHARGE_BG9 = `${newPrefix}lt/recharge-bg999.png`;

export const RECHARGE_BG7 = `${newPrefix}lt/recharge-qrcode.png`;

export const RECHARGE_GO = `${prefix}lt/recharge-go.png`;
export const RECHARGE_NOW = `${prefix}lt/recharge-now.png`;
export const RECHARGE_TIME = `${prefix}lt/recharge-time.png`;
export const RECHARGE_TITLE = `${prefix}lt/recharge-title1.png`;

/** 鱼泡商城 */
export const YP_STORE_HEADER_BG = `${prefix}lqx/yp_store_header_bg.png`;
export const YP_STORE_HEADER_TITLE = `${prefix}lqx/yp_store_header_title.png`;
export const YP_STORE_BOX_BG = `${prefix}lqx/yp_store_box_bg.png`;
export const YP_STORE_SUBTITLE = `${prefix}lqx/yp_store_subtitle.png`;
export const YP_STORE_ORDER_RECORD = `${newPrefix}lqx/yp_store_order_icon.png`;
export const YP_STORE_SUBTITLE1 = `${prefix}lqx/yp_store_subtitle1.png`;
export const YP_STORE_TAG = `${prefix}lqx/yp_store_tag.png`;
export const YP_STORE_TAG1 = `${prefix}lqx/yp_store_tag1.png`;
export const YP_STORE_TAG2 = `${prefix}lqx/yp_store_tag2.png`;
export const YP_STORE_TAG3 = `${prefix}lqx/yp_store_tag3.png`;
export const YP_STORE_INCREASE = `${prefix}lqx/yp_store_increase.png`;
export const YP_STORE_DECREASE_ACTIVE = `${prefix}lqx/yp_store_decrease_active.png`;
export const YP_STORE_DECREASE = `${prefix}lqx/yp_store_decrease.png`;
export const STORE_ICON_DANCE = `${prefix}lqx/store_icon_dance.png`;
export const YP_STORE_ACTIVITY_BG = `${prefix}lqx/yp_store_activity_bg.png`;
export const YP_STORE_ACTIVITY_END = `${prefix}lqx/yp_store_activity_end.png`;
export const APPOINT_ACTIVITY_BUTTON = `${newPrefix}lqx/appoint_activity_button.png`;
export const APPOINT_ACTIVITY_BUTTON_DISABLED = `${newPrefix}lqx/appoint_activity_button_disabled.png`;

export const APPOINT_ACTIVITY_BOX_BG1 = `${newPrefix}lqx/appoint_activity_box_bg1.png`;
export const APPOINT_ACTIVITY_BOX_BG2 = `${newPrefix}lqx/appoint_activity_box_bg2.png`;
export const APPOINT_ACTIVITY_BORDER = `${newPrefix}lqx/appoint_activity_border.png`;
export const YP_STORE_SERVICE_ICON = `${newPrefix}lqx/yp_store_service_icon.png`;
export const YP_STORE_ICON_BACK = `${newPrefix}lqx/icon_back.png`;
/** 鱼泡商城-end */
export const ICON_NOTICE = `${prefix}lqx/icon_notice.png`;

/** 鱼泡安全中心 */
export const SAFE_AUTH = `${prefix}lt/safe_auth.png`;
export const SAFE_NOAUTH = `${prefix}lt/safe_noauth.png`;
export const SAFE_FILE_ICON = `${prefix}lt/safe_file_icon.png`;
export const SAFE_USER_ICON = `${prefix}lt/safe_user_icon.png`;
export const SAFE_ALERT_ICON = `${newPrefix}lt/icon_hyzx_jbzx.png`;

export const SAFE_SHIELD = `${prefix}lt/safe_shield.png`;
export const SAFE_TITLE = `${prefix}lt/safe_title.png`;
export const SAFE_BANNER1 = `${prefix}lt/safe_banner1.png`;
export const SAFE_BANNER2 = `${prefix}lt/safe_banner2.png`;
export const SAFE_BUBBLE = `${prefix}lt/safe_bubble.png`;
export const SAFE_IMG1 = `${prefix}lt/safe_img1.png`;
export const SAFE_IMG2 = `${prefix}lt/safe_img2.png`;
export const SAFE_IMG3 = `${prefix}lt/safe_img3.png`;
export const SAFE_ANTI_FRAUD = `${prefix}lt/safe_anti_fraud.png`;
export const ARROW_RIGHT_BLUE = `${newPrefix}lt/icon_arrow_r.png`;
export const SAFE_NUMBER_VIDEO = 'https://cdn.yupao.com/resources/sd/v/1228vm_without_cover.mp4';
// export const INSURANCE_VIDEO = `${newPrefix}lt/how_insurance.mp4`;
export const INSURANCE_VIDEO = 'https://cdn.yupao.com/newyupao/video/wgbczzn.mp4';

/** 新手使用指南 */
export const USER_GUIDE_GR = `${prefix}lt/user-guide-gr.png`;
export const USER_GUIDE_GR_IMG1 = `${prefix}lt/user-guide-gr-img1.png`;
export const USER_GUIDE_GR_IMG2 = `${prefix}lt/user-guide-gr-img2.png`;
export const USER_GUIDE_GR_IMG3 = `${prefix}lt/user-guide-gr-img3.png`;
export const USER_GUIDE_GR_IMG4 = `${prefix}lt/user-guide-gr-img4.png`;
export const USER_GUIDE_GR_IMG5 = `${prefix}lt/user-guide-gr-img5.png`;
export const USER_GUIDE_GR_IMG6 = `${prefix}lt/user-guide-gr-img6.png`;

export const USER_GUIDE_LB = `${prefix}lt/user-guide-lb.png`;
export const USER_GUIDE_LB_IMG1 = `${prefix}lt/user-guide-lb-img1.png`;
export const USER_GUIDE_LB_IMG2 = `${prefix}lt/user-guide-lb-img2.png`;
export const USER_GUIDE_LB_IMG3 = `${prefix}lt/user-guide-lb-img3.png`;
export const USER_GUIDE_LB_IMG4 = `${prefix}lt/user-guide-lb-img4.png`;
export const USER_GUIDE_LB_IMG5 = `${prefix}lt/user-guide-lb-img5.png`;

export const USER_GUIDE_LB_VIDEO = `https://staticscdn.zgzpsjz.com/newyupao/video/recurit-v1.mp4`;
export const USER_GUIDE_GR_VIDEO = `${newPrefix}lt/safe_guide1.mp4`;

export const ICON_VIDEO = `${newPrefix}lt/icon_video_bf.png`;

/** 去实名弹框-点击标签 */
export const TO_AUTHORIZATION_DIALOG = {
	header: `${prefix}lt/image_smrz_pop_t_1.png`,
	icons: [
		`${prefix}lt/icon_pop_smrz_1_1.png`,
		`${prefix}lt/icon_pop_smrz_1_2.png`,
		`${prefix}lt/icon_pop_smrz_1_3.png`,
		`${prefix}lt/icon_pop_smrz_1_4.png`
	]
};

/** 防骗指南tips-icon */
export const FRAUD_PREVENTION_GUIDE_TIPS_ICON = `${prefix}pmy/fraud_prevention_guide_tips_icon.png`;
/** 防骗指南kefu-icon */
export const FRAUD_PREVENTION_GUIDE_KEFU_ICON = `${prefix}pmy/fraud_prevention_guide_kefu_icon.png`;
/** 防骗指南--工友找活怕被骗 */
export const FRAUD_WORKER_VIDEO1 = `${newPrefix}lt/new_worker_swindled.mp4`;
/** 防骗指南--工友找活怕收不到钱 */
export const FRAUD_WORKER_VIDEO2 = `${newPrefix}lt/worker_no_money.mp4`;
/** 防骗指南--老板招工想要找工人 */
export const FRAUD_BOSS_VIDEO1 = `${newPrefix}lt/boss_find_worker.mp4`;
/** 防骗指南--老板招工优质工人 */
export const FRAUD_BOSS_VIDEO2 = `${newPrefix}lt/boss_good_worker.mp4`;
/** 防骗指南--老板联系的不是一个人 */
export const FRAUD_BOSS_VIDEO3 = `${newPrefix}lt/boss_contact_worker.mp4`;
/** 防骗指南--老板车费怕被骗 */
export const FRAUD_BOSS_VIDEO4 = `${newPrefix}lt/boss_cheat_fare.mp4`;

export const FRAUD_WORKER_POSTER1 = `https://static102.cdqlkj.cn/r/c9f8/102/pb/p/20221020/15829860127689196050000.png`;
export const FRAUD_WORKER_POSTER2 = `${prefix}pmy/fraud_prevention_guide_findJob_1.jpg`;
export const FRAUD_WORKER_POSTER3 = `https://static102.cdqlkj.cn/r/b9f5/102/pb/p/20221020/15829881408748831630000.png`;
export const FRAUD_WORKER_POSTER4 = `${prefix}pmy/fraud_prevention_guide_findJob_2.jpg`;

export const FRAUD_BOSS_POSTER1 = `${newPrefix}zh/frame bg3.png`;
export const FRAUD_BOSS_POSTER2 = `${prefix}pmy/fraud_prevention_guide_findWorker_1.jpg`;
export const FRAUD_BOSS_POSTER3 = `${newPrefix}zh/frame bg2.png`;
export const FRAUD_BOSS_POSTER4 = `${prefix}pmy/fraud_prevention_guide_findWorker_2.jpg`;
export const FRAUD_BOSS_POSTER5 = `https://static102.cdqlkj.cn/r/f46f/102/pb/p/20221020/15829900236937544570000.png`;
export const FRAUD_BOSS_POSTER6 = `${newPrefix}zh/frame bg1.png`;
export const FRAUD_BOSS_POSTER7 = `${prefix}pmy/fraud_prevention_guide_findWorker_4.jpg`;
export const FRAUD_BOSS_POSTER8 = `${prefix}pmy/fraud_prevention_guide_findWorker_3.jpg`;

/** 看视频 */
export const LOOK_VIDEO = `${newPrefix}why/look_video.gif`;
/** 弹窗title */
export const TITLE = `${newPrefix}why/title.png`;
/** 积分图标 */
export const INTEGRAL = `${newPrefix}why/integral .png`;
/** 积分领取弹窗线 */
export const INTEGRAL_LINE = `${newPrefix}why/integra_line.png`;
/** 添加 */
export const ADD = `${newPrefix}why/add.png`;
/** 闪闪积分 */
export const FLASH_INTEGRAL = `${newPrefix}why/img_pop_jifen_jinbi.png`;
/** 助力背景 */
export const HELP_PAGE_BG = `${newPrefix}why/help_bg.png`;
/** 横幅 */
export const BANNER = `${newPrefix}why/img_zly_title.png`;
/** 底部按钮 */
export const FOOTER_BTN = `${newPrefix}why/footer_btn.png`;

/** 底部线 */
export const BOTTOM_LINE = `${newPrefix}why/img_bottom_bg.png`;
/** 不闪的积分 */
export const NOT_FLASH_INTEGRAL = `${newPrefix}why/not_flag_integralbg.png`;
/** 看视频积分 背景 */
export const VIDEO_IMG_BG = `${newPrefix}why/video_img_bg.png`;
/** 规则 */
export const RULE = `${newPrefix}why/icon_ksp_gz.png`;
/** 邀请工友送现金 */
export const INVITE_GIVE_CASH_IMG1 = `${newPrefix}pmy/invite_give_cash_img1.png`;
export const INVITE_GIVE_CASH_IMG2 = `${newPrefix}pmy/invite_give_cash_img2.png`;
export const INVITE_GIVE_CASH_IMG3 = `${newPrefix}pmy/invite_give_cash_img3.png`;
export const INVITE_GIVE_CASH_IMG3_1 = `${newPrefix}pmy/invite_give_cash_img3-1.png`;
export const INVITE_GIVE_CASH_IMG4 = `${newPrefix}pmy/invite_give_cash_img4.png`;
export const INVITE_GIVE_CASH_IMG5 = `${newPrefix}pmy/invite_give_cash_img5.png`;
export const INVITE_GIVE_CASH_IMG6 = `${newPrefix}pmy/invite_give_cash_img6.png`;
export const INVITE_GIVE_CASH_IMG7 = `${newPrefix}pmy/invite_give_cash_img7.png`;
export const INVITE_GIVE_CASH_IMG8 = `${newPrefix}pmy/invite_give_cash_img8-1.png`;
export const INVITE_GIVE_CASH_IMG9 = `${newPrefix}pmy/invite_give_cash_img9.png`;
export const INVITE_GIVE_CASH_GUIDE = `${newPrefix}pmy/invite_give_cash_guide.png`;
export const INVITE_GIVE_CASH_WITHDRAWAL = `${newPrefix}pmy/invite_give_cash_withdrawal.png`;
export const INVITE_GIVE_CASH_RANK_1 = `${newPrefix}pmy/invite_give_cash_rank_1.png`;
export const INVITE_GIVE_CASH_RANK_2 = `${newPrefix}pmy/invite_give_cash_rank_2.png`;
export const INVITE_GIVE_CASH_RANK_3 = `${newPrefix}pmy/invite_give_cash_rank_3.png`;
export const INVITE_GIVE_CASH_SHARE_IMG = `${newPrefix}pmy/11.png`;
/** 无数据背景图 */
export const NULL_GB = `${newPrefix}why/null_bg.png`;

/** 小程序分享引导图 */
export const MINI_SHARE_GUIDE = `${newPrefix}why/share_mini_guide.png`;

/** 视频引导横幅 */
export const GUIDE_BANNER = `${newPrefix}why/img_ksp_st.png`;
/** 视频引导底部文案 */
export const GUIDE_FOOTER = `${newPrefix}why/footer_text.png`;
/** 视频引导确定 */
export const GUIDE_CONFIRM = `${newPrefix}why/img_card_ks.png`;

/** 元宵节 */

/** 微信logo */
export const WX_LOGO = `${newPrefix}lt/wx-logo.png`;
/** 邀请好友一起参加 */
export const SHARE_TIP = `${newPrefix}lt/festival-tip.png`;
/** 邀请icon */
export const SHARE_ICON = `${newPrefix}lt/share-icon.png`;
/** 倒计时 1 */
export const COUNT_DOWN1 = `${newPrefix}lt/count-down1.png`;
/** 倒计时 2 */
export const COUNT_DOWN2 = `${newPrefix}lt/count-down2.png`;
/** 倒计时 3 */
export const COUNT_DOWN3 = `${newPrefix}lt/count-down3.png`;
/** 开始 */
export const COUNT_END = `${newPrefix}lt/count-end.png`;
/** 恭喜你答对了 */
export const CONGRATUATION_RIGHT = `${newPrefix}lt/congratulation-right.png`;
/** 邀请好友参与 */
export const INVITE_FRIENDS = `${newPrefix}lt/invite-friends.png`;
/** 帮他助力 */
export const HELP_FRIENDS = `${newPrefix}lt/help-friends.png`;
/** 我也要参加 */
export const OTHER_WANT_JOIN = `${newPrefix}lt/other-want-join.png`;
/** 我要参加 */
export const WANT_JOIN = `${newPrefix}lt/want-join.png`;
/** 重新答题 */
export const ANSWER_AGAIN = `${newPrefix}lt/answer-again.png`;
/** 再玩一次 */
export const PLAY_AGAIN = `${newPrefix}lt/play-again1.png`;
/** 邀请好友完成助力 */
export const INVITE_FRIENDS_HELP = `${newPrefix}lt/invite-friends-help.png`;
/** 好友助力成功 */
export const FRIENDS_HELP_SUCCEED = `${newPrefix}lt/friends-help-succeed.png`;
/** 升级版本 */
export const UPDATE_VERSION = `${newPrefix}lt/upgrade-version.png`;
/** 礼盒 */
export const GIFT_BOX = `${newPrefix}lt/gift-box.png`;
/** 失败表情 */
export const EMOTION_CRY = `${newPrefix}lt/emoticon-cry.png`;
/** 成功表情 */
export const EMOTION_SMILE = `${newPrefix}lt/emoticon-smile.png`;
/** 恭喜你 背景 */
export const CONGRATUATE_BG = `${newPrefix}lt/congratulate-bg.png`;
/** 主题 背景 */
export const LANTERN_BG = `${newPrefix}lt/lantern-bg.png`;
/** 离开 背景 */
export const LEAVE_BG = `${newPrefix}lt/leave-bg.png`;
/** 离开 标题 */
export const LEAVE_TITLE = `${newPrefix}lt/active-leave.png`;
/** 背景图 宫殿 */
export const BACKGROUND_BG3 = `${newPrefix}lt/background-bg3.png`;
/** 背景图 宫殿 */
export const BACKGROUND_BG1 = `${newPrefix}lt/background-bg1.png`;
/** 背景图 烟花 */
export const BACKGROUND_BG2 = `${newPrefix}lt/background-bg2.png`;
/** 锦旗 */
export const SILK_BNNER = `${newPrefix}lt/silk-banner.png`;
/** 活动规则标题 */
export const RULES_TITLE = `${newPrefix}lt/active-rules-title.png`;
/** 回答正确 */
export const ANSWER_RIGHT = `${newPrefix}lt/answer-right.png`;
/** 回答错误 */
export const ANSWER_ERROR = `${newPrefix}lt/answer-error.png`;
/** 默认 logo */
export const DEFAULT_LOGO = `${newPrefix}lt/default-logo.png`;
/** 邀请 图标 */
export const INVITE_ADD = `${newPrefix}lt/invite-add.png`;
/** 规则 箭头 图标 */
export const RULES_ARROW = `${newPrefix}lt/rules-arrow.png`;
/** 分享落地页 */
export const LANTERN_FESTIVAL_SHREED = `${newPrefix}lt/lantern-festival-shared.png`;
/** 去使用券 */
export const USE_TICKET = `${newPrefix}lt/use-ticket.png`;
/** 鱼泡VIP */
export const VIP_TYPE_RIGHT = `${newPrefix}zh/vip_type_right.png`;
export const VIP_TYPE_LEFT = `${newPrefix}zh/vip_type_left.png`;
export const VIP_TYPE_MID = `${newPrefix}zh/vip_type_mid.png`;
export const VIP_TITLE = `${newPrefix}lt/img_sqtc_tittle.png`;
export const VIP_TAG_BG = `${newPrefix}zh/vip_tag_bg.png`;
export const VIP_START = `${newPrefix}zh/vip_start_tag.png`;
export const VIP_SIGN = `${newPrefix}zh/vip_sign.png`;
export const VIP_OPEN_BG = `${newPrefix}zh/vip_open_bg.png`;
export const VIP_INFO_TAG = `${newPrefix}zh/vip_info_tag.png`;
export const VIP_EMPTY_BG = `${newPrefix}zh/vip_empty_bg.png`;
export const VIP_DIALOG_BG = `${newPrefix}zh/vip_dialog_bg.png`;
export const VIP_INTEGRAL = `${newPrefix}zh/vip_integral.png`;
export const VIP_DES_BG = `${newPrefix}zh/vip_des_bg.png`;
export const VIP_BG = `${newPrefix}zh/vip_bg.png`;
export const ICON_VIP_UPGRADE = `${newPrefix}zh/icon_vip_upgrade.png`;
export const VIP_MBX = `${newPrefix}zh/vip_mbx.png`;
export const VIP_LIST_BG = `${newPrefix}lt/vip_list_bg.png`;
export const VIP_KTHY = `${newPrefix}zh/vip_kthy.png`;
export const VIP_JILU = `${newPrefix}zh/vip_jilu.png`;
export const VIP_JILU1 = `${newPrefix}zh/vip_jilu1.png`;
export const VIP_JILU2 = `${newPrefix}zh/vip_jilu2.png`;
export const VIP_PAY_SELECT = `${newPrefix}lt/vip_pay_select2.png`;
export const VIP_NO_SELECT = `${newPrefix}zh/vip_no_select.png`;
export const VIP_HUIYUAN = `${newPrefix}zh/vip_huiyuan.png`;
export const VIP_CHAKAN = `${newPrefix}zh/vip_chakan.png`;
export const VIP_SERVICE = `${newPrefix}zh/vip_service.png`;
export const VIP_DIALOG_CLOSE = `${newPrefix}zh/vip_dialog_close.png`;
export const VIP_TBB_BORDER2 = `${newPrefix}lt/border2.png`;
export const VIP_TBB_BORDER1 = `${newPrefix}lt/border1.png`;
export const VIP_ICON_CHANG = `${newPrefix}zh/icon_chang.png`;
export const VIP_ICON_SHU = `${newPrefix}zh/icon_shu.png`;
/** vip 会员tab */
export const VIP_TAB_ICON1 = `${newPrefix}lt/icon_vip_grade1.png`;
export const VIP_TAB_ICON11 = `${newPrefix}lt/icon_vip_grade11.png`;
export const VIP_TAB_ICON2 = `${newPrefix}lt/icon_vip_grade2.png`;
export const VIP_TAB_ICON22 = `${newPrefix}lt/icon_vip_grade22.png`;
export const VIP_TAB_ICON3 = `${newPrefix}lt/icon_vip_grade3.png`;
export const VIP_TAB_ICON33 = `${newPrefix}lt/icon_vip_grade33.png`;
export const VIP_TAB_ICON_ACTIVE = `${newPrefix}lt/icon_vipjilu.png`;

/** 咨询分享 */
export const SHARE_BTN = `${newPrefix}zh/yp_web_share.png`;

/** 咨询分享 */
export const MEMBER_VIP_BG1 = `${newPrefix}zh/img_haohua_new.png`;
export const MEMBER_VIP_BG2 = `${newPrefix}zh/img_baiyin_new.png`;
export const MEMBER_VIP_BG3 = `${newPrefix}zh/img_zhizun_new.png`;
export const MEMBER_VIP_BG4 = `${newPrefix}zh/img_jyb_new.png`;
export const MEMBER_VIP_BTN_ICON = `${newPrefix}lt/icon_vip_btn.png`;
export const MEMBER_VIP_SQTC_BG = `${newPrefix}lt/img_bg_sqtc1.png`;
export const MEMBER_VIP_SQTC_XIA = `${newPrefix}zh/vip_bg_sqtc3.png`;
export const MEMBER_VIP_SHOW_ALL = `${newPrefix}zh/vip-record-down.png`;
export const MEMBER_VIP_START_EQU = `${newPrefix}zh/vip_start_equ.png`;
export const MEMBER_VIP_GYYP = `${newPrefix}zh/icon_hyzx_gyyp1.png`;
export const MEMBER_VIP_UP = `${newPrefix}zh/vip-record-up.png`;

/** 货运司机加群 */
export const CLOSE_ICON = `${newPrefix}lt/close-icon.png`;
/** 广告投放  */
export const AD_LAUBCH1 = `${newPrefix}lt/ad-launch1.png`;
export const AD_LAUBCH2 = `${newPrefix}lt/ad-launch2.png`;
export const AD_LAUBCH_BG1 = `${newPrefix}lt/ad-item-bg1.png`;
export const AD_LAUBCH_BG2 = `${newPrefix}lt/ad-item-bg2.png`;
export const AD_LAUBCH_TAB1 = `${newPrefix}lt/title_tab1.png`;
export const AD_LAUBCH_TAB2 = `${newPrefix}lt/title_tab2.png`;
export const AD_LAUBCH_CHECKED = `${newPrefix}lt/checked.png`;
export const AD_LAUBCH_NO_CHECKED = `${newPrefix}lt/no-check.png`;

/** 会员升级 */
export const VIP_UPDATE = `${newPrefix}lt/vip-update.png`;
export const VIP_UPDATE1 = `${newPrefix}lt/vip-update-2.png`;
export const VIP_UPDATE2 = `${newPrefix}lt/vip-update-1.png`;
export const VIP_UPDATE3 = `${newPrefix}lt/vip-update-3.png`;
export const VIP_UPDATE4 = `${newPrefix}lt/vip-update-4.png`;

export const CIKA_ICON_1 = `${newPrefix}lt/cika-icon-1.png`;
export const CIKA_ICON_2 = `${newPrefix}lt/cika-icon-2.png`;
export const CIKA_PLAY = `${newPrefix}lt/cika-play.png`;
export const VIP_ICON_1 = `${newPrefix}lt/vip-icon-1.png`;
export const VIP_ICON_2 = `${newPrefix}lt/vip-icon-2.png`;
export const VIP_PLAY = `${newPrefix}lt/vip-play.png`;
export const VIP_UPDATE_DIAMOND = `${newPrefix}lt/vip-update-diamond.png`;
export const VIP_PRAISE = `${newPrefix}lt/vip-praise.png`;
export const VIP_LIGHT = `${newPrefix}lt/vip-light.png`;
export const VIP_MP4 = `${newPrefix}zh/vip-video.mp4`;
export const VIP_ACTIVE_BG = `${newPrefix}lt/vip-active-bg1.png`;
export const CIKA_ACTIVE_BG = `${newPrefix}lt/cika-active-bg1.png`;
export const VIP_LIST_ARROW = `${newPrefix}zh/list-arrow1.png`;
export const VIP_PAY_SELECT1 = `${newPrefix}lt/vip_pay_select1.png`;
/** vip 挽留弹窗 */
export const VIP_DIALOG_POP_BG = `${newPrefix}zh/vip-dialog-bg1.png`;
/** 鱼泡首充 */
export const YP_FIRST_RECHARGE = `${newPrefix}lt/yp-first-recharge-main.png`;
export const YP_FIRST_RECHARGE2 = `${newPrefix}lt/yp-first-recharge7.png`;

/** 商城退货 */
export const YP_STORE_RETURN_CALL = `${newPrefix}lt/return_call.png`;

/** 上传失败 预览占位 */
export const PREVIEW_FAIL = `${newPrefix}lt/preview-fail.png`;

/** 餐饮加群 */
export const CATERING_GROUP = `${newPrefix}lt/catering-group1.png`;

/** 头部返回按钮 */
export const HEADER_BACK = `${newPrefix}lt/header_back.png`;

export const CONSUME_RECORDS = `${newPrefix}zh/order-center1.png`;
export const ORDER_CENTER = `${newPrefix}zh/order-center2.png`;
export const VIP_ORDER = `${newPrefix}zh/order-center3.png`;
export const VIP_RECORD_TAG = `${newPrefix}zh/vip_record_tag.png`;
export const VIP_RECORD_TAG1 = `${newPrefix}zh/vip_record_deftag.png`;
export const MENU_RIGHT = `${newPrefix}zh/menu_right.png`;

export const RECOMMEND_ONCE_BG = `${newPrefix}zh/vip-recomend1.png`;
export const RECOMMEND_ONCE_BG1 = `${newPrefix}zh/vip-recomend3.png`;
export const RECOMMEND_MEMBER_BG = `${newPrefix}zh/vip-recomend4.png`;
export const RECOMMEND_MEMBER_BG1 = `${newPrefix}zh/vip-recomend2.png`;

/* ANCHOR 群发炸弹 */
export const BACK_ICON = `${newPrefix}pp/back-white.png`;
export const PURCHASE_RECORDS = `${newPrefix}pp/purchase-records.png`;
export const BOARDCAST = `${newPrefix}pp/boardcast.png`;
export const BOMB = `${newPrefix}pp/bomb.png`;
export const RECOMMEND_ICON = `${newPrefix}pp/recommend.png`;
export const CHAT_MESSAGE = `${newPrefix}pp/chat-msg.png`;
export const ARROW_RIGHT = `${newPrefix}pp/arrow-right.png`;
export const WORKER = `${newPrefix}pp/worker.png`;
export const AVATAR_BOMB = `${newPrefix}pp/avatar-icon.png`;
export const ARROW_RIGHT_WHITE = `${newPrefix}pp/arrow-right-white.png`;
export const CLOSE_ICON_WHITE = `${newPrefix}pp/close-icon-w.png`;
export const BOMBS_BRIEF_1 = `${newPrefix}pp/bombs-brief-1.png`;
export const BOMBS_BRIEF_2 = `${newPrefix}pp/bombs-brief-2.png`;
export const BOMBS_BRIEF_3 = `${newPrefix}pp/bombs-brief-3.png`;
export const BOMBS_BRIEF_4 = `${newPrefix}pp/bombs-brief-4.png`;
export const BOMBS_EMPTY = `${newPrefix}pp/bombs-empty.png`;
export const BOMBS_UNSELECT = `${newPrefix}pp/bombs-unselectable.png`;
export const BOMBS_PRECISE = `${newPrefix}pp/precise.png`;
export const BOMBS_EFFICIENCY = `${newPrefix}pp/efficiency.png`;
export const BOMBS_RETAIN_BOMB = `${newPrefix}pp/bombs-retain.png`;
export const BOMBS_RETAIN_DIALOG = `${newPrefix}pp/retain-dialog.png`;
export const BOMBS_EMPTY_RECORDS = `${newPrefix}pp/bombs-empty-records.png`;
export const BOMBS_CIRCLE_LANDING = `${newPrefix}pp/bombs-circle.png`;
export const BOMBS_BOMBS = `${newPrefix}pp/bombs-bomb-icon.png`;
export const BOMBS_PURCHASE_ARROW_RIGHT = `${newPrefix}pp/arrow-right-record-card.png`;

export const BOMBS_CIRCLE_BIG_LANDING = `${newPrefix}pp/bombs-big-circle.png`;
export const BOMBS_CIRCLE_BIG_ICON = `${newPrefix}pp/bombs-bomb-big-icon.png`;

export const TIME_ICON = `${newPrefix}pp/time-icon.png`;
export const BG_TIME = `${newPrefix}pp/bg-time.png`;
export const BG_BOMBS = `${newPrefix}pp/bg-bombs.png`;
export const BG_BOMBSING = `${newPrefix}pp/bg-bmbsing.png`;

export const BUY_PROP = `${newPrefix}zh/buy_prop1.png`;
export const VIP_POP_CLOSE = `${newPrefix}zh/vip_pop_close.png`;

export const VIP_COUPON = `${newPrefix}zh/vip_coupon.png`;
export const VIP_COUPON_POP_BG = `${newPrefix}zh/vip_coupon1.png`;
export const VIP_MENU_RIGHT = `${newPrefix}zh/vip_menu_right1.png`;
export const VOUCHER_CHECKED = `${newPrefix}zh/voucher_checked.png`;

/** 新春活动 */
export const NEW_YEAR_LOADING = `${newPrefix}lt/new_year_loading.png`;
export const NEW_YEAR_RETURN = `${newPrefix}lt/new_year_return.png`;
export const NEW_YEAR_VIDEO = `${newPrefix}lt/new_year_gif.gif`;
export const NEW_YEAR_HAS_PRIZE = `${newPrefix}lt/new_year_has_prize.png`;
export const NEW_YEAR_TITLE = `${newPrefix}lt/new_year_title.png`;
export const NEW_YEAR_PENDANT1 = `${newPrefix}lt/new_year_pendant1.png`;
export const NEW_YEAR_PENDANT2 = `${newPrefix}lt/new_year_pendant2.png`;
export const NEW_YEAR_FRINED_ICON = `${newPrefix}lt/new_year_friend_icon.png`;

/** 福卡部分小图标 */
export const NEW_YEAR_IMG1 = `${newPrefix}lt/new_year_img1.png`;
export const NEW_YEAR_IMG2 = `${newPrefix}lt/new_year_img2.png`;
export const NEW_YEAR_IMG3 = `${newPrefix}lt/new_year_fuka_bg.png`;
export const NEW_YEAR_IMG4 = `${newPrefix}lt/new_year_img4.png`;

/** 福卡 */
export const NEW_YEAR_FUKA_ZHAO = `${newPrefix}lt/new_year_fuka_zhao.png`;
export const NEW_YEAR_FUKA_ZHAO1 = `${newPrefix}lt/new_year_fuka_zhao1.png`;
export const NEW_YEAR_FUKA_GONG = `${newPrefix}lt/new_year_fuka_gong.png`;
export const NEW_YEAR_FUKA_GONG1 = `${newPrefix}lt/new_year_fuka_gong1.png`;
export const NEW_YEAR_FUKA_ZUO = `${newPrefix}lt/new_year_fuka_zuo.png`;
export const NEW_YEAR_FUKA_ZUO1 = `${newPrefix}lt/new_year_fuka_zuo1.png`;
export const NEW_YEAR_FUKA_SHANG = `${newPrefix}lt/new_year_fuka_shang.png`;
export const NEW_YEAR_FUKA_SHANG1 = `${newPrefix}lt/new_year_fuka_shang1.png`;
export const NEW_YEAR_FUKA_YU = `${newPrefix}lt/new_year_fuka_yu.png`;
export const NEW_YEAR_FUKA_YU1 = `${newPrefix}lt/new_year_fuka_yu1.png`;
export const NEW_YEAR_FUKA_PAO = `${newPrefix}lt/new_year_fuka_pao.png`;
export const NEW_YEAR_FUKA_PAO1 = `${newPrefix}lt/new_year_fuka_pao1.png`;
export const NEW_YEAR_FUKA_TRIANGLE = `${newPrefix}lt/new_year_triangle.png`;

/** 按钮 */
export const NEW_YEAR_FUKA_EXTRACT = `${newPrefix}lt/new_year_fuka_extract.png`;
export const NEW_YEAR_FUKA_EXTRACT_DOWN = `${newPrefix}lt/new_year_fuka_extract_down.png`;

/** 任务 */
export const NEW_YEAR_FUKA_LIMIT_TITLE = `${newPrefix}lt/new_year_limit_title.png`;
export const NEW_YEAR_FUKA_EVERYDAY_TITLE = `${newPrefix}lt/new_year_fuka_everyday_title.png`;

export const NEW_YEAR_INVITE_BTN = `${newPrefix}lt/new_year_invite_btn.png`;
export const NEW_YEAR_END_BTN = `${newPrefix}lt/new_year_end_btn.png`;
export const NEW_YEAR_FINISH_BTN = `${newPrefix}lt/new_year_finish_btn.png`;

export const NEW_YEAR_SHARE = `${newPrefix}lt/new_year_share.png`;
export const NEW_YEAR_SIGN = `${newPrefix}lt/new_year_sign.png`;
export const NEW_YEAR_SUBSCRIBE = `${newPrefix}lt/new_year_subscribe.png`;
export const NEW_YEAR_VIP = `${newPrefix}lt/new_year_vip.png`;
export const NEW_YEAR_CREATE_RESUME = `${newPrefix}lt/new_year_create_resume.png`;
export const NEW_YEAR_COMPLETE_RESUME = `${newPrefix}lt/new_year_complete_resume.png`;
export const NEW_YEAR_CHARGE = `${newPrefix}lt/new_year_charge.png`;
export const NEW_YEAR_PUBLISH_HIRING = `${newPrefix}lt/new_year_publish_hiring.png`;
export const NEW_YEAR_VIDEO_ICON = `${newPrefix}lt/new_year_video_icon.png`;

/** 新春预热活动 */
export const NEW_YEAR_PREHEAT_TITLE = `${newPrefix}szh/new_year_preheat_title.png`;
export const NEW_YEAR_PREHEAT_COLON = `${newPrefix}szh/new_year_preheat_colon.png`;
export const NEW_YEAR_PREHEAT_COUNTDOWN = `${newPrefix}szh/new_year_preheat_countdown.png`;
export const NEW_YEAR_PREHEAT_PROGRESS = `${newPrefix}szh/new_year_preheat_progress.png`;
export const NEW_YEAR_PREHEAT_TOPIMAGE = `${newPrefix}szh/new_year_preheat_topImage.png`;
export const NEW_YEAR_PREHEAT_TRIANGLE = `${newPrefix}szh/new_year_preheat_triangle.png`;

/** 新春活动-集福攻略弹窗 */
export const NEW_YEAR_FORTUNE_STRATEGY_TITLE = `${newPrefix}szh/new_year_strategy_title.png`;
export const NEW_YEAR_FORTUNE_STRATEGY_CLOSE = `${newPrefix}szh/new_year_strategy_close.png`;
export const NEW_YEAR_FORTUNE_ACTIVITY_CLOSE = `${newPrefix}szh/new_year_activity_close.png`;

/** 新春活动-我的奖品弹窗 */
export const NEW_YEAR_MY_AUDIT_TRIANGLE = `${newPrefix}szh/new_year_my_audit_triangle.png`;
export const NEW_YEAR_MY_CLOSE = `${newPrefix}szh/new_year_my_close.png`;
export const NEW_YEAR_MY_PRIZE = `${newPrefix}szh/new_year_my_prize.png`;
export const NEW_YEAR_MY_RECEIVED = `${newPrefix}szh/new_year_my_received.png`;
export const NEW_YEAR_MY_REDENVELOPE = `${newPrefix}szh/new_year_my_redenvelope.png`;
export const NEW_YEAR_MY_EQUITY = `${newPrefix}szh/new_year_my_equity.png`;
export const NEW_YEAR_MY_INTEGRAL = `${newPrefix}szh/new_year_my_integral.png`;

/** 新春活动-我的奖品-抽奖记录 */
export const NEW_YEAR_LOTTERY_CARD = `${newPrefix}szh/new_year_lottery_card.png`;

/** 新春活动-奖品弹窗 */
export const NEW_YEAR_PRIZE_TITLE = `${newPrefix}szh/new_year_prize_title.png`;
export const NEW_YEAR_PRIZE_SHENGZHI = `${newPrefix}szh/new_year_prize_shenzhi.png`;
export const NEW_YEAR_PRIZE_SHENGZHI_TXT = `${newPrefix}szh/new_year_prize_shenzhi_txt.png`;
export const NEW_YEAR_PRIZE_HONGTU = `${newPrefix}szh/new_year_prize_hongtu.png`;
export const NEW_YEAR_PRIZE_DAJIDALI = `${newPrefix}szh/new_year_prize_dajidali.png`;
export const NEW_YEAR_PRIZE_GAOFENG = `${newPrefix}szh/new_year_prize_gaofeng.png`;
export const NEW_YEAR_PRIZE_FUQIMANMAN = `${newPrefix}szh/new_year_prize_fuqimanman.png`;
export const NEW_YEAR_PRIZE_GAOSHENG = `${newPrefix}szh/new_year_prize_gaosheng.png`;
export const NEW_YEAR_PRIZE_QINQINKENKEN = `${newPrefix}szh/new_year_prize_qinqinkenken.png`;

/** 新春活动-中奖名单 */
export const NEW_YEAR_WINNER_TITLE = `${newPrefix}szh/new_year_winner_title.png`;

/** 分享好友弹窗 */
export const NEW_YEAR_SHARE_POP_BG = `${newPrefix}lt/new_year_share_pop_bg.png`;
export const NEW_YEAR_SHARE_POP_FACE = `${newPrefix}lt/new_year_share_pop_face.png`;
export const NEW_YEAR_SHARE_POP_FRIEND = `${newPrefix}lt/new_year_share_pop_friend.png`;
export const NEW_YEAR_SHARE_POP_WECHAT = `${newPrefix}lt/new_year_share_pop_wechat.png`;
export const NEW_YEAR_SHARE_POP_TITLE = `${newPrefix}lt/new_year_share_pop_title.png`;

/** 面对面弹窗 */
export const NEW_YEAR_FACE_POP_CODE = `${newPrefix}lt/new_year_face_pop_code.png`;
export const NEW_YEAR_FACE_POP_TITLE = `${newPrefix}lt/new_year_face_pop_title.png`;
export const NEW_YEAR_FACE_POP_BG = `${newPrefix}lt/new_year_face_pop_bg.png`;

/** 分享弹窗背景 */
export const NEW_YEAR_VX_BACKGROUND = `${newPrefix}szh/new_year_vx_background1.png`;

/** 提醒弹窗 */
export const NEW_YEAR_REMAIN_BG = `${newPrefix}lt/new_year_remain_bg.png`;
export const NEW_YEAR_REMAIN_FU = `${newPrefix}szh/new_year_remain_fu.png`;
export const NEW_YEAR_REMAIN_KA = `${newPrefix}lt/new_year_remain_ka.png`;

/** 集福攻略 */
export const NEW_YEAR_STRATEGY_TXT = `${newPrefix}lt/new_year_strategy_text.png`;

/** 中奖记录-底部 */
export const NEW_YEAR_WINLIST_BOTTOM = `${newPrefix}szh/new_year_winlist_bottom.png`;

/** 活动结束,开奖按钮 */
export const NEW_YEAR_FUKA_DRAW_PRIZE = `${newPrefix}szh/new_year_fuka_draw_prize.png`;

/** 光圈 */
export const NEW_YEAR_APERTURE = `${newPrefix}lt/new_year_aperture.png`;
export const NEW_YEAR_APERTURE1 = `${newPrefix}lt/new_year_aperture1.png`;

export const NEW_YEAR_REMAIN_BTN_BG = `${newPrefix}lt/new_year_remain_btn_bg.png`;
export const VIP_DIAN_ZAN = `${newPrefix}fb/icon_dianzan.png`;
export const VIP_DEATILS = `${newPrefix}fb/vip_details.png`;

export const ICON_GOU = `${newPrefix}fb/icon_gou-1.png`;
export const ICON_GOU_VIP = `${newPrefix}fb/icon_gou.png`;
export const IVON_GOU_CIKA = `${newPrefix}fb/icon_gou1.png`;

export const ICON_VIP_NO_BUY = `${newPrefix}fb/icon_buy.png`;
export const ICON_VIP_BUY1 = `${newPrefix}fb/icon_buy1.png`;
export const ICON_VIP_BUY2 = `${newPrefix}fb/icon_buy2.png`;
export const ICON_VIP_DIAMOND = `${newPrefix}fb/diamond.png`;
export const IVON_VIP_TIE_QUAN = `${newPrefix}fb/VIP_ZHUANSHU_QUANYI.png`;
export const IVON_VIP_KEFU = `${newPrefix}fb/icon_kefu.png`;
export const ICON_PHONE = `${newPrefix}fb/icon_phone-2.png`;
export const ICON_XUNHUAN = `${newPrefix}fb/icon_xunhuan-1.png`;
export const ICON_ARROW = `${newPrefix}fb/icon_arrow_top.png`;
export const ICON_TAN_HAO = `${newPrefix}fb/icon_tanhao.png`;
export const ICON_ARROW_RIGHT = `${newPrefix}fb/icon_arrow_right.png`;
export const ICON_RECORD_RIGHT = `${newPrefix}fb/icon_record.png`;

export const ICON_AGREE_NO = `${newPrefix}fb/icon_gou-1.png`;
export const ICON_AGREE_VIP = `${newPrefix}fb/icon_gou.png`;
export const ICON_AGREE_CIKA = `${newPrefix}fb/icon_gou1.png`;
export const ICON_LOGIN_CLOSE = `${newPrefix}fb/login_close.png`;

export const ICON_LOGIN_GOU_ACTIVE = `${newPrefix}fb/login_gou_active.png`;
export const ICON_LOGIN_GOU = `${newPrefix}fb/login_gou.png`;
export const ICON_LOGIN_MA = `${newPrefix}fb/login_ma.png`;
export const ICON_LOGIN_PHONE = `${newPrefix}fb/login_phone.png`;
export const ICON_LOGIN_PLOGIN = `${newPrefix}fb/plogin.png`;
export const WEI_XIN_URL = `${newPrefix}fb/icon_weixin.png`;
export const IMAGES_URL = `${newPrefix}fb/icon_kefu.png`;

export const ICON_CIKA_NO_BUY = `${newPrefix}fb/icon_cika.png`;
export const ICON_CIKA_BUY1 = `${newPrefix}fb/icon_cika_buy3.png`;
export const ICON_CIKA_BUY2 = `${newPrefix}fb/icon_cika_buy1.png`;
export const ICON_CIKA_BUY3 = `${newPrefix}fb/icon_cika_buy.png`;

export const ICON_CIKA_ARROW_ICON = `${newPrefix}fb/arrow_icon.png`;
export const ICON_CIKA_MENU = `${newPrefix}fb/cika_icon_menu.png`;
export const ICON_CIKA_ARROW = `${newPrefix}fb/cika_icon.png`;
export const ICON_CIKA_FRAME = `${newPrefix}fb/frame_cika_icon.png`;
export const ICON_CIKA_BLUE = `${newPrefix}fb/icon_blue.png`;

export const ICON_CIKA_EXPIRS_BACK = `${newPrefix}fb/cika_icon_expirs_back.png`;
export const ICON_CIKA_EXPIRS = `${newPrefix}fb/cika_icon_expirs.png`;

export const B_IVON_BG = `${newPrefix}fb/b_icon_bg.png`;
export const B_ICON_RECORD_TITLE = `${newPrefix}fb/b_icon_record_title.png`;
export const B_ICON_RECORD = `${newPrefix}fb/b_icon_record.png`;

export const ICON_DEATE_VIP = `${newPrefix}fb/ICON_DEATE_VIP.png`;
export const ICON_VIP_TITLE = `${newPrefix}fb/ICON_VIP_TITLE.png`;

export const ICON_MY_PROPS_ARROW = `${newPrefix}fb/ICON_MY_PROPS_ARROW.png`;
export const ICON_MY_PROPS_PHONE = `${newPrefix}fb/ICON_MY_PROPS_PHONE.png`;
export const ICON_MY_PROPS_WEIXIN = `${newPrefix}fb/ICON_MY_PROPS_WEIXIN.png`;
export const ICON_MY_PROPS_XUN = `${newPrefix}fb/ICON_MY_PROPS_XUN.png`;

export const ICON_VIP_RIGHT_BOTTOM = `${newPrefix}fb/ICON_VIP_RIGHT_BOTTOM.png`;

export const ICON_RECORD_CIKA_BLUE = `${newPrefix}fb/ICON_RECORD_CIKA_BLUE.png`;
export const ICON_RECORD_CIKA_GREY = `${newPrefix}fb/ICON_RECORD_CIKA_GREY.png`;
export const ICON_RECORD_VIP_GREY = `${newPrefix}fb/ICON_RECORD_VIP_GREY.png`;
export const ICON_RECORD_VIP_YEIIOW = `${newPrefix}fb/ICON_RECORD_VIP_YEIIOW.png`;
export const ICON_RECORD = `${newPrefix}fb/ICON_RECORD.png`;
export const WANGLIUTAN_ICIN = `${newPrefix}fb/WANGLIUTAN_ICIN.png`;
export const ICON_KONG_RECORD = `${newPrefix}fb/ICON_KONG_RECORD.png`;

export const DA_E_CHONG_ZHI_MINI = `${newPrefix}fb/DA_E_CHONG_ZHI_MINI.png`;
export const DAE_CHONG_ZHI = `${newPrefix}fb/DAE_CHONG_ZHI.png`;
export const DAE_CHONGZHI_ANNIU = `${newPrefix}fb/DAE_CHONGZHI_ANNIU.png`;

export const BODA_ACTIVECIKA_ICON_DAOJU = `${newPrefix}fb/BODA_ACTIVECIKA_ICON_DAOJU.png`;
export const BODA_CIKA_ICON_DAOJU = `${newPrefix}fb/BODA_CIKA_ICON_DAOJU-1.png`;
export const CHANGLIAO_ACTIVECIKA_ICON_DAOJU = `${newPrefix}fb/CHANGLIAO_ACTIVECIKA_ICON_DAOJU.png`;
export const CHANGLIAO_CIKA_ICON_DAOJU = `${newPrefix}fb/CHANGLIAO_CIKA_ICON_DAOJU-1.png`;
export const PROPS_CIKA_ICON_DAOJU = `${newPrefix}fb/PROPS_CIKA_ICON_DAOJU.png`;
export const SHUAXIN_ACTIVE_CIKA_ICON_DAOJU = `${newPrefix}fb/SHUAXIN_ACTIVE_CIKA_ICON_DAOJU.png`;
export const SHUAXIN_CIKA_ICON_DAOJU = `${newPrefix}fb/SHUAXIN_CIKA_ICON_DAOJU-1.png`;
export const VIP_BG_BIAOSHI = `${newPrefix}fb/VIP_BG_BIAOSHI.png`;
export const ICON_WEIGOUXUAN = `${newPrefix}fb/ICON_WEIGOUXUAN.png`;

export const CODE_LOGIN_ICON = `${newPrefix}fb/CODE_LOGIN_ICON.png`;
export const DELETE_LOGIN_ICON = `${newPrefix}fb/DELETE_LOGIN_ICON.png`;
export const PHONRE_LOGIN_ICON = `${newPrefix}fb/PHONRE_LOGIN_ICON.png`;
export const QUANYI_RECORD = `${newPrefix}fb/QUANYI_RECORD.png`;
export const BUY_ICON_PAY = `${newPrefix}fb/BUY_ICON_PAY.png`;
export const YUPAO_FUKA_PG = `${newPrefix}fb/YUPAO_FUKA_PG.png`;
export const XIN_NIAN_HUO = `${newPrefix}fb/XIN_NIAN_HUO.png`;

export const ICON_ANNIU = `${newPrefix}fb/ICON_ANNIU.png`;
export const ICON_SHOUYE = `${newPrefix}fb/ICON_SHOUYE.png`;
export const ICON_XIAO_CHENGXU = `${newPrefix}fb/ICON_XIAO_CHENGXU.png`;
export const VIP_BG_QUANYI = `${newPrefix}fb/vip_bg_quanyi.png`;

export const VIP_TAG = `${newPrefix}zh/vip_tag1.png`;
export const CONFIRM_CHECK1 = `${newPrefix}zh/confirm_check1.png`;
export const CONFIRM_CHECK2 = `${newPrefix}zh/confirm_check2.png`;
export const CONFIRM_CHECK3 = `${newPrefix}zh/confirm_check3.png`;
export const EQUITY_BG = `${newPrefix}zh/equity_bg.png`;

/* 保险 */
export const INSURE_BG = `${H5_CDN}/insure_bg.png`;
export const SHARE_BACK = `${newPrefix}zch/share_back.png`;
export const SHARE_WX_OUT = `${newPrefix}zch/share_wx.png`;
export const BNSURE_LIST_ON = `${newPrefix}zch/bnsure_list_on.png`;
export const BNSURE_LIST_OFF = `${newPrefix}zch/bnsure_list_off.png`;
export const BNSURE_ORDER_ON = `${newPrefix}zch/bnsure_order_on.png`;
export const BNSURE_ORDER_OFF = `${newPrefix}zch/bnsure_order_off.png`;
export const BNSURE_ZYBXJC = `${newPrefix}gyf/zybxjc.mp4`;

/** 营销活动v1.2.0---商城送商品活动、促销、【短袖】 */
// 首页相关
export const SUM_AVT_BANNER_BG = `${newPrefix}sjc/sum_avt_banner_bg_001_01.png`;
export const SUM_AVT_BANNER_BTN_GIFT = `${newPrefix}sjc/sum-avt-banner-btn-gift.png`;
export const SUM_AVT_BANNER_BTN_INVIT = `${newPrefix}sjc/sum-avt-banner-btn-invit_1.png`;
export const SUM_AVT_BANNER_BTN_INVIT_LIST = `${newPrefix}sjc/sum-avt-banner-btn-invit-list.png`;
export const SUM_AVT_BANNER_BTN_INVIT_FINGER = `${newPrefix}sjc/sum-avt-banner-btn-invit-finger.png`;
export const SUM_AVT_BANNER_BTN_RULE = `${newPrefix}sjc/sum-avt-banner-btn-rule.png`;
export const SUM_AVT_BTN_ICON_LOCK = `${newPrefix}sjc/sum-avt-btn-icon-lock.png`;
export const SUM_AVT_ICON_BTN_QUESTION_MARK = `${newPrefix}sjc/sum-avt-icon-btn-question-mark.png`;
export const SUM_AVT_IMG_LIST_HEADER = `${newPrefix}sjc/sum-avt-img-list-header.png`;
export const SUM_AVT_TASK_ICON1 = `${newPrefix}sjc/sum-avt-task-icon1.png`;
export const SUM_AVT_TASK_ICON2 = `${newPrefix}sjc/sum-avt-task-icon2.png`;
export const SUM_AVT_TASK_WRAP_HEADER = `${newPrefix}sjc/sum-avt-task-wrap-header_1.png`;
export const SUM_AVT_T_SHIRT_ICON_CHECKED = `${newPrefix}sjc/sum-avt-t-shirt-icon-checked.png`;
export const SUM_AVT_T_SHIRT_SELECTED = `${newPrefix}sjc/sum-avt-t-shirt-selected.png`;
export const SUM_AVT_T_SHIRT_SELECTED_CLOSE = `${newPrefix}sjc/sum-avt-t-shirt-selected-close.png`;
// 攻略
export const SUM_AVT_STRATEGE_HEAD_BG = `${newPrefix}sjc/sum_avt_stratege_head_bg.png`;
export const SUM_AVT_STRATEGE_DESC_3 = `${newPrefix}sjc/sum_avt_stratege_desc_3_1.png`;
export const SUM_AVT_STRATEGE_DESC_2 = `${newPrefix}sjc/sum_avt_stratege_desc_2_1.png`;
export const SUM_AVT_STRATEGE_DESC_1 = `${newPrefix}sjc/sum_avt_stratege_desc_1_01.png`;
export const SUM_AVT_BG_LOCK = `${newPrefix}sjc/sum_avt_bg_lock.png`;
// 弹窗里头得大杂烩
export const SUM_AVT_BG_GRANULAR_LIGHT = `${newPrefix}sjc/sum_avt_bg_granular_light.png`;
export const SUM_AVT_DIALOG_RECEIVE_SUCESS = `${newPrefix}sjc/sum_avt_dialog_receive_sucess.png`;
export const SUM_AVT_WHITE_SHIRT_BG_LONG = `${newPrefix}sjc/sum_avt_white_shirt_bg_long.png`;
export const SUM_AVT_DIALOG_ICON_CLOSE = `${newPrefix}sjc/sum_avt_dialog_icon_close.png`;
export const SUM_AVT_BTN_USE_RIGHT_NOW_TOP = `${newPrefix}sjc/sum_avt_btn_use_right_now_top.png`;
export const SUM_AVT_DIALOG_HEAD_BG_LOCK = `${newPrefix}sjc/sum_avt_dialog_head_bg_lock.png`;
export const SUM_AVT_ICON_WX_FRIEND = `${newPrefix}sjc/sum_avt_icon_wx_friend.png`;
export const SUM_AVT_ICON_WX_SHARED = `${newPrefix}sjc/sum_avt_icon_wx_shared.png`;
export const SUM_AVT_DIALOG_HEAD_BG_GIFT = `${newPrefix}sjc/sum_avt_dialog_head_bg_gift.png`;
export const SUM_AVT_BG_LIGHT_YUN = `${newPrefix}sjc/sum_avt_bg_light_yun.png`;
export const SUM_AVT_BG_LIGHT = `${newPrefix}sjc/sum_avt_bg_light.png`;
export const SUM_AVT_BTN_USE_RIGHT_NOW = `${newPrefix}sjc/sum_avt_btn_use_right_now.png`;
export const SUM_AVT_WHITE_SHIRT_BG = `${newPrefix}sjc/sum_avt_white_shirt_bg_01_01.png`;
export const SUM_AVT_HEADER_SHARED_BTN = `${newPrefix}sjc/sum_avt_header_shared_btn.png`;
export const SUM_AVT_HEADER_BACK_BTN = `${newPrefix}sjc/sum_avt_header_back_btn.png`;
export const SUM_AVT_FIRST_GUIDE_AVATAR = `${newPrefix}sjc/sum_avt_first_guide_avatar.png`;
export const SUM_AVT_FIRST_GUIDE_AVATAR_2 = `${newPrefix}sjc/sum_avt_first_guide_avatar_2.png`;
export const SUM_AVT_FIRST_GUIDE_AVATAR_3 = `${newPrefix}sjc/sum_avt_first_guide_avatar_3.png`;
export const SUM_AVT_FIRST_GUIDE_AVATAR_4 = `${newPrefix}sjc/sum_avt_first_guide_avatar_4.png`;
export const SUM_AVT_FIRST_GUIDE_AVATAR_5 = `${newPrefix}sjc/sum_avt_first_guide_avatar_5.png`;
export const SUM_AVT_FIRST_GUIDE_AVATAR_6 = `${newPrefix}sjc/sum_avt_first_guide_avatar_6.png`;

export const SUM_AVT_FIRST_GUIDE_RECEIVE_BTN = `${newPrefix}sjc/sum_avt_first_guide_receive_btn.png`;
export const SUM_AVT_FIRST_GUIDE_SHIRT_BLACK = `${newPrefix}sjc/sum_avt_first_guide_shirt_black.png`;
export const SUM_AVT_FIRST_GUIDE_SHIRT_WHITE = `${newPrefix}sjc/sum_avt_first_guide_shirt_white.png`;
export const SUM_AVT_GOOD_DEC_BODY_4 = `${newPrefix}sjc/sum_avt_good_dec_body_4.png`;
export const SUM_AVT_GOODS_DESC_003 = `${newPrefix}sjc/sum_avt_goods_desc_003_01_01.png`; //--
export const SUM_AVT_GOODS_DESC_002 = `${newPrefix}sjc/sum_avt_goods_desc_002_01.png`; //--
export const SUM_AVT_GOODS_DESC_001 = `${newPrefix}sjc/sum_avt_goods_desc_001.png`;
export const SUM_AVT_GOOD_DEC_HEADER_BG = `${newPrefix}sjc/sum_avt_good_dec_header_bg.png`;
export const SUM_AVT_BTN_GET_RIGHT_NOW = `${newPrefix}sjc/sum_avt_btn_get_right_now.png`;

// 短袖v1.2.1
export const NEW_TSHIRT_BUG_LIST_LEFT_ICON = `${newPrefix}sjc/new_tshirt_bug_list_left_icon.png`;
export const NEW_TSHIRT_GET_BTN_BG = `${newPrefix}sjc/new_tshirt_get_btn_bg.png`;
export const NEW_TSHIRT_GET_HEADER_BG = `${newPrefix}sjc/new_tshirt_get_header_bg.png`;
export const NEW_TSHIRT_GIFT_GET_BG = `${newPrefix}sjc/new_tshirt_gift_get_bg.png`;
export const NEW_TSHIRT_HAS_SELECTED = `${newPrefix}sjc/new_tshirt_has_selected.png`;
export const NEW_TSHIRT_HAS_SELECTED_NOT = `${newPrefix}sjc/new_tshirt_has_selected_not.png`;
export const NEW_TSHIRT_HOT_POSITION = `${newPrefix}sjc/new_tshirt_hot_position_01.png`;
export const NEW_TSHIRT_INVITE_HEADER_BG = `${newPrefix}sjc/new_tshirt_invite_header_bg.png`;
export const NEW_TSHIRT_LEFT_ARROW_ICON = `${newPrefix}sjc/new_tshirt_left_arrow_icon.png`;
export const NEW_TSHIRT_RIGHT_ARROW_ICON = `${newPrefix}sjc/new_tshirt_right_arrow_icon.png`;
export const NEW_TSHIRT_RIGHT_ARROW_LIGHT_ICON = `${newPrefix}sjc/new_tshirt_right_arrow_light_icon.png`;
export const NEW_TSHIRT_TOP_BG = `${newPrefix}sjc/new_tshirt_top_bg_01.jpg`;
export const NEW_TSHIRT_PREVIEW_BIG_PIC = `${newPrefix}sjc/new_tshirt_preview_big_pic.png`;

// 微信分享蒙层
export const SJC_TSHIRT_WX_SHARE_MASK_ARROW_TOP = `${H5_CDN}/sjc_tshirt_wx_share_mask_arrow_top.png`;
export const SJC_TSHIRT_WX_SHARE_MASK_BG_BOTTOM = `${H5_CDN}/sjc_tshirt_wx_share_mask_bg_bottom.png`;
export const SJC_TSHIRT_WX_SHARE_MASK_BG_TOP_DOLL = `${H5_CDN}/sjc_tshirt_wx_share_mask_bg_top_doll.png`;
// 竞招职位
export const JINGZHAO_PAYWAY_SELECTED_NOT = `${newPrefix}sjc/jingzhao_payway_selected_not.png`;
export const JINGZHAO_PAYWAY_SELECTED = `${newPrefix}sjc/jingzhao_payway_selected.png`;
export const JINGZHAO_VIP_DESC_03 = `${newPrefix}sjc/jingzhao_vip_desc_03.png`;
export const JINGZHAO_VIP_DESC_02 = `${newPrefix}sjc/jingzhao_vip_desc_02.png`;
export const JINGZHAO_VIP_DESC_01 = `${newPrefix}sjc/jingzhao_vip_desc_01.png`;
export const JINGZHAO_TOOL_BUY_ICON = `${newPrefix}sjc/jingzhao_tool_buy_icon.png`;
export const JINGZHAO_HEADER_CLOSE = `${H5_CDN}/jingzhao_header_close_02.png`;
export const JINGZHAO_TAB_QUSTION_ICON = `${H5_CDN}/jingzhao_tab_qustion_icon_02.png`;
export const JINGZHAO_COMMON_WARN_ICON = `${H5_CDN}/jingzhao_common_warn_icon_02.png`;
export const JINGZHAO_ALER_INFO_TOP_ICON = `${H5_CDN}/jingzhao_aler_info_top_02.png`;
export const JINGZHAO_VIP_DESC_OLD_001 = `${H5_CDN}/jingzhao_vip_desc_old_001.png`;


/* 保险订单记录 */
export const NOT_PAY = `${newPrefix}zyh/not_pay.png`;
export const ALL_PAY = `${newPrefix}zyh/all_pay.png`;
export const BACK_PAY = `${newPrefix}zyh/back_pay.png`;
export const RIGHT_ALLOW = `${newPrefix}zch/right_allow.png`;

export const SHARE_WX = `${newPrefix}zch/base_share1.png`;

export const SHARE_WX_PG = `${newPrefix}zch/wx_share_bg.png`;

/** 会员权益 */
export const MEMBER_VIP_ANGULAR = `${newPrefix}zh/vip-angular.png`;
export const POSITIONING_ICON = `${newPrefix}pp/positioning-icon.png`;
export const RIGHT_BREADCRUMBS = `${newPrefix}pp/right-breadcrumbs.png`;
export const SHARE_BY_MINI = `${newPrefix}gyf/share_by_mini.jpg`;

//招聘数据可视化
export const BANNER_BACKGROUND = `${newPrefix}cyh/banner-background.png`;
export const DIRECT_DIALING = `${newPrefix}cyh/direct-dialing.png`;
export const GREEN_DESCEND = `${newPrefix}cyh/green-descend.png`;
export const JOB_REFRESH = `${newPrefix}cyh/job-refresh.png`;
export const ONLINE_COMMUNICATION = `${newPrefix}cyh/online-communication.png`;
export const RED_RISE = `${newPrefix}cyh/red-rise.png`;
export const RISE_ICON = `${newPrefix}cyh/rise-icon.png`;
export const TOGGLE = `${newPrefix}cyh/toggle.png`;
export const VIP_BACK = `${newPrefix}cyh/vip-back.png`;
export const WHITE_BACK = `${newPrefix}cyh/white-back.png`;
export const BANNER_BACKGROUND_LIGHT = `${newPrefix}cyh/banner_background_light.png`;

//招聘数据可视化-YPZP版本
export const BANNER_BACKGROUND_NEW = `${H5_CDN}/h5_ypzp_recruit_data_center_top_bg.png`;
export const GREEN_DESCEND_NEW = `${H5_CDN}/h5_ypzp_recruit_data_center_arrow_down.png`;
export const RED_RISE_NEW = `${H5_CDN}/h5_ypzp_recruit_data_center_arrow_up.png`;
export const TOGGLE_NEW = `${H5_CDN}/h5_ypzp_recruit_data_center_arrow_down_switch.png`;
export const WHITE_BACK_NEW = `${H5_CDN}/h5_ypzp_recruit_data_center_arrow_left.png`;

/* 金刚区落地页 */
export const DIAMOND_AREA = `${newPrefix}zch/diamond_area.png`;
export const MINIDiamond_BG = `${newPrefix}zch/miniDiamond_BG.png`;
export const DIAMOND_PHONE = `${newPrefix}zch/diamond_phone.png`;
export const CUSTOM_BG = `${newPrefix}fb/DA_KE_BG.png`;

export const CLOSE_CUSTOM = `${newPrefix}fb/close_custom.png`;
export const CUSTOM_RIGHT_ICON = `${newPrefix}fb/custom_right_icon.png`;

export const VIP_IVON_MEMBER_C = `${newPrefix}fb/VIP_IVON_MEMBER_C.png`;
export const VIP_IVON_MEMBER_M = `${newPrefix}fb/VIP_IVON_MEMBER_M.png`;
export const VIP_IVON_MEMBER_V = `${newPrefix}fb/VIP_IVON_MEMBER_V.png`;
export const VIP_IVON_MEMBER_WEN = `${newPrefix}fb/VIP_IVON_MEMBER_WEN.png`;
export const VIDE_PLAY = `${newPrefix}fb/VIDE_PLAY.png`;

/** 机械下载页 */
export const MACHINERY_BG1 = `${newPrefix}zh/machinery_bg1.png`;
export const MACHINERY_BG2 = `${newPrefix}zh/machinery_bg2.png`;
export const MACHINERY_BG3 = `${newPrefix}zh/machinery_bg3.png`;
export const MACHINERY_ICON = `${newPrefix}zh/machinery_icon.png`;
export const VIP_DEATILS_BG = `${newPrefix}fb/VIP_DEATILS_BG.png`;
export const VIP_ZHUAN_SHU_QUANYI_DEATILS = `${newPrefix}fb/VIP_ZHUAN_SHU_QUANYI_DEATILS.png`;

export const HUIYUAN_ZHINAN = `${newPrefix}zh/b-member-vip.mp4`;

export const DA_KEHU_PC_TITLE = `${newPrefix}fb/DA_KEHU_PC_TITLE.png`;
export const DA_KEHU_PC = `${newPrefix}fb/DA_KEHU_PC.png`;

export const CUSTOMER_WARN = `${newPrefix}zh/customer_warn.png`;
export const CUSTOMER_TIME = `${newPrefix}zh/customer_time.png`;
export const CUSTOMER_SUCCESS = `${newPrefix}zh/customer_success.png`;
export const CUSTOMER_FAIL = `${newPrefix}zh/customer_fail.png`;
export const CORPORATE = `${newPrefix}zh/customer_corporate.png`;

export const YP_DOWNAPP_BG = `${newPrefix}lt/yp_downapp_bg1.png`;

export const RIGHT_TAG_ICON = `${newPrefix}zh/right_tag_icon.png`

/** 购买页说明 */
export const PUBLISH_CARD1 = `${newPrefix}zh/publish_card1.png`
export const PUBLISH_CARD2 = `${newPrefix}zh/publish_card3.png`
export const MY_PROP_PUBLISH = `${newPrefix}zh/my_prop.png`
export const MY_PROP_REFRESH = `${newPrefix}zh/my_prop_refresh.png`
export const MY_PROP_CHAT = `${newPrefix}zh/my_prop_chat.png`
export const MY_PROP_TEL = `${newPrefix}zh/my_prop_tel.png`
export const REFRESH_CARD1 = `${newPrefix}zh/refresh_card1.png`
export const REFRESH_CARD2 = `${newPrefix}zh/refresh_card2.png`
export const CHAT_CARD1 = `${newPrefix}zh/chat_card1.png`
export const CHAT_CARD2 = `${newPrefix}zh/chat_card2.png`
export const TEL_CARD1 = `${newPrefix}zh/tel_card1.png`
export const TEL_CARD2 = `${newPrefix}zh/tel_card2.png`

export const RECRUIT_CALL = `${newPrefix}zh/recruit_call.png`
export const RECRUIT_CHAT = `${newPrefix}zh/recruit_chat.png`
export const RECRUIT_PUBLISH = `${newPrefix}zh/recruit_publish.png`
export const RECRUIT_REFRESH = `${newPrefix}zh/recruit_refresh.png`

/* 道具商城 */
export const BOUGHT_ARROW = `${newPrefix}zq/bought_arrow.png`;
export const PROP_TIP = `${newPrefix}zq/prop_tip.png`;
export const PROP_POINT = `${newPrefix}zq/prop_point.png`;
export const PROP_RIGHT_ARROW = `${newPrefix}zq/prop_right_arrow.png`;
export const PROP_BOUGHT_RECORD = `${newPrefix}zq/prop_buy.png`;
export const PROP_COUPON = `${newPrefix}zq/prop_coupon.png`;
export const PROP_JOB_TOP = `${newPrefix}zq/prop_job_top.png`;
export const PROP_URGENT = `${newPrefix}zq/prop_urgent.png`;
export const PROP_MASS = `${newPrefix}zq/prop_bombs.png`;
export const PROP_SUB = `${newPrefix}zq/prop_sub.png`;
export const PROP_REFRESH = `${newPrefix}zq/prop_refresh.png`;
export const PROP_SUSTAIN = `${newPrefix}zq/prop_sustain_refresh.png`;
export const PROP_RESUME_TOP = `${newPrefix}zq/prop_resume_top.png`;
export const POINT = `${newPrefix}xya/point.png`
export const YP_INTEGRAL = `${newPrefix}xya/yp-integral.png`

/** C端求职权益页 */
export const NEWVIP_TAG = `${newPrefix}xya/vip-tag.png`

/** 积分明细页 */
export const POINT_RECORD_EMPTY = `${newPrefix}xya/recrod_empty1.png`
export const YP_POINTS = `${newPrefix}xya/yp-points.png`
export const POINTS_DETAIL_BG = `${newPrefix}sjc/points_detail_bg.png`
export const POINTS_DETAIL_HEADER_BACK_ICON = `${newPrefix}sjc/points_detail_header_back_icon.png`

/** 道具效果页 */
export const PROPS_ACCOUNTING = `${newPrefix}xya/yp-accounting.png`
export const PROPSEFFECT_B_EMPTY = `${newPrefix}zyh/record_empty.png`

/** 在线畅聊卡教程视频 */
export const LINE_CHAT_VIDEO = `${H5_CDN}/b-chat-vip.mp4`;

/** 购买记录 */
export const BUY_RECORD = `${newPrefix}yc/yp_h5-buy-record-empty.png`;

/** 招工信息地理位置定位图标 */
export const RECRUIT_POS_ICON = `${newPrefix}yc/yp_h5-recruit-detail-map-icon.png`;
export const RESUME_EMPTY = `${newPrefix}yc/yp_h5-resume-empty.png`;
export const DEATIL_RIGHT_ARROW = `${newPrefix}zh/detail_right_allow.png`;
/** 送T恤活动，邀请弹窗 背景 */
export const MY_INVITE_RECORD_POP_BG = `${newPrefix}lt/my-invite.png`;

/** 送T恤活动，卡片 */
export const SEND_TX_FRIEND1 = `${newPrefix}lt/send_tx_friend1_01_new.png`;

/** 送T恤活动，朋友圈 */
export const SEND_TX_FRIEND2 = `${newPrefix}lt/send_tx_friend2_01_new.png`;

/** 我的兑换 */
export const MY_INVITE_RECORD_EXCHANGE = `${newPrefix}lt/exchange.png`;

/** 我的奖品 */
export const EXCHANGE_TITLE = `${newPrefix}lt/exchange_title.png`;
export const GIFT_TITLE = `${newPrefix}lt/gift.png`;
export const PRIZE_TITLE = `${newPrefix}lt/prize_title.png`;

/** 送T恤弹窗 背景 */
export const SEND_T_POP_BG = `${newPrefix}sjc/send_t_pop_bg_01.png`;

export const YP_DKH_BG = `https://cdn.yupaowang.com/yupao_hybrid_h5/yp_dkh_bg4.png`
export const YP_DKH_BG_MINI = `https://cdn.yupaowang.com/yupao_hybrid_h5/yp_dkh_bg5.png`

export const YP_DKH_BG_FOOTER = `https://cdn.yupaowang.com/yupao_hybrid_h5/yp_dkh_footer.png`
export const VIP_TAG2 = `${newPrefix}zh/vip_tag2.png`

export const JOB_TOP_PROVINCE_CARD = `${newPrefix}pp/prop_province_top.png`
export const JOB_TOP_CITY_CARD = `${newPrefix}pp/prop_city_top.png`
export const YP_B_MEMBER_TAG1 = `${newPrefix}zh/b-member-tag1.png`
export const YP_B_MEMBER_TAG2 = `${newPrefix}zh/b-member-tag2.png`
export const YP_B_MEMBER_TAG3 = `${newPrefix}zh/b-member-tag3.png`
export const YP_B_MEMBER_BG = `${newPrefix}zh/b-member-bg.png`

export const TOP_CRAD = `${newPrefix}zh/top_card.png`

export const MY_INVITE_RECORD_EMPTY = `${newPrefix}lt/my_invite_record_empty.png`;

/** 送T恤弹窗 背景 */
export const QUESTION_ICON = `${newPrefix}lt/question.png`;

// 金刚区H5
export const RECORD_EMPTY = `${newPrefix}zyh/record_empty.png`
export const H5_YPZP_JGQ_SUB_TITLE_ICON = `${H5_CDN}/h5_ypzp_jgq_sub_title_icon.png.png`

/** 朋友圈分享裂变 */
export const FISSION_BACK = `${newPrefix}lt/fission_back.png`
export const FISSION_HEADER = `${newPrefix}lt/fission_header.png`
export const FISSION_HEADER_MINI = `${newPrefix}lt/fission_header_mini.png`

export const FISSION_ACTIVE = `${newPrefix}zh/fission_active.png`
export const FISSION_STEP1 = `${newPrefix}zh/fission_step1.png`
export const FISSION_STEP1_1 = `${newPrefix}lt/fission_step1_1.png`
export const FISSION_STEP2 = `${newPrefix}zh/fission_step2.png`
export const FISSION_STEP2_1 = `${newPrefix}zh/fission_step2_1.png`
export const FISSION_STEP3 = `${newPrefix}lt/fission_step33.png`
export const FISSION_STEP4 = `${newPrefix}lt/fission_step4444.png`

export const FISSION_RIGHT_ARROW = `${newPrefix}lt/fission_right_arrow.png`
export const FISSION_SUS = `${newPrefix}lt/fission_sus.png`
export const FISSION_TIME = `${newPrefix}lt/fission_time.png`
export const FISSION_UPLOAD = `${newPrefix}lt/fission_upload.png`
export const FISSION_WARN = `${newPrefix}lt/fission_warn.png`
export const FISSION_FRIEND = `${newPrefix}lt/fission_friend.png`
export const FISSION_POINT = `${newPrefix}lt/fission_point1.png`
export const FISSION_DOWN = `${newPrefix}lt/fission_down.png`
export const FISSION_EXCLAMATION = `${newPrefix}lt/fission_exclamation.png`
export const FISSION_OK = `${newPrefix}lt/fission_ok.png`
export const FISSION_REMAIN = `${newPrefix}lt/fission_remain.png`
export const FISSION_TIMER = `${newPrefix}lt/fission_timer.png`

export const FISSION_ANDROID_CODE = `${newPrefix}lt/fission_android_code2.jpg`
export const FISSION_IOS_CODE = `${newPrefix}lt/fission_ios_code2.jpg`
export const FISSION_MINI_CODE = `${newPrefix}lt/fission_mini_code2.jpg`
export const FISSION_QIWEI_CODE = `${newPrefix}lt/fission_qiwei_code2.jpg`
export const FISSION_SIWEI_CODE = `${newPrefix}lt/fission_siwei_code2.jpg`

export const SJC_SYS_ALERT_INFO_ICON = `${H5_CDN}/sjc_sys_alert_info_icon.png`;

/** 开票 */
export const INVOICE_CHECKED = `${newPrefix}lt/invoice_checked.png`
export const INVOICE_CLOSE = `${newPrefix}lt/invoice_close.png`
export const INVOICE_CODE = `${newPrefix}lt/invoice_code.png`
export const INVOICE_TEL = `${newPrefix}lt/invoice_tel.png`
export const INVOICE_OFF = `${newPrefix}lt/invoice_off.png`
export const INVOICE_DELETE = `${newPrefix}lt/invoice_delete.png`
export const CUSTOM_BG1 = `${newPrefix}zh/DA_KE_BG1.png`;
export const LOADING_IMG = `${newPrefix}zh/loading.gif`;
export const BLUE_CLOSE_ICON = `${newPrefix}gyf/blue-close-icon.png`

/** 白领专区 */
export const TRIANGLE_ICON = `${newPrefix}zq/triangle.png`;
export const LOCATION_ICON = `${newPrefix}zq/location.png`;
export const POSITION_ICON = `${newPrefix}zq/position.png`;
export const RIGHTARW_ICON = `${newPrefix}zq/right.png`;

/** b端会员 挽留弹窗背景 */
export const B_VIP_DETAINMENT = `${newPrefix}lt/b_vip_detainment1.png`;
export const B_VIP_TiCKET_CHECKED = `${newPath}b_vip_ticket_checked.png`;
export const B_VIP_TiCKET_CHECK = `${newPath}b_vip_ticket_check.png`;
export const B_VIP_TiCKET_FRAME = `${newPath}b_vip_ticket_frame.png`;
export const B_VIP_TiCKET_END = `${newPath}b_vip_ticket_end.png`;
export const B_VIP_TiCKET_ICON = `${newPath}b_vip_ticket_icon.png`;
export const B_VIP_TiCKET_ARROW = `${newPath}b_vip_ticket_arrow.png`;
/** 送T恤 订单确认图标 */
export const ORDER_LOCATION = `${newPrefix}zq/location.png`;
export const ORDER_CLOSE = `${newPrefix}zq/close.png`;
export const ORDER_BACK = `${newPrefix}zq/back.png`;
export const ORDER_SIZE_M = `${newPrefix}zq/size_m.png`;
export const ORDER_SIZE_L = `${newPrefix}zq/size_l.png`;
export const ORDER_SIZE_XL = `${newPrefix}zq/size_xl.png`;
export const ORDER_SIZE_2XL = `${newPrefix}zq/size_2xl.png`;
export const ORDER_SIZE_M_F = `${newPrefix}zq/f_size_m.png`;
export const ORDER_SIZE_L_F = `${newPrefix}zq/f_size_l.png`;
export const ORDER_SIZE_XL_F = `${newPrefix}zq/f_size_xl.png`;
export const B_MEMBER_VIP_BG = `${newPrefix}zh/b-member-vip-bg.png`;
export const B_MEMBER_VIP_BACK = `${newPrefix}zh/b-member-vip-back.png`;
export const RIGHT_ARROW = `${newPrefix}zh/right_arrow.png`;
export const VECTOR_ICON = `${newPrefix}zh/vector_icon.png`;
export const HORN_ICON = `${newPrefix}zh/horn_icon.png`;
export const CONFIRM_SELECT = `${newPrefix}sjc/confrim-select.png`;
export const RIGHT_POINT = `${newPrefix}sjc/right_point.png`;
export const FRAME_TRIANGLE = `${newPrefix}zh/frame_triangle.png`;
export const B_MEMBER_VIP_TAG1 = `${newPrefix}zh/b-member-vip-tag1.png`;
export const B_MEMBER_VIP_TAG2 = `${newPrefix}zh/b-member-vip-tag2.png`;
export const B_MEMBER_VIP_TAG3 = `${newPrefix}zh/b-member-vip-tag3.png`;
export const B_MEMBER_BG1 = `${newPrefix}sjc/b-member-bg1.png`;
export const YP_B_MEMBER_NEW_TAG1 = `${newPrefix}sjc/b-member-new-tag1.png`
export const YP_B_MEMBER_NEW_TAG2 = `${newPrefix}sjc/b-member-new-tag2.png`
export const YP_B_MEMBER_NEW_TAG3 = `${newPrefix}sjc/b-member-new-tag3.png`
// 红色警示icon
export const ICON_WARN = `${newPrefix}cjz/icon-warn.png`
export const ICON_LEFT_BACK = `${newPrefix}sjc/points_detail_header_back_icon.png`

/** 意见反馈 */
export const ADVICE_ADD_BACK_ICON = `${newPrefix}lt/advice_add_back_icon.png`;
export const ADVICE_ADD_BG = `${newPrefix}lt/advice_add_bg1.png`;
export const ADVICE_ADD_MSG = `${newPrefix}lt/advice_add_msg.png`;
export const ADVICE_ADD_SERVICE = `${newPrefix}lt/advice_add_service.png`;
export const INTRODUCTION_CARD = `${newPrefix}zh/introduction_card.png`;

export const CAN_IMG = `${newPrefixNew}can_img.png`;

/** 安全保障中心 */
export const SAFE_CENTER_IMG1 = `${newPrefixNew}safe_center_img1.png`
export const SAFE_CENTER_IMG2 = `${newPrefixNew}safe_center_img2.png`
export const SAFE_CENTER_IMG3 = `${newPrefixNew}safe_center_img3.png`
export const SAFE_CENTER_IMG4 = `${newPrefixNew}safe_center_img4.png`
export const SAFE_CENTER_BOSS_IMG1 = `${newPrefixNew}boss_fraud1.png`
export const SAFE_CENTER_BOSS_IMG2 = `${newPrefixNew}boss_fraud2.png`
export const SAFE_CENTER_BOSS_IMG3 = `${newPrefixNew}boss_fraud3.png`
export const SAFE_CENTER_BOSS_IMG4 = `${newPrefixNew}boss_fraud4.png`
export const SAFE_CENTER_WORKER_IMG1 = `${newPrefixNew}boss_worker1.png`
export const SAFE_CENTER_WORKER_IMG2 = `${newPrefixNew}boss_worker2.png`
export const SAFE_CENTER_CHECK_IMG1 = `${newPrefixNew}safe_center_check1.png`
export const SAFE_CENTER_CHECK_IMG2 = `${newPrefixNew}safe_center_check2.png`
export const SAFE_CENTER_CHECK_IMG3 = `${newPrefixNew}safe_center_check5.png`
export const SAFE_CENTER_FRAGE1 = `${newPrefixNew}safe_cetner_frage1.png`
export const SAFE_CENTER_FRAGE2 = `${newPrefixNew}safe_cetner_frage2.png`
export const SAFE_CENTER_FRAGE3 = `${newPrefixNew}safe_cetner_frage3.png`
export const LOADING_IMG_V1 = `${newPath}loading_v1.png`;

export const MEMBER_VIP_USE_TAG_CIKA = `${H5_CDN}/member_vip_use_tag_cika.png`;
export const MEMBER_VIP_USE_CIKA = `${H5_CDN}/member_vip_use_cika.png`;
export const MEMBER_VIP_USE_TAG_VIP = `${H5_CDN}/member_vip_use_tag_vip.png`;
export const MEMBER_VIP_USE_VIP = `${H5_CDN}/member_vip_use_vip2.png`;
export const MEMBER_VIP_BACK_ICON = `${H5_CDN}/member_vip_left.png`;
export const MEMBER_VIP_STRIPE = `${H5_CDN}/member_vip_stripe.png`;
export const MEMBER_VIP_VIDEO_PLAY = `${H5_CDN}/member_video_play.png`;
export const VIP_ACTIVE_BG1 = `${H5_CDN}/vip-active-bg2.png`;
export const CIKA_ACTIVE_BG1 = `${H5_CDN}/cika-active-bg2.png`;

export const MEMBER_VIP_C_TAG1 = `${H5_CDN}/member_vip_dialog_tag1.png`;
export const MEMBER_VIP_C_TAG2 = `${H5_CDN}/member_vip_dialog_tag2.png`;
export const MEMBER_VIP_C_TAG3 = `${H5_CDN}/member_vip_dialog_tag3.png`;
export const MEMBER_VIP_C_ICON = `${H5_CDN}/member_vip_dialog_icon1.png`;
export const MEMBER_VIP_C_EQUITY = `${H5_CDN}/member_vip_c_equity.png`
export const MEMBER_VIP_C_EQUITY_TAG = `${H5_CDN}/member_vip_c_equity_tag.png`

export const SAFE_BANNER3 = `${H5_CDN}/safe_banner3.png`;
