import qs from 'qs';

import { ConfigService } from '$lib/service/config';
import { YP_API_SERVER, YP_PHP_SERVER } from '$lib/utils/env';
import { getCurrentTime, getPHPSign, getRandomWord, getSign } from '$lib/utils/sign';
import { DIFF_TIME } from '$lib/utils/storageKeys';
import { browser, getOs, getOsVersion } from '$lib/utils/tools';

import { Http } from './http';
import type { HttpOptions, IHttpBaseOptions } from './types';

export const hHeaders = {
	appVersion: '6.2.0',
	business: 'YPZP',
	hybird: 'H5',
	platform: 'android',
	runtime: 'android',
	runtimeversion: '10',
	system: 'ANDROID',
	systemVersion: '10',
	packagename: 'io.dcloud.H576E6CC7',
	os: getOs(),
	osVersion: getOsVersion() || 6.0,
	Packageversion: '6.1.0'
};
/** 获取请求header信息 */
export const getRequestHeader = () => {
	const system_time = `${Math.floor(new Date().getTime() / 1000)}`;
	return {
		'request-type': 'application/json',
		/** 系统时间 */
		system_time
	};
};

/**
 * 获取系统类型
 * @param {string} runtime 全小写匹配
 * @returns ios、android、wechat、wechat_h5、h5_mobile
 */
function getSystemType(runtime?: string) {
	/** ios、android、wechat、wechat_h5、h5_mobile */
	let system_type = 'h5_mobile';
	if (runtime) {
		if (runtime.indexOf('mini') !== -1) system_type = 'wechat';
		else if (runtime === 'wx_h5') system_type = 'wechat_h5';
		else if (runtime === 'h5') system_type = 'h5_mobile';
		else system_type = runtime;
	}
	return system_type;
}

/**
 * 获取服务器时间
 */
function* getServerTime() {
	yield ConfigService.getPHPServerTime();
}

const requestInstance = (options: IHttpBaseOptions) =>
	Http.init({
		headers: getRequestHeader(),
		timeout: 2000,
		/** 序列化请求参数 */
		paramsSerializer: (params: any) => {
			return qs.stringify(params, { arrayFormat: 'indices' });
		},
		...(options ?? {})
	});
let _delay: IteratorResult<Promise<any>, void>;
export const request = async <R>(url: string, options: Partial<HttpOptions>) => {
	const headers = {
		...getSign(options),
		...options.headers
	};

	/** 接口为php时，header参数调整 */
	if (options?.php) {
		const system_type = getSystemType(headers?.runtime?.toLowerCase());
		const diff_time = browser ? window[DIFF_TIME] : (global as any)[DIFF_TIME];
		const tIsGetServerTime = url.indexOf('/eventreport/v1/base/serverTime') !== -1;
		if (!tIsGetServerTime && !diff_time) {
			if (!_delay) {
				_delay = getServerTime().next();
			}
			const response = await _delay.value;
			if (browser) window[DIFF_TIME] = response?.data?.serverTime - Date.now();
			else (global as any)[DIFF_TIME] = response?.data?.serverTime - Date.now();
		}
		const tNonce = getRandomWord(10) + '.' + getCurrentTime();
		const version =
			headers?.packageVersion ??
			headers?.packageversion ??
			headers?.appVersion ??
			headers?.appversion ??
			'3.6.3';
		Object.assign(headers, {
			system_type,
			/** 来源端 1-鱼泡网 */
			business: '1',
			version,
			'x-yp-nonce': tNonce,
			/** 这里使用 getSign 时 传入当前请求的sign，防止请求头被更改时计算出的 sign 值错误 */
			'x-yp-sign': getPHPSign({ ...headers, version, 'x-yp-nonce': tNonce })
		});
	}

	return requestInstance({ prefix: options?.php ? YP_PHP_SERVER : YP_API_SERVER })<R>({
		url,
		...(options ?? {}),
		headers
	});
};
const get = <R>(url: string, options = {} as Partial<HttpOptions>) => {
	return request<R>(url, { ...options, method: 'GET' });
};

const post = <R>(url: string, options = {} as Partial<HttpOptions>) => {
	return request<R>(url, { ...options, method: 'POST' });
};

export { get, post };
