import { isIos } from '$lib/utils/tools';
// 浏览器支持-直接暴露到window
export const isNative = (protocol: string) => {
	if (typeof window !== 'undefined') {
		if (isIos()) {
			// ios通过方法名作为协议，传递的参数key作为调用的方法名，value作为参数
			return window.webkit?.messageHandlers?.[protocol];
		}
		return window?.[protocol];
	} else {
		return false;
	}
};

export type IBrowserNativeFuncOption<T> = {
	hasCallback?: boolean;
	protocol: string;
	funcName: string;
	params?: T;
};

type ICallSuccess<R> = {
	status: 'success' | 'error';
	data?: R;
	name: string;
	message?: string;
};

/**
 * @name callNative
 * @description 调用native方法,callback函数名以协议开头,_callback结尾（protocol_callback）
 * @param options {funcName: string, params?: R, protocol:string}
 * @returns Promise<R>
 */
export const callNative = <T, R = null>(options: IBrowserNativeFuncOption<T>) => {
	return new Promise<ICallSuccess<R>>((resolve, reject) => {
		if (!isNative(options.protocol)) {
			console.error('无法建立连接！');
			reject({ status: 'error', name: options.funcName });
		} else {
			if (isIos()) {
				const funcInfo = { [options.funcName]: options?.params || {} };
				if (options.hasCallback) {
					Object.assign(funcInfo, { callback: `${options.funcName}Callback` });
				}

				window.webkit?.messageHandlers?.[options.protocol]?.postMessage({
					messageId: options.funcName,
					messageData: options?.params
				});
			} else {
				const funcInfo = {
					messageId: options.funcName,
					messageData:
						typeof options.params === 'string' ? options?.params : JSON.stringify(options?.params)
				};
				if (options.hasCallback) {
					Object.assign(funcInfo, { responseId: `${options.funcName}Callback` });
				}
				window?.[options.protocol]?.sendMessageNative(JSON.stringify(funcInfo));
			}
			if (options.hasCallback) {
				window[`${options.funcName}Callback`] = (data: Omit<ICallSuccess<R>, 'name'>) => {
					resolve({ ...(data ?? {}), status: data?.status, name: options.funcName });
				};
			} else {
				resolve({ status: 'success', name: options.funcName });
			}
		}
	});
};

const isObj = (obj: any) => Object.prototype.toString.call(obj) === '[object Object]';
const isFunction = (fn: any) => Object.prototype.toString.call(fn) === '[object Function]';

export const registryFunc = (options: { [key: string]: Function }) => {
	if (!isObj(options)) return console.error('请正确传递注册函数');
	if (typeof window !== 'undefined') {
		Object.keys(options).forEach((registryName) => {
			if (!isFunction(options[registryName])) return console.error('请正确传递注册函数');
			const event = new Event(registryName);
			window[registryName] = (...arg: any) => {
				options[registryName](...arg);
				window.dispatchEvent(event);
				return { status: 'success' };
			};
		});
	} else {
		console.error('请在客户端尝试注册方法：' + JSON.stringify(options));
	}
};
