import { postApi } from '$lib/model/request';

import {
    CarouselInfoReq,
    CarouselInfoRes
} from './types';

export default class CommonOrderService {

    // 获取轮播用户
    static fetchOrderBuyrCarousel(params: CarouselInfoReq) {
        return postApi<CarouselInfoRes>('/trade/v1/order/getOrderCarousel', { data: params });
    }
}
