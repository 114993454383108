import qs from 'qs';

import { toast } from '$components/shared/toast';
import { canUseDom } from '$components/shared/utils/can-use-dom';
import { callNative } from '$lib/hooks/native/browserSupport';
import { YP_API_SERVER, YP_ENV } from '$lib/utils/env';
import { MiniApi } from '$lib/utils/miniApi';
import { browser } from '$lib/utils/tools';

import { getCookie, removeCookie } from '../utils/tools/storage';
import type { HttpOptions, IHttpBaseOptions, PublicResult } from './types';

const isFormData = (obj: any) => Object.prototype.toString.call(obj) === '[object FormData]';

export class Http {
	private static toLogin: boolean;
	private static errorMessage: string;
	private static errorInfo: any;
	private static baseOptions: IHttpBaseOptions = {
		prefix: YP_API_SERVER,
		timeout: 2000,
		credentials: 'include',
		headers: {},
		params: null,
		data: null,
		paramsSerializer: undefined
	};

	private static timeoutFn() {
		return new Promise((_, reject) => {
			setTimeout(
				() => reject({ message: '网络超时', type: 'Timeout' }),
				Http.baseOptions.timeout! * 1000
			);
		});
	}

	public static init(options: IHttpBaseOptions) {
		Http.baseOptions = { ...Http.baseOptions, ...options };
		return Http.fetch;
	}

	public static async fetch<R>(resources: HttpOptions) {
		const paramsFiltered = Object.entries(resources.params || {}).filter(([, val]) => val);
		// const paramsString = Http.baseOptions.paramsSerializer
		// 	? Http.baseOptions.paramsSerializer(paramsFiltered)
		// 	: new URLSearchParams(paramsFiltered).toString();
		const paramsString = new URLSearchParams(paramsFiltered).toString();
		const tUrl = [(resources.mock ? '' : Http.baseOptions.prefix) + resources.url, paramsString]
			.filter(Boolean)
			.join('?');

		const tHeaders = { ...resources.headers, ...Http.baseOptions.headers };
		const tBody = resources.data
			? isFormData(resources.data)
				? resources.data
				: JSON.stringify(resources.data)
			: null;

		const { url, options } = Http.requestInterceptor(tUrl, {
			...resources,
			headers: tHeaders as any,
			body: tBody as any
		});
		if (!isFormData(resources.data)) options.headers['Content-Type'] = 'application/json';
		try {
			const response = await Promise.race([fetch(url, { ...options }), Http.timeoutFn()]);
			try {
				const data = (await Http.responseInterceptor(response, options)) as PublicResult<R>;
				return data;
			} catch (error) {
				return Http.errorHandler(error, options);
			}
		} catch (e) {
			return Http.errorHandler(e, resources);
		}
	}
	// 请求前置拦截
	private static requestInterceptor(url: string, options: HttpOptions) {
		return {
			url,
			options: {
				...options
			}
		};
	}

	private static async responseInterceptor(response: any, options: HttpOptions) {
		if (response?.json && typeof response.json === 'function') {
			try {
				const data = await response.json();
				if (options.mock) return Promise.resolve(data);
				if (options.php) {
					if (data?.head && 'code' in data?.head) {
						const servercode = String(data?.head.code);
						if (servercode === '200') {
							return Promise.resolve(data);
						}
					}
					return Promise.reject(data);
				} else {
					if (data && 'code' in data) {
						const servercode = String(data?.code);
						if (servercode === '0') {
							return Promise.resolve(data);
						}
					}
					return Promise.reject(data);
				}
			} catch (e) {
				return Promise.reject(e);
			}
		}
	}

	private static errorHandler(error: any, options: HttpOptions) {
		const { hideInterceptMessage, defaultLogin = true, showErrorMessageForce } = options;
		/** 打印错误日志 */
		if (YP_ENV !== 'prod') {
			console.error(
				`Http fetch Error: ${options.url}`,
				error,
				options.headers,
				options?.data || options?.params
			);
		} else {
			console.error(`Http fetch Error: ${options.url}`, error);
		}

		if (browser) {
			// eslint-disable-next-line no-console
			console.log('网络异常捕获', error);

			if (error.type === 'Timeout') {
				this.errorMessage = '网络超时';
				this.errorInfo = hideInterceptMessage ? { message: '网络超时', type: 'Timeout' } : {};
			} else if (error?.name === 'SyntaxError') {
				this.errorMessage = '请求非法';
				this.errorInfo = hideInterceptMessage ? { message: '请求非法', type: 'SyntaxError' } : {};
			} else if (
				(error?.message && error?.message.includes('Failed to fetch')) ||
				error?.name === 'TypeError'
			) {
				this.errorMessage = '网络异常';
				this.errorInfo = hideInterceptMessage ? { message: '网络异常', type: 'NetworkError' } : {};
			} else if ('code' in error || error?.head?.code) {
				if ((Number(error?.code) === 401 || Number(error?.head?.code) === 401) && defaultLogin) {
					if (getCookie('enterType')) {
						removeCookie('userModel.token')
						window.location.href = window.location.origin + `/login?${window.location.search}`;
					}
					if (!this.toLogin) {
						this.toLogin = true;
						this.errorMessage = '登录失效，请重新登录~';
						this.errorInfo = hideInterceptMessage ? error : {};
						if (options.headers?.platform?.includes('mini')) {
							if (canUseDom) {
								MiniApi.navigateTo({
									url:
										'/subpackage/userauth/auth/index' +
										'?' +
										qs.stringify({ redirect: window.location.href })
								});
							}
						} else callNative({ protocol: 'ypNative', funcName: 'toLogin' });
					}
				} else if (Number(error?.code) === 430) {
					this.errorMessage = error?.message;
					this.errorInfo = hideInterceptMessage ? error : {};
				} else {
					this.errorInfo = error;
					if (showErrorMessageForce) { // 强制显示错误提示的补丁
						this.errorMessage = error?.message || error?.head?.msg
					}
				}
			} else {
				this.errorInfo = error;
			}

			if (this.errorMessage && !hideInterceptMessage) toast.show(this.errorMessage);
			return Promise.reject(this.errorInfo);
		} else {
			return Promise.reject(error);
		}
	}
}
