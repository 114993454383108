import { postApi } from '$lib/model/request';

import {
	IGetOccInfoParams,
	IIosBuyLimitParams,
	IIosRmbBuy,
	IMemberVipListData,
	IMemberVipOrderParams,
	IMemberVipOrderRes,
	IMemberVipParams,
	IMemberVipPurchaseData,
	IMemberVipPurchasePreData,
	IMemberVipRecommendDate,
	IMemberVipRecordData,
	IMemberVipRenewData,
	IMemberVipRenewParams,
	IMemberVipResourceList,
	IMemberVipResourcePageParams,
	IMemberVipUpgradeData,
	IMemberVipUpgradeParams,
	IOccInfosData,
	IPreOrderNo,
	IQueryCouponData,
	IQueryCouponParams,
	IQueryHitGrayParams,
	IQueryHitGrayQueryData,
	IRecordParams,
	IVipMembershipParams,
	IVipMembershipRes,
	IVoucherRetainData,
	IVoucherRetainParams
} from './types';

export default class MemberVipService {
	/** 会员套餐列表 */
	static vipList() {
		return postApi<IMemberVipListData[]>('/member/v1/vipSpu/list');
	}
	/** 会员购买记录 */
	static recordList(params: IRecordParams) {
		return postApi<IMemberVipRecordData>('/member/v1/vipRecord/pageList', { data: params });
	}
	/** 610以上会员购买记录 */
	static resumePageList(params: IRecordParams) {
		return postApi<IMemberVipRecordData>('/member/v1/vipRecord/resumePageList', { data: params });
	}
	/** 推荐会员列表 */
	static recommendList() {
		return postApi<IMemberVipRecommendDate>('/member/v1/vipRecommend/list');
	}
	/** 会员购买 */
	static purchaseQuery(params: IMemberVipParams) {
		return postApi<IMemberVipPurchaseData>('/member/v2/vipSpu/purchaseQuery', { data: params });
	}
	/** 会员续费 */
	static renewQuery(params: IMemberVipRenewParams) {
		if (!params.type) {
			params.type = 0;
		}
		return postApi<IMemberVipRenewData>('/member/v1/vipSpu/detail', { data: params });
	}
	/** 会员升级 */
	static upgradeQuery(params: IMemberVipUpgradeParams) {
		return postApi<IMemberVipUpgradeData>('/member/v2/vipSpu/upgradeQuery', { data: params });
	}
	/** 下单接口 */
	static createOrder(data: IMemberVipOrderParams) {
		return postApi<IMemberVipOrderRes>('/trade/v1/goods/createOrder', { data });
	}
	/** 查询当前用户是否走工行 */
	static queryHitGray(params: IQueryHitGrayParams) {
		return postApi<IQueryHitGrayQueryData>('/trade/v1/order/queryHitGray', { data: params });
	}

	/** 查询用户VIP状态v2 */
	static vipMembership(params: IVipMembershipParams) {
		return postApi<IVipMembershipRes>('/member/v2/vipMembership/status', { data: params });
	}

	/** 通过工种ids查询工种数据集合 */
	static getOccInfos(params: IGetOccInfoParams) {
		return postApi<IOccInfosData>('/occupation/v1/getOccInfos', { data: params });
	}
	/** 获取预下订单号 */
	static fetchPreOrderNo() {
		return postApi<IPreOrderNo>('/trade/v1/order/getOrderNo');
	}
	/** 获取订单号 */
	static fetchByOrderNo(params: IPreOrderNo) {
		return postApi<IPreOrderNo>('/trade/v1/order/getByOrderNo', { data: params });
	}
	/** 获取卡券列表 */
	static queryCoupon(data: IQueryCouponParams) {
		return postApi<IQueryCouponData>('/vouchercenter/v1/voucher/queryCanUseVoucherList', { data });
	}
	/** 会员弹窗优惠券挽留 */
	static voucherRetain(data: IVoucherRetainParams) {
		return postApi<IVoucherRetainData>('/member/v1/vipPop/voucherRetain', { data });
	}
	/** 获取ios交易开关 */
	static getIosRmbBuyLimitFlag(data: IIosBuyLimitParams) {
		return postApi<IIosRmbBuy>('/trade/v1/order/getIosRmbBuyLimitFlag', { data });
	}
	/** 新版会员购买查询 */
	static newPurchaseQuery(params: IMemberVipParams) {
		return postApi<IMemberVipPurchasePreData>('/marketGateway/v1/marketGoodsGroup/purchaseDetail', {
			data: params
		});
	}
	/** 查询用户VIP状态v3 */
	static vipMembershipV3(params: IVipMembershipParams) {
		return postApi<IVipMembershipRes>('/member/v3/vipMembership/status', { data: params });
	}
	/** 下单接口V2 */
	static createOrderV2(data: IMemberVipOrderParams) {
		return postApi<IMemberVipOrderRes>('/trade/v2/order/preCreateOrder', { data });
	}
	/** 更多会员推荐 */
	static vipMemberMoreList(data: IMemberVipResourcePageParams, userrole?: string) {
		const params = userrole
			? {
				data,
				headers: {
					userrole
				}
			}
			: { data };
		return postApi<IMemberVipResourceList>('/cms/v1/resource/page', {
			...params
		});
	}
	/** C端找活会员查询 */
	static vipResumeMember() {
		return postApi('/member/v1/resumeVipMembership/query')
	}
	/** 简历是否存在 */
	static resumeExist() {
		return postApi('/resume/v2/my/exist')
	}
	/** 找活-名片刷新预处理接 */
	static refreshPretreatment() {
		return postApi('/resume/v1/resumeRefresh/refreshPretreatment')
	}
	/** 找活-名片刷新接口 */
	static resumeRefresh(data?: { source: number }) {
		return postApi('/resume/v1/resumeRefresh/refresh', {
			data
		})
	}
}
