var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};

_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {
  "id": "prod-0.1.0"
};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/yp_hybrid/static/prod/82720249833";
/*
 * @Date: 2024-07-03 16:38:42
 * @Description: Modify here please
 */

import * as Sentry from '@sentry/nextjs';
import { YP_ENV } from '$lib/utils/env';
/** !!暂时放在这里，不动。这是next方案。 */

Sentry.init({
  // DSN告诉SDK将事件发送到哪里。如果未提供此值，SDK 将尝试从环境变量中读取它SENTRY_DSN。如果该变量也不存在，SDK 将不会发送任何事件。
  dsn: 'https://1b4791457b74250fe838fdd8659f1453@sentry.yupaowang.com/11',
  // https://docs.sentry.io/platforms/javascript/configuration/integrations/
  integrations: [// 捕获浏览器的性能数据
  Sentry.browserTracingIntegration()],

  /**
   * 0 到 1 之间的数字，控制将特定交易发送到 Sentry 的百分比概率。（0 代表 0%，1 代表 100%）
   * 同样适用于应用程序中创建的所有交易。必须tracesSampler 定义此或才能启用跟踪。
   */
  tracesSampleRate: 0.6,
  environment: YP_ENV,
  // 过滤问题
  beforeSend: function beforeSend(event, hint) {
    var error = hint.originalException;
    var regexpArr = [// /^[Oo]bject captured as promise rejection with keys/,
      // /^Unable to preload CSS for.*.css$/,
      // /^canceled$/,
      // /^UCShellJava.sdkEventFire/,
      // /^[Ff]ailed to fetch dynamically imported module:/,
      // /UnhandledRejection*/
    ];

    if (error && error !== null && error !== void 0 && error.message && regexpArr.some(function (reg) {
      return reg.test(error.message);
    })) {
      return null;
    }

    return event;
  } //注意：如果要覆盖自动释放值，请不要设置
  //此处为“release”值-使用环境变量“SENTRY_release”，因此
  //它也会附加到你的源地图上

});