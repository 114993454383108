import classNames from 'classnames';
import type { ForwardedRef, ReactNode } from 'react';
import React, { forwardRef, useEffect } from 'react';

import IconDialogClose from '$icons/IconDialogClose';

import { Button } from '../button';
import { Mask } from '../mask';
import TouchFeedBack from '../touch-feed-back';
import type { GetContainer } from '../utils/render-to-container';
import { Visible } from '../visible';
import styles from './index.module.scss';

const classPrefix = `ypm-dialog`;

export interface DialogProps {
	afterClose?: () => any;
	header?: ReactNode;
	title?: ReactNode;
	content?: ReactNode;
	onClose?: () => any;
	closeOnMaskClick?: boolean;
	visible?: boolean;
	getContainer?: GetContainer;
	containerStyle?: React.CSSProperties;
	containerClassName?: string;
	messageClassName?: string;
	bodyStyle?: React.CSSProperties;
	bodyClassName?: string;
	maskStyle?: React.CSSProperties;
	maskContentClassName?: string;
	maskClassName?: string;
	cancelButtonText?: ReactNode;
	confirmButtonText?: ReactNode;
	hideCancelButton?: boolean;
	hideConfirmButton?: boolean;
	buttonPosition?: 'vertical' | 'horizontal';
	clickButtonClose?: boolean;
	showCloseIcon?: boolean;
	children?: any;
	cancelButtonIdName?: string;
	confirmButtonIdName?: string;
	rightCloseIcon?: boolean;
	onCancel?: () => void | Promise<void>;
	onConfirm?: () => void | Promise<void>;
	onCloseIcon?: () => void | Promise<void>;
	unmount?: () => void;
	closeIconClass?: string;
}
const unmounts: (() => void)[] = [];
export const Dialog = forwardRef((props: DialogProps, ref: ForwardedRef<HTMLDivElement>) => {
	useEffect(() => {
		props.unmount && unmounts.push(props.unmount);
	}, []);

	const onCancel = async () => {
		try {
			await props.onCancel?.();
			props.clickButtonClose && props.onClose?.();
		} catch {}
	};

	const onConfirm = async () => {
		try {
			await props.onConfirm?.();
			props.clickButtonClose && props.onClose?.();
		} catch {}
	};

	const onCloseIcon = async () => {
		try {
			await props.onCloseIcon?.();
			props.onClose?.();
		} catch (e) {
			console.warn(e, '');
		}
	};

	return (
		<div
			ref={ref}
			onClick={(event) => {
				event.preventDefault();
				event.stopPropagation();
			}}
		>
			<Mask
				visible={props.visible}
				destroyOnClose
				disableBodyScroll
				getContainer={props.getContainer}
				afterClose={props.afterClose}
				onMaskClick={props.closeOnMaskClick ? props.onClose : undefined}
				style={props.maskStyle}
				className={classNames(styles[classPrefix], props.maskClassName)}
			>
				<div className={classNames(styles[`${classPrefix}-wrap`], props?.maskContentClassName)}>
					{props.children || (
						<div
							style={props.containerStyle}
							onClick={(e) => e.stopPropagation()}
							className={classNames(styles[`ypm-dialog-content`], props.containerClassName)}
						>
							<div
								style={props.bodyStyle}
								className={classNames(styles[`ypm-dialog-body`], props.bodyClassName)}
							>
								<>
									{props.header ??
										(!!props.title && (
											<div className={styles[`ypm-dialog-body-title`]}>{props.title}</div>
										))}
									{!!props.content && (
										<div className={styles[`ypm-dialog-body-message-wrapper`]}>
											<div
												className={classNames(
													styles[`ypm-dialog-body-message`],
													props.messageClassName
												)}
											>
												{props.content}
											</div>
										</div>
									)}
								</>
							</div>
							<div
								className={classNames(styles[`ypm-dialog-footer`], {
									vertical: props.buttonPosition === 'vertical'
								})}
							>
								{!props?.hideCancelButton && (
									<Button
										className={classNames(
											'primary-color primary-text',
											styles['ypm-dialog-footer-button']
										)}
										outlined
										onClick={onCancel}
									>
										{props.cancelButtonText}
									</Button>
								)}
								{!props?.hideConfirmButton && (
									<Button
										className={classNames(
											'primary-color white-text',
											styles['ypm-dialog-footer-button']
										)}
										onClick={onConfirm}
									>
										{props.confirmButtonText}
									</Button>
								)}
							</div>
						</div>
					)}
					<Visible visible={!!props.showCloseIcon}>
						<div className={styles[`${classPrefix}-close`]}>
							<TouchFeedBack activeClassName="touch-feed-back">
								<IconDialogClose
									className={classNames(styles[`${classPrefix}-close-icon`], props?.closeIconClass)}
									onClick={onCloseIcon}
								/>
							</TouchFeedBack>
						</div>
					</Visible>
				</div>
			</Mask>
		</div>
	);
});

export const clear = () => {
	while (true) {
		const unmount = unmounts.pop();
		if (!unmount) break;
		unmount();
	}
};

Dialog.defaultProps = {
	cancelButtonText: '取消',
	confirmButtonText: '确定',
	buttonPosition: 'horizontal',
	closeOnMaskClick: false,
	clickButtonClose: true,
	showCloseIcon: false
};
