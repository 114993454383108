import { IHttpHeaders } from '$lib/http/types';
import { postApi } from '$lib/model/request';
import { getOs, getOsVersion } from '$lib/utils/tools';

import { IMemberVipOrderRes, IosRmbBuyLimitRiskCheck } from '../b-member-vip';
import {
    CheckCodeRes,
    CreateChargeOrderReq,
    OrderListIapProductsReq,
    OrderListIapProductsRes
} from './types.d';

export const headers: Partial<IHttpHeaders> = {
    hybird: 'H5',
    platform: 'h5',
    runtime: 'h5',
    system: 'h5',
    packagename: 'yp_js_mobile',
    os: getOs(),
    osversion: getOsVersion() || 6.0,
    appversion: '6.2.0',
    version: '6.2.0',
    hybridversion: '6.2.0',
    runtimeversion: '10',
    systemVersion: '10',
    packageversion: '6.2.0'
};

export default class PointsRechargeGuideService {
    /** 获取IAP商品列表（混淆） */
    static IAPListIapProducts() {
        return postApi<OrderListIapProductsRes>('/trade/v2/order/listIapProducts', { headers });
    }

    /** 校验购买code */
    static IAPCheckPurchaseCode(data: OrderListIapProductsReq) {
        return postApi<CheckCodeRes>('/trade/v2/order/iap/checkPurchaseCode', { data, headers });
    }

    /** 校验购买风控 */
    static IAPCheckPayRisk(data: OrderListIapProductsReq) {
        return postApi<Partial<IosRmbBuyLimitRiskCheck>>('/trade/v2/order/iap/checkPayRisk', { data, headers });
    }

    /** 创建充值订单 */
    static IAPCreateChargeOrder(data: CreateChargeOrderReq) {
        return postApi<IMemberVipOrderRes>('/trade/v2/order/iap/createChargeOrder', { data, headers });
    }

}
