import { getApi } from '$lib/model/request';

import type {
	IFetchAwardsReq,
	IFetchAwardsRes,
	IFetchCashOutAccountReq,
	IFetchCashOutAccountRes
} from './types';

export default class AwardDetailService {
	/** 获取 活动详情 */
	static fetchCashOutAccount(params: IFetchCashOutAccountReq) {
		return getApi<IFetchCashOutAccountRes>('/active/cashout/account', { params, php: true });
	}
	/** 获取列表 */
	static fetchAwards(params: IFetchAwardsReq) {
		return getApi<IFetchAwardsRes>('/active/cashout/awards', { params, php: true });
	}
}
