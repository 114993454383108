import { HttpOptions } from '$lib/http/types';
import { postApi } from '$lib/model/request';

import {
    IFetchAppShareHeaders,
    IFetchAppShareReq,
    IFetchExchangeRecordRes,
    IFetchGoodsDetail,
    IFetchMyInviteRecordRes,
    IFetchMyPrizeRes,
    IFetchTShirtActivityConfigRes,
    IFetchTShirtClassicalPicRes,
    IFetchTShirtExchangeRecord,
    IPageParamsReq,
    IPostShippingAddressParams,
    IShippingAddressInfo,
} from './types';

export default class ActivitySummerShirtFreeService {
    /** 获取活动时间等基础信息配置 */
    static fetchTShirtActivityConfig() {
        return postApi<IFetchTShirtActivityConfigRes>('/marketing/v1/activityService/Tshirt/info');
    }

    /** 获取精选图片 */
    static fetchClasssicalPicList(params: IPageParamsReq) {
        return postApi<IFetchTShirtClassicalPicRes>('/marketing/v1/activityService/Tshirt/buyerShow/list', { data: params });
    }

    /** 已经获奖得用户数据，轮播用 */
    static fetchTshirtExchangeRecordCarousel() {
        return postApi<IFetchTShirtExchangeRecord>('/marketing/v1/activityService/Tshirt/exchangeRecord/carousel');
    }

    /** 获取app分享配置 */
    static fetchAppShare(
        data: IFetchAppShareReq
        , options?: Partial<HttpOptions>) {
        return postApi('/share/v1/config/info', { data, ...(options ?? {}) });
    }

    /** 我的邀请记录 */
    static fetchMyInviteRecord() {
        return postApi<IFetchMyInviteRecordRes>('/marketing/v1/activityService/Tshirt/shareInvite/query');
    }

    /** 我的兑换记录 */
    static fetchExchangeRecord() {
        return postApi<IFetchExchangeRecordRes>('/marketing/v1/activityService/Tshirt/exchangeRecord/list');
    }

    /** 查询是否有待用券 */
    static fetchMyPrize() {
        return postApi<IFetchMyPrizeRes>('/marketing/v1/activityService/Tshirt/prize/unusedList');
    }

    /** 查询商品详情 */
    static fetchGoodsDetail(data: {
        goodsCode: string;
        configId?: string;
        purchaseOrderNo?: string;
        userId?: string;
    }) {
        return postApi<IFetchGoodsDetail>('/trade/v1/mallGoods/detail', { data });
    }

    /** 订单确认提交 */
    static fetchOrderSubmit(data: any) {
        return postApi<any>('/trade/v1/goods/createOrder', { data });
    }

    /** 查询收货地址 */
    static fetchShippingAddress() {
        return postApi<IShippingAddressInfo>('/trade/v1/receiveAddr/queryOne');
    }

    /** 新增收货地址 */
    static postShippingAddress(data: IPostShippingAddressParams) {
        return postApi('/trade/v1/receiveAddr/add', { data });
    }

    /** 修改收货地址 */
    static updateShippingAddress(data: IShippingAddressInfo) {
        return postApi('/trade/v1/receiveAddr/modify', { data });
    }
}
