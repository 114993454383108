import { AppProps } from 'next/app';
import React, { EffectCallback, ReactElement } from 'react';

import { inBrowser } from '../utils/in-browser';

export type IKeepAliveComponentProps<P = {}> = AppProps<P> & {
	keepAliveKey: string;
	useKeepAliveEffect: (effectCallback: EffectCallback, deps: any[]) => void;
};

export type ICacheComponentOptions = {
	scrollContainer?: Element;
	key: string;
	when?: boolean | (() => boolean);
};
const withKeepAlive = <P extends unknown = {}>(
	Component: ((props: IKeepAliveComponentProps<P>) => ReactElement | null) & {
		getInitialProps?: any;
	},
	options: ICacheComponentOptions
) => {
	const KeepAlive = (props: IKeepAliveComponentProps<P>) => {
		return <Component {...props} keepAliveKey={options.key} />;
	};

	if (Component?.getInitialProps) {
		KeepAlive.getInitialProps = Component.getInitialProps;
	}

	KeepAlive.cacheInfo = {
		key: options.key,
		scrollContainer: options.scrollContainer ?? inBrowser ? document.body : null
	};
	KeepAlive.displayName = options.key;

	return KeepAlive;
};

export default withKeepAlive;
