import '../assets/styles/globals.scss';

import * as Sentry from '@sentry/nextjs'
import { NextPageContext } from 'next';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import { dialog } from '$components/shared/dialog';
import { KeepAliveProvider } from '$components/shared/keep-alive';
import withDynamicRedux from '$components/withDynamicRedux';
import { CacheScrollContext, useCacheScroll, useScroll } from '$lib/hooks/use-cache-scroll';
import { AppState, IReduxContext, useAppSelector } from '$lib/model/wrapper/common';
import { VOLC_ENGINE_APP_ID, VOLC_ENGINE_APP_ID_ZP, YP_ENV } from '$lib/utils/env';
import { getReduxCtxSync } from '$lib/utils/getReduxContext';
import { useBack } from '$lib/utils/nativeFunc';
import { sliceUrlParams } from '$lib/utils/sliceUrlParams';
import { browser, isIos, isWechat } from '$lib/utils/tools';
import { getCookie, removeCookie, setCookie, setLocalStore } from '$lib/utils/tools/storage';

import { canUseDom } from '../components/shared/utils/can-use-dom';
import { useAppVersion } from './hooks/useAppVersion';

type IAppProps = AppProps & { reduxWrapper?: IReduxContext; initialState?: any };
function AppPage({ Component, ...rest }: IAppProps) {
	const { query, headers } = useAppSelector((state: AppState) => state.configModel);

	YP_ENV !== 'prod' && console.warn('[来自原生的参数]:', rest.router.query);
	const router = useRouter();
	const reduxWrapper = rest?.reduxWrapper;
	const { platform } = rest?.initialState?.configModel ?? 'h5';
	const is_ypzp_app = useAppVersion(800);

	const { run: back } = useBack(platform?.includes('mini'));
	const volc_app_id =
		(platform?.includes('ios') || headers?.os === 'IOS') && is_ypzp_app
			? VOLC_ENGINE_APP_ID_ZP
			: VOLC_ENGINE_APP_ID;
	const [scroll] = useScroll();
	useCacheScroll(scroll);
	useEffect(() => {
		smoothscroll.polyfill();
	}, []);

	useEffect(() => {
		setLocalStore('ROUTE_PATHNAME', router.asPath);
	}, [router.asPath, router.pathname]);

	useEffect(() => {
		if (query?.uid !== getCookie('UID_CACHE')) {
			removeCookie('VIP_PAY_CHANNEL');
			removeCookie('VIP_FROM_PAGE_SOURCE');
			setCookie('UID_CACHE', query?.uid);
		}
		if (rest.router?.query?.xnhd_status === 'end') {
			dialog.show({
				title: '温馨提示',
				content: '本次活动已结束，前往活动列表查看其他活动吧',
				hideCancelButton: true,
				confirmButtonText: '我知道了',
				onConfirm: () => {
					back();
				}
			});
		}
	}, []);
	return (
		<>
			<Head>
				<meta
					name="viewport"
					content="initial-scale=1.0, width=device-width, maximum-scale=1.0, user-scalable=0, viewport-fit=cover"
				/>
				<title>鱼泡网</title>
				<meta name="keywords" content={'鱼泡网'} />
				<meta name="description" content={'鱼泡网'} />
				<meta name="theme-color" content="#f8f8f8" />

				<meta name="apple-mobile-web-app-capable" content="yes" />
				<meta name="apple-mobile-web-app-status-bar-style" content="default" />
				<link
					rel="icon"
					type="image/x-icon"
					href="https://staticscdn.zgzpsjz.com/newyupao/v2.1.7/image/favicon.ico"
				/>
			</Head>

			<CacheScrollContext.Provider value={scroll}>
				<KeepAliveProvider>
					<Component {...{ ...rest.pageProps, router: rest.router }} />
				</KeepAliveProvider>
			</CacheScrollContext.Provider>

			<Script
				src="/burypoint.js"
				onLoad={function () {
					if (canUseDom && reduxWrapper) {
						console.warn(
							'火山sdk初始化-native：%s, appId：%s, isYPZP:',
							['ios', 'android'].includes(platform),
							volc_app_id,
							platform?.includes('ios') || headers?.os === 'IOS'
						);

						window.collectEvent('init', {
							// init evt dispatch
							app_id: volc_app_id as unknown as number, // expected a number
							channel_domain: `https://snssdk.yupaowang.com`, // default channel
							log: true, // debug
							autotrack: false, // 全埋点开关
							spa: true,
							Native: !!['ios', 'android'].includes(platform)
						});
						const {
							configModel: { query }
						} = getReduxCtxSync<IReduxContext>(reduxWrapper).getState();
						window.collectEvent('config', {
							user_unique_id: query?.uid || 0
						});
						query?.uid && Sentry.setUser({ id: query?.uid })
						window.collectEvent('start');
					}

					var script = document.createElement('script');
					script.setAttribute(
						'src',
						'https://cdn.yupaowang.com/yupao_hybrid_h5/js/collect-privity-v5.1.2.js'
					);
					document.body.appendChild(script);
				}}
			/>
			<Script src="https://static.jmlk.co/scripts/dist/jmlink.min.js"></Script>
			{YP_ENV !== 'prod' && (
				<React.Fragment>
					<Script src="https://cdn.yupao.com/new_yupao_hybrid/js/eruda-features.js" />
					<Script src="https://cdn.yupao.com/new_yupao_hybrid/js/eruda-memory.min.js" />
					<Script src="https://cdn.yupao.com/new_yupao_hybrid/js/eruda-fps.min.js" />
					<Script
						src="https://cdn.yupao.com/new_yupao_hybrid/js/eruda.min.js"
						onLoad={function () {
							try {
								window.eruda.init();
								window.eruda.add(window.erudaFeatures);
								window.eruda.add(window.erudaMemory);
								window.eruda.add(window.erudaFps);
							} catch (error) {
								console.warn(error);
							}
						}}
					/>
				</React.Fragment>
			)}
			{isWechat() && !isIos() && (
				<Script
					id="handleFontSize"
					dangerouslySetInnerHTML={{
						__html: `
						if (typeof WeixinJSBridge === 'object' && typeof WeixinJSBridge.invoke === 'function') {
								handleFontSize()
						} else {
								if (document.addEventListener) {
										document.addEventListener('WeixinJSBridgeReady', handleFontSize, false)
								} else if (document.attachEvent) {
										document.attachEvent('WeixinJSBridgeReady', handleFontSize)
										document.attachEvent('onWeixinJSBridgeReady', handleFontSize)
								}
						}
						function handleFontSize () {
								// 设置网页字体为默认大小
								WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 })
								// 重写设置网页字体大小的事件
								WeixinJSBridge.on('menu:setfont', function () {
										WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 })
								})
						}
				`
					}}
				/>
			)}
		</>
	);
}

AppPage.getInitialProps =
	(context: AppContext & { ctx: NextPageContext<any> & { reduxWrapper?: IReduxContext } }) =>
	async (store: any) => {
		const appPage = ['404', 'error'].every((p) => context.ctx.pathname.indexOf(p) < 0);
		if (!browser && store && appPage) {
			sliceUrlParams(store, context.ctx);
		}
		if (!browser) {
			if (context.ctx.res && context.ctx?.asPath?.includes?.('/common/downloadApp')) {
				context.ctx.res.writeHead(302, {
					Location: 'https://m.yupao.com' + context.ctx?.asPath
				});
				context.ctx.res.end();
			}
		}

		return {
			pageProps: {
				...(await App.getInitialProps(context)).pageProps,
				router: context.router,
				reduxWrapper: context.ctx?.reduxWrapper
			}
		};
	};

export default withDynamicRedux(AppPage);
