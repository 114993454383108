import { postApi } from '$lib/model/request';

import { fetchCountRes, fetchRecordRes, fetchRuleRes } from './types';

export default class VideoIntegralServise {
	/** 获取积分规则 */
	static fetchRule() {
		return postApi<fetchRuleRes>('/marketingcenter/v1/watchVideo/rule');
	}
	/** 活动记录 */
	static fetchRecord() {
		return postApi<fetchRecordRes>('/marketingcenter/v1/watchVideo/activityLog');
	}
	/** 获取当前信息 */
	static fetchInfo() {
		return postApi<fetchCountRes>('/marketingcenter/v1/watchVideo/countAndReward');
	}
}
