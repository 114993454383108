import camelCase from 'lodash/camelCase';
import { AppContext, AppProps } from 'next/app';
import React from 'react';
import { Provider } from 'react-redux';

import * as wrappers from '$lib/model/wrapper';

const moduleKeys = Object.keys(wrappers);

const getCurrentWrapper = (name: string) => {
	return (wrappers as any)[name];
};

const getWrapperKey = (key?: string) => {
	const wrapperKey = camelCase(key ?? '');

	return moduleKeys.includes(wrapperKey) ? wrapperKey : 'common';
};

const withDynamicRedux = (
	Component: ((props: AppProps) => JSX.Element) & {
		getInitialProps?: (p: AppContext) => (store: unknown) => any;
	}
) => {
	const displayName = `withDynamicRedux(${Component.name || 'Component'})`;

	const hasInitialProps = 'getInitialProps' in Component;
	const Wrapper = (props: AppProps) => {
		const originalKey = props.router.pathname.split('/')?.[1];
		const wrapperKey = getWrapperKey(originalKey);
		const reduxWrapper = getCurrentWrapper(wrapperKey);
		const { store, props: combinedProps } = reduxWrapper.useWrappedStore(props);
		return (
			<Provider store={store}>
				<Component {...combinedProps} {...{ reduxWrapper }} />
			</Provider>
		);
	};
	Wrapper.displayName = displayName;
	if (hasInitialProps) {
		Wrapper.getInitialProps = async (props: any) => {
			const originalKey = props.router.pathname.split('/')?.[1];
			const wrapperKey = getWrapperKey(originalKey);
			const reduxWrapper = getCurrentWrapper(wrapperKey);
			return reduxWrapper.getInitialAppProps((store: unknown) => () => {
				return Component?.getInitialProps?.({ ...props, ctx: { ...props.ctx, reduxWrapper } })(
					store
				);
			})(props);
		};
	}
	return Wrapper;
};

export default withDynamicRedux;
