import { postApi } from '$lib/model/request';

import type {
	ISecurityArticleReq,
	ISecurityArticleRes,
	IUserCenterRes
} from './types';

export default class SafeCenterService {
	/** 获取 文章相关内容 */
	static fetchSecurityArticle(params: ISecurityArticleReq) {
		return postApi<ISecurityArticleRes>('/cms/v1/release/guarantee', { data: params });
	}

	/** 获取 用户信息 */
	static fetchUserData() {
		return postApi<IUserCenterRes>('/account/v2/userBase/userCenter', {});
	}
}
