import { postApi } from '$lib/model/request';

import {
	IReortCentreStars,
	IReportCentreCategory,
	IReportCentreInfoForm,
	IReportWorkerOrYpCategory,
	IResponseRadio,
	IResponseRadio2,
	IResponseRecords,
	IResponseReportResults,
	IRportCentreWorkerOrYpForm,
} from './types';

export default class ReportCentreService {

	/** 举报记录 */
	static reportRecords() {
		return postApi<{data: IResponseRecords[]}>('/audit/v1/reportCenter/reportRecords');
	}

	/** 提交评价 */
	static evaluationStars(data: IReortCentreStars) {
		return postApi('/audit/v1/reportCenter/evaluate', { data });
	}

	/** 七天内的联系记录，即举报信息 */
	static contactRecords(data: { pageSize: number, currentPage: number }) {
		return postApi('/audit/v1/reportCenter/contactRecords', { data });
	}

	/** 提交信息举报表单 */
	static submitInfoForm(data: IReportCentreInfoForm) {
		return postApi('/audit/v1/reportCenter/save', { data });
	}

	/** 提交工人/老板举报表单 */
	static submitWorkerForm(data: Omit<IRportCentreWorkerOrYpForm, 'configId'>) {
		return postApi('/crm/v1/feedbackRecord/add', { data: { ...data, configId: 28 } });
	}

	/** 提交平台举报表单 */
	static submitYpForm(data: Omit<IRportCentreWorkerOrYpForm, 'configId'>) {
		return postApi('/crm/v1/feedbackRecord/add', { data: { ...data, configId: 27 } });
	}

	/** 举报信息的配置选项 */
	static reportInfoCategory(data: IReportCentreCategory) {
		return postApi<{ list: [IResponseRadio] }>('/audit/v1/reportCenter/category', { data });
	}

	/** 举报工人/老板的配置选项 */
	static reportWorkerCategory(data: IReportWorkerOrYpCategory) {
		return postApi<{ category: IResponseRadio2[] }>('/crm/v1/feedbackConfig/category', { data: { ...data, configId: 28 } });
	}

	/** 举报平台的配置选项 */
	static reportYpCategory(data: IReportWorkerOrYpCategory) {
		return postApi<{ category: IResponseRadio2[] }>('/crm/v1/feedbackConfig/category', { data: { ...data, configId: 27 } });
	}

	/** 查询处理信息 */
	static reportResult(data: { id: string, reportType: string }) {
		return postApi<IResponseReportResults>('/audit/v1/reportCenter/handResult', { data });
	}
}
