import { postApi } from '$lib/model/request';

import type { IArticleInfo, IFetchOuterArticleReq, IFetchOuterArticleRes } from './types';

export default class ArticleService {
	/** 咨询 */
	static fetchArticleDetail(params: { id: number | string }) {
		return postApi<IArticleInfo>('/cms/v1/release/articleDetail', { data: params });
	}
	static praiseArticle(params: { id: number | string }) {
		return postApi('/cms/v1/article/like', { data: params });
	}
	/** 外链 */
	static fetchOuterArticle(params: IFetchOuterArticleReq) {
		return postApi<IFetchOuterArticleRes>('/cms/v1/outerLink/articleDetail', { data: params });
	}
}
