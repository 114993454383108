// @ts-nocheck
// 导出 reduxContext

const getReduxContext = function <R>(wrapper: unknown): Promise<R> {
	return new Promise((resolve) => {
		wrapper.getInitialAppProps((reduxContext) => () => resolve(reduxContext))({});
	});
};

const getReduxCtxSync = function <R>(wrapper: unknown): R {
	let ctx = <R>{};
	wrapper.getInitialAppProps((reduxContext) => () => (ctx = reduxContext))({});

	return ctx;
};

const getReduxCtx = getReduxContext;

export { getReduxContext, getReduxCtx, getReduxCtxSync };
