import classNames from 'classnames';
import Lottie from 'lottie-web-light';
import React, { useEffect } from 'react';

import defaultLoading from '$assets/lottie/default-loading.json';
import wrapper, { IReduxContext } from '$lib/model/wrapper/common';
import { getReduxCtxSync } from '$lib/utils/getReduxContext';
import { LOADING_IMG_V1 } from '$lib/utils/image_cdn';

import { Mask } from '../mask';
import styles from './index.module.scss';

export interface LoadingProps {
	/** loading 完全关闭后的回调 */
	afterClose?: () => void;
	/** 是否显示 */
	visible?: boolean;
	/** loading弹出时的的父容器 */
	getContainer?: HTMLElement | (() => HTMLElement);
}

const Loading: React.FC<LoadingProps> = (props: LoadingProps) => {
	const {
		configModel: { headers, platform }
	} = getReduxCtxSync<IReduxContext>(wrapper).getState();
	/** 端上的版本号 */
	const appVersion = headers?.appversion || headers?.appVersion || '';
	/** 新版本隔离展示 */
	const isNewIos = platform === 'ios' && Number(appVersion?.split('.').join('')) >= 800;
	/** lottie动画 */
	useEffect(() => {
		if (isNewIos) return;
		if (props.visible) {
			Lottie?.loadAnimation({
				name: 'toast-loading',
				container: document.getElementById('toast-loading')!,
				renderer: 'svg',
				loop: true,
				autoplay: true,
				animationData: defaultLoading
			});
		} else {
			Lottie.destroy('toast-loading');
		}
	}, [props.visible, isNewIos]);
	return (
		<Mask
			visible={props.visible}
			destroyOnClose
			opacity={0}
			disableBodyScroll={true}
			afterClose={props.afterClose}
			getContainer={props.getContainer}
			className={styles.ypmLoadingMask}
		>
			<div className={classNames(styles.ypmLoadingWrap, { [styles.newWrapClass]: isNewIos })}>
				{isNewIos ? (
					<img src={LOADING_IMG_V1} className={styles.loadingImg} alt="" />
				) : (
					<div id="toast-loading" className={styles.ypmLoadingWrapContent} />
				)}
			</div>
		</Mask>
	);
};
export default Loading;
