import './index.scss';

import classNames from 'classnames';
import React, { ReactNode, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';

import Mask from '../mask/Mask';
import { mergeProps } from '../utils/with-default-props';

export interface ToastProps {
	/** Toast 完全关闭后的回调 */
	afterClose?: () => void;
	/** Toast 遮罩样式 */
	maskStyle?: React.CSSProperties;
	/** Toast 遮罩类名 */
	maskClassName?: string;
	/** 是否允许背景点击 */
	maskClickable?: boolean;
	/** toast 文本内容 */
	content?: ReactNode;
	/** 提示持续时间，若为 0 则不会自动关闭 */
	duration?: number;
	/** 垂直方向显示位置，默认为 center */
	position?: 'top' | 'bottom' | 'center';
	/** 是否显示 */
	visible?: boolean;
	/** 轻提示弹出时的的父容器 */
	getContainer?: HTMLElement | (() => HTMLElement);
}

const defaultProps = {
	maskClickable: false
};

const InternalToast: React.FC<ToastProps> = (p) => {
	const props = mergeProps(defaultProps, p);
	const { maskClickable, content, position } = props;
	const clsPrefix = 'ypm-toast';
	const top = useMemo(() => {
		switch (position) {
			case 'top':
				return '20%';
			case 'bottom':
				return '80%';
			default:
				return '50%';
		}
	}, [position]);

	return (
		<>
			<Mask
				visible={props.visible}
				destroyOnClose
				opacity={0}
				disableBodyScroll={!maskClickable}
				getContainer={props.getContainer}
				style={{
					pointerEvents: maskClickable ? 'none' : 'all',
					...props.maskStyle
				}}
				className={classNames(`${clsPrefix}-mask`, props.maskClassName)}
			/>
			<div className={`${clsPrefix}-wrap`}>
				<CSSTransition
					onExited={() =>
						setTimeout(() => {
							props?.afterClose?.();
						}, 0)
					}
					unmountOnExit={true}
					in={props.visible}
					timeout={225}
					classNames="ypm-toast"
				>
					<div
						onClick={(e) => e.stopPropagation()}
						style={{ top }}
						className={classNames(`${clsPrefix}-wrap-content`, `${clsPrefix}-wrap-text`)}
					>
						{content}
					</div>
				</CSSTransition>
			</div>
		</>
	);
};

export default InternalToast;
