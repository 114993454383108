import './index.scss';

import classNames from 'classnames';
import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import type { NativeProps } from '../utils/native-props';
import type { GetContainer } from '../utils/render-to-container';
import { useInitialized } from '../utils/use-initialized';
import { useLockScroll } from '../utils/use-lock-scroll';

const classPrefixMask = 'ypm-mask';

export type MaskProps = {
	visible?: boolean;
	onMaskClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	destroyOnClose?: boolean;
	forceRender?: boolean;
	disableBodyScroll?: boolean;
	opacity?: number;
	getContainer?: GetContainer;
	afterClose?: () => void;
	children?: React.ReactNode;
} & NativeProps;

const Mask: React.FC<MaskProps> = (props) => {
	const cls = classNames(classPrefixMask, props.className, {
		[`${classPrefixMask}-hidden`]: !props.visible
	});
	const initialized = useInitialized(props.visible || props.forceRender);

	const ref = useRef<HTMLDivElement>(null);

	useLockScroll(ref, !!(props.visible && props.disableBodyScroll));

	function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		if (e.currentTarget === e.target) {
			props.onMaskClick?.(e);
			e.stopPropagation();
		}
	}

	const node = () => (
		<CSSTransition
			in={props.visible}
			timeout={200}
			classNames={'ypm-mask'}
			onExited={() =>
				setTimeout(() => {
					props?.afterClose?.();
				}, 0)
			}
			unmountOnExit={props.destroyOnClose}
		>
			<div
				className={cls}
				onClick={handleClick}
				ref={ref}
				style={{
					...props.style,
					backgroundColor: `rgba(0, 0, 0, ${props.opacity ?? 0.45})`
				}}
			>
				{initialized && props.children}
			</div>
		</CSSTransition>
	);

	if (props.getContainer) {
		const container =
			typeof props.getContainer === 'function' ? props.getContainer() : props.getContainer;
		return createPortal(node(), container);
	}
	return node();
};

export default Mask;
