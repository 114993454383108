import classNames from 'classnames';

export type IIconProps = {
	className?: string;
	style?: React.CSSProperties;
	type?: string;
	color?: string;
	onClick?: () => void;
};

const Icon = (props: IIconProps) => {
	return (
		<svg
			className={classNames('icon', props?.className)}
			style={{ ...(props?.style ?? {}), color: props?.color }}
			onClick={props?.onClick}
		>
			<use xlinkHref={`#${props.type}`} />
		</svg>
	);
};

export default Icon;
