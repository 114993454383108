import { getApi, postApi } from '$lib/model/request';

import type {
	IFetchActiveDetailReq,
	IFetchActiveDetailRes,
	IFetchActiveHistoryReq,
	IFetchActiveHistoryRes,
	IFetchRankingsRes,
	IFetchUrlSchemeRes,
	IFetchUserImg
} from './types';

export default class InviteGiveCashService {
	/** 获取 活动详情 */
	static fetchActiveDetail(params: IFetchActiveDetailReq) {
		return getApi<IFetchActiveDetailRes>('/active/active/detail', { params, php: true });
	}
	/** 获取活动详情 落地页 */
	static fetchActiveDetailShow(params: IFetchActiveDetailReq) {
		return getApi<IFetchActiveDetailRes>('/active/active/detailShow', { params, php: true });
	}
	/** 获取我的收益数据 */
	static fetchActiveHistory(params: IFetchActiveHistoryReq) {
		return getApi<IFetchActiveHistoryRes>('/active/cashout/activeHistory', { params, php: true });
	}
	/** 获取榜单信息 */
	static fetchRankings(params: IFetchActiveHistoryReq) {
		return getApi<IFetchRankingsRes>('/active/cashout/rankings', { params, php: true });
	}
	/** 获取小程序scheme */
	static fetchUrlScheme(params: FormData) {
		return postApi<IFetchUrlSchemeRes>('/job/home/getUrlScheme', { data: params, php: true });
	}
	/** 参与活动 */
	static fetchJoinActive(params: IFetchActiveHistoryReq) {
		return postApi<any>('/active/active/joinActive', { params, php: true });
	}
	/** 获取用户头像 */
	static fetchUserImg(params: { uid: string; encrypt?: string }) {
		return postApi<IFetchUserImg>('/user/avatar/getOneAvatar', { data: params, php: true });
	}
}
