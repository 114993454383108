/* eslint-disable no-param-reassign */
import md5 from 'crypto-js/md5';
import sha256 from 'crypto-js/sha256';
import omit from 'lodash/omit';
import transform from 'lodash/transform';

import type { HttpOptions } from '$lib/http/types';

import { YP_ENV } from './env';
import { DIFF_TIME } from './storageKeys';
import { browser } from './tools';

const SECRET_LIST = {
	native: '8k&^$Hsk1?kkcj12^99K1ia',
	h5: '*js1(Uc_m12j%hsn#1o%cn1'
} as any;
function isObject(obj: any) {
	return Object.prototype.toString.call(obj) === '[object Object]';
}
function stringifyObj(t: any) {
	if (t === null) {
		return t;
	}
	// 数组
	if (Array.isArray(t)) {
		const list = t.reduce((acc, item) => {
			if (isObject(item)) {
				acc.push(sortObj(item));
			} else {
				acc.push(item);
			}
			return acc;
		}, []);
		return JSON.stringify(list);
	}
	// 是对象 非数据
	if (isObject(t)) {
		return Object.keys(t)
			.sort()
			.reduce((acc, key) => {
				// 数组和对象直接进入判断，开始进行排序，在sortObj判断数组就不改变顺序
				if (typeof t[key] === 'object') {
					return (acc += `${key}=${JSON.stringify(sortObj(t[key]))}&`);
				}

				return (acc += `${key}=${t[key]}&`);
			}, '');
	}
}

function sortObj(obj: any) {
	if (obj === null) {
		return obj;
	}
	if (Array.isArray(obj)) {
		return [...obj].reduce((acc, item) => {
			if (isObject(item)) {
				acc.push(sortObj(item));
			} else {
				acc.push(item);
			}
			return acc;
		}, []);
	}
	if (isObject(obj)) {
		return Object.keys(obj)
			.sort()
			.reduce((acc: any, key) => {
				if (isObject(obj[key])) {
					acc[key] = sortObj(obj[key]);
				} else {
					acc[key] = obj[key];
				}
				return acc;
			}, {});
	}
}

function getParameter({ method, data, params }: any) {
	let parameter = null;
	const tMethods = (method as string).toLocaleUpperCase();
	if (tMethods === 'GET') {
		parameter = params.reduce((acc: any, item: any) => {
			if (typeof item.value !== 'undefined') {
				acc[item.key] = item.value;
			}
			return acc;
		}, {});
	}
	if (tMethods === 'POST') {
		parameter = transform(
			data,
			function (result, value, key) {
				if (typeof value !== 'undefined') {
					Object.assign(result, { [key]: value });
				}
				return result;
			},
			{}
		);
	}

	return parameter;
}

export const getSign = (options: Partial<HttpOptions>): any => {
	const headers = options.headers ?? { runtime: 'h5' };
	/** 除runtime为ios或android外，其余都用h5的密钥 */
	const runtime = headers?.runtime?.toLowerCase() ?? 'h5';
	const secretKey = ['ios', 'android'].includes(runtime) ? 'native' : 'h5';
	const SECRET = SECRET_LIST[secretKey];

	const timestamp = Date.now();
	const params = Array.of(options.params ?? []);

	const parameter = omit(
		getParameter({ method: options.method, data: options?.data, params }),
		'callback'
	);
	const nonce = Math.round(Math.random() * 1000);
	const signString = stringifyObj({ ...parameter, timestamp, nonce }) + SECRET;
	const sign = sha256(signString).toString();
	return { timestamp, sign, nonce };
};

/** php */
export const getRandomWord = (min: number) => {
	let str = '';
	const range = min,
		arr = [
			'0',
			'1',
			'2',
			'3',
			'4',
			'5',
			'6',
			'7',
			'8',
			'9',
			'a',
			'b',
			'c',
			'd',
			'e',
			'f',
			'g',
			'h',
			'i',
			'j',
			'k',
			'l',
			'm',
			'n',
			'o',
			'p',
			'q',
			'r',
			's',
			't',
			'u',
			'v',
			'w',
			'x',
			'y',
			'z'
		];
	for (let i = 0; i < range; i++) {
		const pos = Math.round(Math.random() * (arr.length - 1));
		str += arr[pos];
	}
	return str;
};

/** 取当前时间搓 当生成 nonce 时，本地时间应该加上 差值 diff_time */
export const getCurrentTime = () => {
	/** 客户端环境取客户端时间差 */
	if (browser) {
		return Date.now() + (window[DIFF_TIME] || 0);
	}
	/** 服务端环境取服务端时间差 */
	return Date.now() + ((global as any)[DIFF_TIME] || 0);
};

/** 必须函数返回 */
export const envFun = () => {
	if (['dev', 'develop', 'test'].includes(YP_ENV!)) {
		return 'ZYEr8cHa4U4Lq2f70Ccjl9VFmbbmCTDA';
	} else {
		return 'TJViHTOMPexr7OG0W8qhStZy42H7Fikw';
	}
};

export const getPHPSign = (headers: any) => {
	const token = headers?.token || '';
	const key = envFun();
	const secretKey = key + token + headers?.['x-yp-nonce'] + headers?.version;
	return md5(secretKey).toString();
};
