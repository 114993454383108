import router from 'next/router';
import { useEffect } from 'react';
import { createRoot, Root } from 'react-dom/client';

import { resolveContainer } from '../utils/get-container';
import { inBrowser } from '../utils/in-browser';
import { mergeProps } from '../utils/with-default-props';
import type { LoadingProps } from './Loading';
// import  InternalLoading  from './Loading';
import Loading from './Loading';

const containers = [] as Element[];
const renders = [] as Root[];
export type LoadingShowProps = Omit<LoadingProps, 'visible'>;
const defaultProps = {
	duration: 0
};

function show(p?: LoadingShowProps) {
	if (inBrowser) {
		const props = mergeProps(defaultProps, p);
		const container = document.createElement('div');
		const bodyContainer = resolveContainer(document.body)!;
		bodyContainer.appendChild(container);
		hide();

		const TempLoading = () => {
			const closeLoadingOnPop = (_: string, { shallow }: { shallow: boolean }) => {
				if (!shallow) c.unmount();
			};
			useEffect(() => {
				router.events.on('routeChangeStart', closeLoadingOnPop);
				return () => {
					router.events.off('routeChangeStart', closeLoadingOnPop);
				};
			}, []);

			return <Loading {...props} visible={true} afterClose={() => c.unmount()} />;
		};
		const c = createRoot(container);

		c.render(<TempLoading />);
		renders.push(c);
		containers.push(container);
	}
}

function hide() {
	// eslint-disable-next-line no-constant-condition
	while (true) {
		const container = containers.pop();
		const render = renders.pop();
		if (!container || !render) break;
		render.unmount();
		document.body.removeChild(container);
	}
}
const loading = { hide, show };
export default loading;
