import router from 'next/router';
import type { RefObject } from 'react';
import { createRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { attachPropertiesToComponent } from '../utils/attach-properties-to-component';
import { renderToBody } from '../utils/render-to-body';
import type { DialogProps } from './Dialog';
import { clear, Dialog } from './Dialog';

type Ref = {
	close: () => void;
	ref: RefObject<HTMLDivElement>;
};

export function show(props: Omit<DialogProps, 'visible'>) {
	const Wrapper = forwardRef<Ref>((_, ref) => {
		const [visible, setVisible] = useState(false);
		const refEl = useRef<HTMLDivElement>(null);
		const closeDialogOnPop = (_: string, { shallow }: { shallow: boolean }) => {
			if (!shallow) {
				unmount?.();
			}
		};
		useEffect(() => {
			setVisible(true);
			router.events.on('routeChangeStart', closeDialogOnPop);
			return () => {
				router.events.off('routeChangeStart', closeDialogOnPop);
			};
		}, []);

		useImperativeHandle(ref, () => ({
			close: () => {
				setVisible(false);
			},
			ref: refEl
		}));

		return (
			<Dialog
				ref={refEl}
				{...props}
				visible={visible}
				onClose={() => {
					props.onClose?.();
					setVisible(false);
				}}
				onCloseIcon={() => {
					props?.onCloseIcon?.();
					setVisible(false);
				}}
				unmount={() => unmount?.()}
				afterClose={() => {
					props.afterClose?.();
					unmount?.();
				}}
			/>
		);
	});
	const ref = createRef<Ref>();
	const unmount = renderToBody(<Wrapper ref={ref} />);
	return {
		close: () => {
			ref.current?.close();
		},
		ref: ref.current?.ref
	};
}

export default attachPropertiesToComponent(Dialog, {
	show,
	clear
});
