import { useBrowserNativeFunc } from '$lib/hooks/native/useBrowserNativeFunc';

import { MiniApi } from './miniApi';
import { combineUrlParams } from './url';

type NavigateParams = {
	/** 跳转方式，mini才会有-switchTab、redirectTo、navigateTo、reLaunchTo*/
	navigateMethod?: string;
	route: string;
	params?: Record<string, any>;
};

export const useReLaunchTo = (mini?: boolean) => {
	const { run } = useBrowserNativeFunc({
		funcName: 'reLaunch'
	});
	const reLaunchTo = (params: { route: string; params?: Record<string, any> }) => {
		if (mini)
			(MiniApi as any).reLaunch({
				url: params?.params ? combineUrlParams(params.route, params?.params) : params.route
			});
		else run(params);
	};
	return { run: reLaunchTo };
};

export const useRedirectTo = (mini?: boolean) => {
	const { run } = useBrowserNativeFunc({
		funcName: 'redirectTo'
	});
	const redirectTo = (params: { route: string; params?: Record<string, any> }) => {
		if (mini)
			(MiniApi as any).redirectTo({
				url: params?.params ? combineUrlParams(params.route, params?.params) : params.route
			});
		else run(params);
	};
	return { run: redirectTo };
};

/** 跳转原生页面 */
export const useNavigateTo = (mini?: boolean) => {
	const { run } = useBrowserNativeFunc({
		funcName: 'navigateTo'
	});
	const navigate = (params: NavigateParams) => {
		if (mini)
			(MiniApi as any)[params?.navigateMethod ?? 'navigateTo']({
				url: params?.params ? combineUrlParams(params.route, params?.params) : params.route,
				fail: function () {
					console.error('navigateTo error');
				}
			});
		else run(params);
	};
	return { run: navigate };
};

/** 跳转原生页面, 返回promise */
export const useNavigateToPromise = (mini?: boolean) => {
	const { run } = useBrowserNativeFunc({
		funcName: 'navigateTo'
	});
	const navigate = async (params: NavigateParams) =>
		new Promise((resolve, reject) => {
			if (mini) {
				(MiniApi as any)[params?.navigateMethod ?? 'navigateTo']({
					url: params?.params ? combineUrlParams(params.route, params?.params) : params.route,
					fail: function () {
						console.error('navigateTo error');
						reject('navigateTo error');
					},
					success: function () {
						resolve('navigateTo success');
					}
				});
			} else {
				return run(params)?.then(resolve).catch(reject);
			}
		});
	return { run: navigate };
};

/** 返回上一级 */
export const useBack = <P extends Record<string, any> = {}>(mini?: boolean) => {
	const { run } = useBrowserNativeFunc<P>({ funcName: 'back' });
	const back = (params?: P) => {
		if (mini) MiniApi.navigateBack(params);
		else run(params);
	};
	return { run: back };
};

/** appToast */
export const useAppToast = <P extends Record<string, any> = {}>(mini?: boolean) => {
	const { run } = useBrowserNativeFunc<P>({ funcName: 'showToast' });
	const showToast = (text?: P) => {
		run(text);
	};
	return { run: showToast };
};

/**状态栏颜色 */
export const useUpdateStatusBar = <P extends Record<string, any> = {}>(mini?: boolean) => {
	const { run } = useBrowserNativeFunc<P>({ funcName: 'updateStatusBar' });
	const updateStatusBar = (params?: P) => {
		if (mini) return;
		else run(params);
	};
	return { run: updateStatusBar };
};

/**是否安装微信 */
export const useCheckWXInstall = <P extends Record<string, any> = {}>(mini?: boolean) => {
	const { run } = useBrowserNativeFunc<P>({ funcName: 'checkWXInstall' });
	const checkWXInstall = (params?: P) => {
		if (mini) return;
		else run(params);
	};
	return { run: checkWXInstall };
};

/** h5调用RN方法-native桥接 */
export const useReactNative = <P extends Record<string, any> = {}, R = undefined>() => {
	const { run } = useBrowserNativeFunc<
		{ eventName: string } & { query?: P },
		{ eventName: string; arg: R }
	>({
		funcName: 'invokeReactNative',
		hasCallback: true,
		cover: true
	});
	const invoke = (params: { eventName: string } & { query?: P }) => {
		return run(params);
	};
	return { run: invoke };
};

type IShareParams = Record<string, any> & {
	original_id: string;
	path: string;
	web_url: string;
	img: string;
	title: string;
	desc: string;
	platform: 0 | 1 | 2; //0、微信，1、微信朋友圈，2、qq
};

/**
 * h5调用native分享方法，直接调起分享盆友圈或好友
 */
export const useShare = () => {
	const { run } = useBrowserNativeFunc<Partial<IShareParams>>({
		funcName: 'sharePlatform',
		hasCallback: true,
		cover: true
	});
	const share = (params?: Partial<IShareParams>) => {
		return run({
			original_id: 'gh_9dc9c9f28a9b',
			// original_id: 'gh_7d3a55b8bc65',
			platform: 0,
			web_url: 'https://m.yupao.com',
			...(params ?? {})
		});
	};

	return { run: share };
};
