import classNames from 'classnames';
import { memo } from 'react';

import IconButtonLoading from '$icons/IconButtonLoading';

import { Ripple } from '../ripple';
import { IRippleWrapperProps } from '../ripple/RippleWrapper';
import { NativeProps } from '../utils/native-props';
import styles from './index.module.scss';

export type IButtonProps = {
	outlined?: boolean;
	rounded?: boolean;
	text?: boolean;
	disabled?: boolean;
	loading?: boolean;
	loadingText?: string;
	ripple?: Partial<IRippleWrapperProps>;
	children?: React.ReactNode;
	onClick?: (e: any) => any;
} & NativeProps;

const Button = memo((props: IButtonProps) => {
	const { className, style, ripple, loadingText, text, outlined, disabled, rounded, loading } =
		props;

	return (
		<button
			style={style}
			className={classNames(styles.btn, className, {
				[styles.outlined]: outlined,
				[styles.rounded]: rounded,
				[styles.text]: text,
				[styles.disabled]: loading || disabled
			})}
			disabled={loading || disabled}
			onClick={props?.onClick}
		>
			<>
				<Ripple {...(ripple ?? {})}>
					{loading ? (
						<div className={styles.loading}>
							<IconButtonLoading className={styles.loadingIcon} />
							{loadingText && <div className={styles.loadingText}>{loadingText}</div>}
						</div>
					) : (
						props.children
					)}
				</Ripple>
			</>
		</button>
	);
});

export default Button;
