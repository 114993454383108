
/** 支付流程相关接口定义 */
import { postApi } from '$lib/model/request';

import {
	IGoodsOrderPayParams,
	IGoodsOrderPayRes,
	IosRmbBuyLimitRiskCheck,
	IPreOrderNo,
	IPurchaseResultRes,
	IQueryHitGrayParams,
	IQueryHitGrayQueryData
} from './types';

export default class CommonOrderPayService {
	/** 下单接口V2 */
	static createOrderV2(data: IGoodsOrderPayParams) {
		return postApi<IGoodsOrderPayRes>('/trade/v2/order/preCreateOrder', { data });
	}

	/** 查询当前用户是否走工行 */
	static queryHitGray(params: IQueryHitGrayParams) {
		return postApi<IQueryHitGrayQueryData>('/trade/v1/order/queryHitGray', { data: params });
	}

	/** 获取预下订单号 */
	static fetchPreOrderNo() {
		return postApi<IPreOrderNo>('/trade/v1/order/getOrderNo');
	}

	/** 获取购买结果 */
	static fetchPurchaseResult(data: { tradeNo: string }) {
		return postApi<IPurchaseResultRes>('/trade/v1/pay/queryTradeStatus', { data });
	}

	/** 下单接口  -- 新业务基本不用了，使用createOrderV2*/
	static createOrder(data: IGoodsOrderPayParams) {
		return postApi<IGoodsOrderPayRes>('/trade/v1/goods/createOrder', { data });
	}

	/** 检查风控限制 */
	static getIosRmbBuyLimitRiskCheck(data: {
		riskBusinessType: 'YUPAO_POINTS_CHARGE' | 'YUPAO_B_VIP';
	}) {
		return postApi<Partial<IosRmbBuyLimitRiskCheck>>('/trade/v1/order/getIosRmbBuyLimitRiskCheck', {
			data
		});
	}
}