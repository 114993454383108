import { OS_MAP_REGEXP } from './constants';
import { YP_ENV } from './env';

type OS = 'UNKONWN' | 'WINDOWS' | 'IOS' | 'ANDROID' | 'MACOS' | 'HARMONYOS' | 'CHROMEOS';
export const browser = typeof window !== 'undefined';

/**
 * @description 判断是否在safari
 * @param {string|undefined} userAgent 服务端必传
 * @returns {boolean}
 */
export const isSafari = (userAgent?: string): boolean => {
	if (!browser && !userAgent) {
		console.error('server side no userAgent!');
		return false;
	}
	const ua = (browser ? window.navigator.userAgent : userAgent ?? '')?.toLowerCase();
	return ua.indexOf('safari') !== -1;
};

/**
 * @description 判断当前是否在微信内
 * @param {string|undefined} userAgent 服务端必传
 * @return {boolean}
 */
export const isWechat = (userAgent?: string): boolean => {
	if (!browser && !userAgent) {
		console.error('server side no userAgent!');
		return false;
	}
	const ua = (browser ? window.navigator.userAgent : userAgent ?? '')?.toLowerCase();
	return ua.indexOf('micromessenger') !== -1;
};

/**
 * @description 是否为ios设备
 * @param {string|undefined} userAgent 服务端必传
 * @returns {boolean}
 */
export const isIos = (userAgent?: string): boolean => {
	const reg = /\(i[^;]+;( u;)? cpu.+mac os x/;
	const match = (u: string) => u.match(reg);
	if (!browser && !userAgent) {
		console.error('server side no userAgent!');
		return false;
	}
	const ua = (browser ? window.navigator.userAgent : userAgent ?? '')?.toLowerCase();
	return !!match(ua);
};

/**
 * @description 是否为移动端
 * @param {string|undefined} userAgent 服务端必传
 * @returns {boolean}
 */
export const isMobile = (userAgent?: string): boolean => {
	if (!browser && !userAgent) {
		console.error('server side no userAgent!');
		return true;
	}
	const reg = /mobile|iphone|android/;
	const ua = (browser ? window.navigator.userAgent : userAgent ?? '')?.toLowerCase();
	return reg.test(ua);
};

/**
 * @description 是在电脑上打开
 * @param {string|undefined} userAgent 服务端必传
 * @returns {boolean}
 */
export const isPC = (userAgent?: string): boolean => {
	if (!browser && !userAgent) {
		console.error('server side no userAgent!');
		return true;
	}
	const reg = /windows|macintosh|linux/;
	const ua = (browser ? window.navigator.userAgent : userAgent ?? '')?.toLowerCase();
	return reg.test(ua);
};

/**
 * @description 获取os
 * @param userAgent
 * @returns {OS}
 */
export const getOs = (userAgent?: string): OS => {
	if (!browser && !userAgent) {
		console.error('server side no userAgent!');
		return 'UNKONWN';
	}
	const ua = (browser ? window.navigator.userAgent : userAgent ?? '')?.toLowerCase();
	if (ua.indexOf('window') > -1) return 'WINDOWS';
	if (ua.indexOf('iphone') > -1) return 'IOS';
	if (ua.indexOf('android') > -1) return 'ANDROID';
	if (ua.indexOf('mac os') > -1) return 'MACOS';
	if (ua.indexOf('linux') > -1) return 'HARMONYOS';
	if (ua.indexOf('chrome os') > -1) return 'CHROMEOS';

	return 'UNKONWN';
};

export const getOsVersion = (userAgent?: string): string => {
	let version = '';
	if (!browser && !userAgent) {
		console.error('server side no userAgent!');
		return version;
	}
	const ua = (browser ? window.navigator.userAgent : userAgent ?? '')?.toLowerCase();
	const os = getOs(ua);
	const reg = OS_MAP_REGEXP?.[os];
	if (reg) {
		const uaMatch = ua.match(reg);
		if (!!uaMatch) {
			version = uaMatch[2].replace(/_/g, '.');
		}
	}

	return version;
};

/**
 * @description 获取鱼泡平台信息 wechat、android、ios
 * @param {string} ua
 * @return {'mini'|'android'|'ios' | undefined}
 */
export const getYpPlatformSync = (ua: string): 'mini' | 'android' | 'ios' | undefined => {
	if (/(miniProgram)/.test(ua)) {
		return 'mini';
	}
	const match = ua.match(/YpHybrid\/(ios|android)/);
	if (match && match.length) {
		if (match[0].indexOf('ios') > -1) return 'ios';
		return 'android';
	}
	return undefined;
};

export const dateFormatConversion = (time: string) => {
	if (!time) return;
	const timeArr = time.split(' ')[0].split('-');
	return `${timeArr[0]}年${timeArr[1]}月${timeArr[2]}日`;
};

/**
 *
 */
export const arrSplitByTime = (list: any) => {
	const copyList = JSON.parse(JSON.stringify(list));
	copyList.forEach((item: any) => {
		item.time = dateFormatConversion(item.createdAt);
	});
	const map = new Map();
	copyList.forEach((item: any, index: number, arr: any) => {
		if (!map.has(item.time)) {
			map.set(
				item.time,
				arr.filter((a: any) => a.time == item.time)
			);
		}
	});
	return Array.from(map).map((item) => {
		return {
			time: item[0],
			list: [...item[1]]
		};
	});
};

/** 对应m项目地址 */
export const mUrl = () => {
	let pageUrl = '';
	if (['dev', 'develop', 'test'].includes(YP_ENV ?? '')) {
		pageUrl = 'https://mtest.yupaowang.com';
	} else if (['master', 'preprod'].includes(YP_ENV ?? '')) {
		pageUrl = 'https://rm.kkbbi.com';
	} else if (YP_ENV === 'prod') {
		pageUrl = 'https://m.yupao.com';
	}
	return pageUrl;
};

/** 获取小程序环境 */
export const getAppletEnv = (): string => {
	if (browser) {
		const ua = window.navigator.userAgent.toLowerCase();
		if (ua.indexOf('micromessenger') > -1) {
			return 'weixin';
		}
		if (ua.indexOf('toutiaomicroapp') > -1) {
			return 'douyin';
		}
		if (ua.indexOf('baiduboxapp') > -1) {
			return 'baidu';
		}
	}
	return 'other';
};
/**
 * @description  处理传入值为null,undefined问题
 * @param {obj} T 传入参数
 * @returns {newObj} T
 */
export const handleRouteParams = <T>(obj: T): T => {
	const newObj: any = {};
	for (const prop in obj) {
		if (obj?.hasOwnProperty(prop)) {
			newObj[prop] = obj[prop] === 'null' || obj[prop] === 'undefined' ? '' : obj[prop];
		}
	}
	return newObj as T;
};

/** 转换高度 */
export const pxTo = (px: number) => {
	if (!window) return px
	const windowWidth = window.screen.width
	return Number(((px / 750) * windowWidth).toFixed(2))

}