import { postApi } from '$lib/model/request';

import type {
	IConfirmContinueReq,
	IConfirmReq,
	IGetCurrentRiddleReq,
	IGetCurrentRiddleRes,
	IGiveUpReq,
	IRiddles,
	IShareHelpStatusReq,
	IShareHelpStatusRes,
	IShareLandingStatusReq,
	IShareLandingStatusRes,
	ISubmitAnswerReq,
	ISubmitAnswerRes
} from './types';

export default class LanternFestivalService {
	/** 获取答题中的灯谜 */
	static getCurrentRiddle(params: IGetCurrentRiddleReq) {
		return postApi<IGetCurrentRiddleRes>('/riddle/v1/getCurrentRiddle', { data: params });
	}
	/** 确认继续答题 */
	static confirmContinue(params: IConfirmContinueReq) {
		return postApi<IRiddles>('/riddle/v1/confirmContinue', { data: params });
	}
	/** 提交答案 */
	static submitAnswer(params: ISubmitAnswerReq) {
		return postApi<ISubmitAnswerRes>('/riddle/v1/submitAnswer', { data: params });
	}
	/** 助力状态查询 */
	static shareHelpStatus(params: IShareHelpStatusReq) {
		return postApi<IShareHelpStatusRes>('/riddle/v1/shareHelpStatus', { data: params });
	}
	/** 放弃邀请 */
	static giveUp(params: IGiveUpReq) {
		return postApi('/riddle/v1/giveUp', { data: params });
	}
	/** 开始答题 */
	static confirmStart(params: IConfirmReq) {
		return postApi('/riddle/v1/confirmStart', { data: params });
	}
	/** 分享落地页状态查询 */
	static shareLandingStatus(params: IShareLandingStatusReq) {
		return postApi<IShareLandingStatusRes>('/riddle/v1/shareLandingStatus', { data: params });
	}
}
