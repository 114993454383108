import { throttle } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { callNative, IBrowserNativeFuncOption } from './browserSupport';

type IUseBrowserNativeFuncProps<T> = Omit<IBrowserNativeFuncOption<T>, 'protocol'> & {
	destroyUnmount?: boolean;
	protocol?: string;
	/** 调用间隔时间 */
	delay?: number;
	/** 是否覆盖上次调用,对于有回调但是不清楚什么时候会触发回调的情况有空 */
	cover?: boolean;
};

export const useBrowserNativeFunc = <T, R = undefined>(options: IUseBrowserNativeFuncProps<T>) => {
	const config = useMemo(
		() => ({
			destroyUnmount: true,
			protocol: 'ypNative',
			delay: 1000,
			cover: false,
			...options
		}),
		[options]
	);
	const hasCall = useRef<boolean>(false);
	const timer = useRef<NodeJS.Timeout | null>();

	const run = useCallback(
		async (params?: T) => {
			if (hasCall.current && !config.cover) return;
			hasCall.current = true;
			try {
				const data = await callNative<T, R>({
					params: params,
					...(config as IBrowserNativeFuncOption<T>)
				});
				return Promise.resolve(data);
			} catch (e) {
				return Promise.reject(e);
			} finally {
				if (timer.current) {
					clearTimeout(timer.current);
					timer.current = null;
				}
				if (!config.cover) {
					timer.current = setTimeout(() => (hasCall.current = false), config.delay);
				}
			}
		},
		[config]
	);

	function unmount() {
		/** 仅android需要销毁 */
		if (window[config.protocol] && window[config.protocol]?.sendMessageNative) {
			window[config.protocol].sendMessageNative = null;
		}
		if (window?.[`${config.funcName}Callback`]) {
			window[`${config.funcName}Callback`] = null;
		}
	}

	useEffect(() => {
		return () => {
			config?.destroyUnmount && unmount();
		};
	}, [config?.destroyUnmount]);

	return { run: throttle(run, config.cover ? config.delay : 0), unmount };
};

export type { IBrowserNativeFuncOption };
