export const serializeUrlParams = (
	params: Record<string, string | number | boolean> | undefined
): string => {
	const paramsString: string =
		params && Object.keys(params).length > 0
			? `?${Object.keys(params)
					.map((item) => {
						let value = '';
						if (params[item]) {
							if (typeof params[item] === 'object') {
								value = encodeURIComponent(JSON.stringify(params[item]));
							} else value = encodeURIComponent(params[item]);
						}
						return `${item}=${value}`;
					})
					.join('&')}`
			: '';
	return paramsString;
};

export const combineUrlParams = (url: string, query: Record<string, string | number | boolean>) => {
	if (query && Object.keys(query).length > 0) {
		const symbol = url.indexOf('?') !== -1 ? '&' : '?';
		const str = Object.keys(query)
			.map((item) => {
				let value = '';
				if (query[item]) {
					if (typeof query[item] === 'object') {
						value = encodeURIComponent(JSON.stringify(query[item]));
					} else value = encodeURIComponent(query[item]);
				}
				return `${item}=${value}`;
			})
			.join('&');

		return url + symbol + str;
	}
	return url;
};
